import { KeyOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Input, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from 'pages/ChangePassword/styles.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuthContext } from 'hooks/useAuth';
import BackToButton from './BackToButton';

const schema = yup
  .object({
    email: yup.string().required('Email is a required field.'),
    verification_code: yup.string().required('Verification Code is a required field.'),
    secret: yup
      .string()
      .required('Password is a required field.')
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
        'Minimum eight characters, at least one letter, one number and one special character for the password.'
      ),
    confirmSecret: yup
      .string()
      .required('Confirm Password is a required field.')
      .oneOf([yup.ref('secret')], 'Passwords must match'),
  })
  .required();

export type ChangePasswordFormData = {
  verification_code: string;
  email: string;
  secret: string;
  confirmSecret: string;
};

export type ChangePasswordFormProps = {
  //
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = () => {
  const { passRecoveryConfirm, loading } = useAuthContext();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(schema),
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegistration = async (data) => {
    const message = await passRecoveryConfirm(data);
    if (message) {
      setErrorMessage(message);
    }
  };

  useEffect(() => {
    const email = window.localStorage.getItem('passwordRecoveryEmail') || false;
    if (email) {
      setValue('email', email);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(handleRegistration)}>
      {errorMessage && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errorMessage}
        </Tag>
      )}
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Email"
            type="email"
            prefix={<MailOutlined />}
            className={styles.input}
            disabled={true}
          />
        )}
      />
      {errors?.email && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.email?.message}
        </Tag>
      )}
      <Controller
        name="verification_code"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Verification Code"
            type="verification_code"
            prefix={<MailOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.verification_code && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.verification_code?.message}
        </Tag>
      )}
      <Controller
        name="secret"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Password"
            type="password"
            prefix={<KeyOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.secret && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.secret?.message}
        </Tag>
      )}
      <Controller
        name="confirmSecret"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Confirm Password"
            type="password"
            prefix={<KeyOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.confirmSecret && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.confirmSecret?.message}
        </Tag>
      )}

      <p>
        Please check your inbox for password recovery instructions. Also be sure to check the spam
        folder if you didnt find the email.
      </p>
      <div className={styles.buttons}>
        <Button type="primary" size="large" htmlType="submit" loading={loading}>
          CHANGE
        </Button>
        <BackToButton label={'BACK TO LOGIN'} path={'/login'} />
      </div>
    </form>
  );
};

export default ChangePasswordForm;

import MediaLayout from 'components/layouts/MediaLayout';
import React, { useState } from 'react';
import MediaContentTable from './MediaContentTable';
import useProjectStore from '../../../store/projectStore';

const MediaContainer = () => {
  const [filterByModelOpt, setFilterByModelOpt] = useState(null);
  const { projectDetails } = useProjectStore();
  return (
    <MediaLayout setFilterByModelOpt={setFilterByModelOpt}>
      <MediaContentTable projectDetails={projectDetails} filterByModelOpt={filterByModelOpt} />
    </MediaLayout>
  );
};

export default MediaContainer;

import React from 'react';
import Subscription from './Subsription';
import styles from './subscription.module.scss';

const index = () => {
  return (
    <div className={styles.root}>
      <Subscription />
    </div>
  );
};

export default index;

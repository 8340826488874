import { Dropdown, Menu, Space, Tag, Typography } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { generateIcon } from 'utils/GenerateLabelAndIcon';
import styles from './modelContainer.module.scss';
import { Field_Operation_Type_Enum } from 'generated/graphql';
import { SingleModelProps } from '../interface/interface';
import React from 'react';
import { fieldArr } from '../modelsRightSidebar/data';

const { Text } = Typography;

const FieldContainer: React.FC<SingleModelProps> = ({ field, openDrawer, objectIdentifier }) => {
  const menu = (field) => (
    <Menu mode="vertical" className={styles.menu}>
      <Menu.Item
        key={Field_Operation_Type_Enum.Duplicate}
        icon={<CopyOutlined />}
        onClick={() =>
          openDrawer({
            type: 'duplicate',
            field: field,
            objectIdentifier: objectIdentifier,
          })
        }
      >
        Duplicate
      </Menu.Item>
      <Menu.Item
        key={Field_Operation_Type_Enum.Rename}
        icon={<EditOutlined />}
        onClick={() =>
          openDrawer({
            type: 'rename',
            field: field,
            objectIdentifier: objectIdentifier,
          })
        }
      >
        Rename
      </Menu.Item>
      {/* <Menu.Item key="2" icon={<CopyOutlined />} >Duplicate</Menu.Item> */}
      <Menu.Item
        key={`delete`}
        icon={<DeleteOutlined />}
        onClick={() => {
          openDrawer({
            type: 'delete',
            field: field,
            objectIdentifier: objectIdentifier,
          });
          //setIdentifierForDelete(field?.identifier);
          //setIsDeleteModalVisible(true);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const getDefaultFieldDescription = (field) => {
    return fieldArr.find((f) => f.field_type === field.field_type).description;
  };

  return (
    <div className={styles.singleModelRelationContainer}>
      <div
        className={styles.contentContainer}
        onClick={() => {
          openDrawer({
            type: 'configure',
            field: field,
            objectIdentifier: objectIdentifier,
          });
        }}
      >
        <Space direction="horizontal">
          <div className={styles.iconWrapper}>{generateIcon(field)}</div>
          <Space direction="vertical">
            <Space direction="horizontal" style={{ flexWrap: 'wrap' }}>
              <Text style={{ fontWeight: '800', fontSize: '16px', flexShrink: 0 }}>
                {field.label}
              </Text>
              <Tag className={styles.tag}>{field.identifier}</Tag>
            </Space>
            <Text className={styles.desc}>
              {field.description || getDefaultFieldDescription(field)}
            </Text>
          </Space>
        </Space>
      </div>
      {field?.system_generated ? (
        <LockOutlined />
      ) : (
        <Dropdown overlay={menu(field)} placement={'bottomRight'}>
          <SettingOutlined className={styles.dropDownMenuIcon} />
        </Dropdown>
      )}
    </div>
  );
};

export default FieldContainer;

import { gql } from '@apollo/client';

export const NOTIFY_SYSTEM = gql`
  subscription NotifySystem($type: String) {
    notifySystem(type: $type) {
      message
      type
      duration
      final
    }
  }
`;

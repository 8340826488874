import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type LoaderProps = {
  size?: 'small' | 'default' | 'large';
  spin?: boolean;
  tip?: string;
  className?: any;
};

const Loader: React.FC<LoaderProps> = ({ size, spin, tip, className = '' }) => {
  const antIcon = <LoadingOutlined spin />;

  return (
    <div style={{ margin: '10px' }} className={className}>
      <Spin size={size || 'default'} indicator={!spin && antIcon} tip={tip || ''} />
    </div>
  );
};

export default Loader;

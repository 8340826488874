import { useState } from 'react';

import { Card, Dropdown, List, Menu, message, Space, Tag } from 'antd';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from '@ant-design/icons';

// import dayjs from 'dayjs';
// import { capitalize } from 'lodash';
import styles from './cards.module.scss';
import { IProjectBody, TokenData } from 'dto/project-list.dto';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import { useDeleteProject, useSwitchProject } from 'hooks/useProjectList';
import { useNavigate } from 'react-router-dom';
import ProjectHeader from 'components/headers/ProjectHeader';
import ArangodbLogo from '../icons/arangodb';
import Loader from 'components/loader/Loader';
import { client } from 'index';
import MySQLLogo from '../icons/mysql';
import PostgresLogo from '../icons/postgres';
import ApitoLogo from '../icons/apito';
import DeleteConfirmModal from 'components/modals/DeleteConfimModal';
// import { DeleteConfirmModal } from '../custom/modal';

type ProjectCardProps = {
  project: IProjectBody;
  tokenData: TokenData;
  onActiveProject: (id: string) => void;
};
const ProjectCard: React.FC<ProjectCardProps> = ({ project, tokenData, onActiveProject }) => {
  // const { name, description, created_at, id, plan } = data;

  // const { jwtToken, refresh } = useAuth();
  // const projectData = jwtToken ? jwt_decode(jwtToken) : {};
  // const projectId = projectData?.project_id ?? '';
  // const projectRole = projectData?.project_role ?? '';
  const [isProjectSwitchLoader, setIsProjectSwitchLoader] = useState(false);
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [deleteProjectID, setDeleteProjectID] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  // const onProjectCardClick = async (id) => {
  //   console.log(id);
  //   console.log(projectId);
  //   if (projectId != id || projectRole === 'demo') {
  //     console.log('entering');
  //     await setIsProjectSwitchLoader(true);
  //     // await switchProject(id);
  //     await setIsProjectSwitchLoader(false);
  //     await router.push('/console/content');
  //     await router.reload();
  //   } else if (projectId === id) {
  //     console.log('leaving');
  //     await setIsProjectSwitchLoader(true);
  //     await router.push('/console/content');
  //   }
  // };

  const onDeleteProject = async () => {
    // setIsDeleteLoader(true);
    // deleteProject(deleteProjectID, setIsDeleteLoader);
    handleDeleteProject(project.id);
  };

  const handleDeleteProject = async (id: string) => {
    setIsDeleteLoader(true);
    message
      .loading('Deleting projects', 0.5)
      .then(async () => {
        setLoading(true);
        await deleteProject(id);
        setLoading(false);
      })
      .then(() => {
        navigate('/projects');
        message.success('Project ' + id + ' deleted', 1.5);
        window.location.reload();
        setIsDeleteLoader(false);
      });
    //setDeleteProjectID(projectID);
    //setIsDeleteModalVisible(true);
    //await client.resetStore();
  };

  const navigate = useNavigate();

  const switchProject = useSwitchProject();
  const deleteProject = useDeleteProject();

  const handleSwitchProject = async (id: string) => {
    /*    if (tokenData?.project_id === id) {
          await navigate('/console/contents');
          await message.success('Switched to Project ' + id, 1.5);
        } else {*/
    message
      .loading('Entering Into Project -> ' + id, 0.5)
      .then(async () => {
        await client.resetStore();
        setLoading(true);
        await switchProject(id);
        setLoading(false);
      })
      .then(async () => {
        navigate('/console');
        await message.success('Switched to Project ' + id, 1.5);
      });
    //}
  };

  const menu = (id: string) => (
    <Menu className={styles.menu}>
      <Menu.Item
        key="1"
        icon={<ArrowRightOutlined />}
        onClick={async (e) => {
          e.domEvent.stopPropagation();
          await handleSwitchProject(project?.id);
        }}
      >
        Enter Project
      </Menu.Item>
      {tokenData?.is_super_admin === 'true' && (
        <Menu.Item
          key="2"
          icon={<SettingOutlined />}
          onClick={async (e) => {
            e.domEvent.stopPropagation();
            onActiveProject(project?.id);
          }}
        >
          Project Settings
        </Menu.Item>
      )}

      {tokenData.project_role === 'admin' && (
        <Menu.Item
          key="3"
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            setDeleteProjectID(id);
            setIsDeleteModalVisible(true);
            // await handleDeleteProject(id);
          }}
        >
          Delete Project
        </Menu.Item>
      )}
    </Menu>
  );

  function GetLogo(db) {
    switch (db) {
      case 'apito':
        return <ApitoLogo />;
      case 'arango':
        return <ArangodbLogo />;
      case 'postgresql':
        return <PostgresLogo />;
      case 'mysql':
        return <MySQLLogo />;
    }
  }

  return (
    <div>
      <Card
        hoverable
        className={styles.projectCard}
        onClick={() => handleSwitchProject(project?.id)}
        title={
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                avatar={<ProjectHeader projectName={project?.name} isCurrentProject />}
                title={project?.name}
                description={`Started ${dayjs().from(dayjs(project?.created_at), true)} ago`}
              />
            </List.Item>
          </List>
        }
        extra={
          <div className="cardExtra">
            {loading && <Loader className="loading" />}
            <Dropdown overlay={menu(project?.id)}>
              <EllipsisOutlined rotate={90} style={{ fontSize: '16px' }} />
            </Dropdown>
          </div>
        }
        bodyStyle={{
          marginTop: '0px',
          height: '85%',
        }}
        actions={[
          <Tag
            color="blue"
            // onClick={async () => {
            //   await onProjectCardClick(id);
            // }}
            key={1}
          >
            LIVE
          </Tag>,
          <Tag key={2} color="cyan">
            {capitalize(project?.plan)}
          </Tag>,
          // <div key={3}>{projectId === id ? <Badge color="green">Logged In</Badge> : ''}</div>,
          /*<div key={3}>
            {' '}
            <Badge color="green">Logged In</Badge>{' '}
          </div>,*/
          <div key={4}>
            <Space direction={'horizontal'}>{GetLogo(project?.db)}</Space>
          </div>,
        ]}
      >
        {isProjectSwitchLoader || isDeleteLoader ? (
          <></>
        ) : (
          // <Loader />
          <div
            role="presentation"
            // onClick={() => {
            //   onProjectCardClick(id);
            // }}
            className={styles.cardBodyContentWrapper}
          >
            {project?.description}
          </div>
        )}
      </Card>
      <DeleteConfirmModal
        loader={isDeleteLoader}
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        handleModelConfirm={onDeleteProject}
        confirmText={deleteProjectID}
      />
    </div>
  );
};

export default ProjectCard;

import { Button, Layout, notification, Spin } from 'antd';
import Header from 'components/headers/Header';
import { SystemMessage, useGetCurrentProjectQuery } from 'generated/graphql';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useProjectStore from '../../store/projectStore';
import { useSubscription } from '@apollo/client';
import { NOTIFY_SYSTEM } from '../../subscriptions';

// import styles from './layouts.module.scss';

const { Content } = Layout;

const ConsoleLayout = () => {
  const { setProjectDetails } = useProjectStore();

  const { data: notificationData, loading: notificationLoading } = useSubscription(NOTIFY_SYSTEM, {
    variables: {
      type: 'type-not-used',
    },
  });

  let key = 'msg';

  useEffect(() => {
    //console.log('notificationData', notificationData);
    const ShowNotification = (data) => {
      console.log('notification data', data);
      switch (data?.type) {
        case 'loading':
          notification.info({
            message: data?.message,
            key,
            placement: 'bottomLeft',
            icon: <Spin />,
            duration: 0,
          });
          break;
        case 'info':
          notification.info({
            message: data?.message,
            key,
            placement: 'bottomLeft',
          });
          break;
        case 'success':
          notification.success({
            message: data?.message,
            key,
            placement: 'bottomLeft',
          });
          break;
        case 'error':
          notification.error({
            message: data?.message,
            key,
            placement: 'bottomLeft',
          });
          break;
        case 'warning':
          notification.warn({
            message: data?.message,
            key,
            placement: 'bottomLeft',
          });
          break;
        default:
          notification.info({
            message: data?.message,
            key,
            placement: 'bottomLeft',
          });
          break;
      }
    };
    if (notificationData && !notificationLoading) {
      ShowNotification(notificationData?.notifySystem);
    }
  }, [notificationData, notificationLoading]);

  const navigate = useNavigate();

  const { data, loading } = useGetCurrentProjectQuery({
    onCompleted: (res) => {
      setProjectDetails(res?.currentProject);
    },
  });

  const nkey = 'notification-key';

  useEffect(() => {
    const openNotification = (system_messages: SystemMessage[]) => {
      system_messages.forEach((message) => {
        if (!message.hide) {
          notification.warn({
            key: nkey,
            message: message.message,
            duration: 0,
            placement: 'bottomLeft',
            closeIcon: null,
            description: [
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  navigate(message.redirection);
                }}
              >
                Upgrade
              </Button>,
            ],
            /*onClick: () => {
          console.log('Notification Clicked!');
        },*/
          });
        }
      });
    };

    if (!loading && data) {
      openNotification(data?.currentProject?.system_messages);
    }
  }, [loading, data, navigate]);

  return (
    <Layout>
      <Header showNav={true} title="Apito Console" subtitle={data?.currentProject?.name} />
      <Layout>
        <Content>
          <Spin spinning={loading}>
            <Outlet />
          </Spin>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ConsoleLayout;

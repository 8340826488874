import InfoToolTip from 'components/tooltip/InfoToolTip';
import React from 'react';
import { ANY_FIXME } from 'types/common';

type CheckboxWrapperProps = { field: string; style?: ANY_FIXME };

const CheckboxTooltip: React.FC<CheckboxWrapperProps> = ({ field, style }) => {
  const getCheckboxInfo = (val) => {
    switch (val) {
      case 'required':
        return 'Field need to be filled before submitting.';
      case 'as_title':
        return 'Name will be used as field title';
      case 'unique':
        return 'Check if the field value will be unique.';
      case 'hide':
        return 'Check to hide field.';
      case 'is_email':
        return 'Field will be used for Email.';
      case 'is_gallery':
        return 'Check to Upload Multiple Image.';
      default:
        return '';
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...style,
      }}
    >
      <InfoToolTip tooltipMsg={getCheckboxInfo(field)} />
    </div>
  );
};

export default CheckboxTooltip;

import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import LogicSidebar, { LogicSidebarProps } from 'components/sidebars/LogicSidebar';
import styles from './layouts.module.scss';

const LogicLayout: React.FC<LogicSidebarProps> = ({
  children,
  setIsCreate,
  setFunctionData,
  selectedFunction,
  funcList,
}) => {
  return (
    <Layout hasSider className={styles.layoutWrapper}>
      <Sider className={styles.logicSidebar}>
        <LogicSidebar
          children={children}
          setIsCreate={setIsCreate}
          setFunctionData={setFunctionData}
          selectedFunction={selectedFunction}
          funcList={funcList}
        />
      </Sider>
      <div className={styles.layoutContent}>
        <Layout className={styles.layoutContentInner} id="scrollableDiv">
          <Content>{children}</Content>
        </Layout>
      </div>
    </Layout>
  );
};

export default LogicLayout;

import { CodeOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Input, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from 'pages/Varification/styles.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuthContext } from 'hooks/useAuth';
import BackToButton from './BackToButton';

const schema = yup
  .object({
    email: yup.string().required('Email is a required'),
    secret: yup.string().required('Verification Code is required'),
  })
  .required();

export type VarificationFormData = {
  email: string;
  secret: string;
};

export type VarificationFormProps = {
  //
};

const VarificationForm: React.FC<VarificationFormProps> = () => {
  const { emailVerify, loading } = useAuthContext();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<VarificationFormData>({
    resolver: yupResolver(schema),
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleVerify = async (data) => {
    const message = await emailVerify(data);

    if (message) {
      setErrorMessage(message);
    }
  };

  useEffect(() => {
    const email = window.localStorage.getItem('verifyEmail') || false;
    if (email) {
      setValue('email', email);
    }
  }, [setValue]);

  return (
    <form onSubmit={handleSubmit(handleVerify)}>
      {errorMessage && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errorMessage}
        </Tag>
      )}
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Email"
            type="email"
            prefix={<MailOutlined />}
            className={styles.input}
            disabled={true}
          />
        )}
      />
      {errors?.email && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.email?.message}
        </Tag>
      )}
      <Controller
        name="secret"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Email Verification Code"
            type="text"
            prefix={<CodeOutlined />}
            className={styles.input}
          />
        )}
      />
      <p>
        By creating an account, you agree to our <a href="/">User Agreement</a> and{' '}
        <a href="/">Privacy Policy</a>
      </p>
      <div className={styles.buttons} style={{ marginBottom: '1rem' }}>
        <Button type="primary" size="large" htmlType="submit" loading={loading}>
          VERIFY & CREATE
        </Button>
        <BackToButton label={'BACK TO LOGIN'} path={'/login'} />
      </div>
    </form>
  );
};

export default VarificationForm;

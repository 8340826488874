import { Button, Dropdown, MenuProps, Skeleton, Space } from 'antd';
import MainLayout from 'components/layouts/MainLayout';
import ModelsLayout from 'components/layouts/ModelsLayout';
import { ContentContext, ContentReducer } from 'context/ContentContext';
import {
  UpdateModelTypeEnum,
  useGetAllModelsQuery,
  useGetFormGenerationDataQuery,
  useUpdateModelMutation,
} from 'generated/graphql';
import { Reducer, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateModel from './CreateModel';
import Models from './Models';
import ModelsRightSidebar from './modelsRightSidebar/ModelsRightSidebar';
import styles from './models.module.scss';
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FileTextOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import DeleteConfirmModal from 'components/modals/DeleteConfimModal';
import { successMessage } from 'utils/message';
import ConvertConfimModal from '../../../components/modals/ConvertConfimModal';
import RenameConfimModal from '../../../components/modals/RenameConfimModal';
import DuplicateConfimModal from '../../../components/modals/DuplicateConfimModal';
import { GET_ALL_MODELS } from '../../../graphql/queries/models';

const ModelsContainer = () => {
  const [isCreate, setIsCreate] = useState(false);
  const [state, dispatch] = useReducer<Reducer<any, any>>(ContentReducer, { target: '' });
  const [repeatedFieldIdentifier, setRepeatedFieldIdentifier] = useState('');
  const [popover, setPopover] = useState(false);
  const [currentModelName, setModelName] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [isConvertModalVisible, setIsConvertModalVisible] = useState(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { loading, data: currentModelInfo } = useGetFormGenerationDataQuery({
    variables: {
      model_name: `${state?.target}`,
    },
    skip: !state?.target,
  });
  const { data: modelList } = useGetAllModelsQuery();

  const isSinglePage = currentModelInfo?.projectModelsInfo[0]?.single_page;

  //console.log('single_page', isSinglePage);

  const [updateModel, { loading: loadingUpdate }] = useUpdateModelMutation({
    onCompleted: (data) => {
      console.log('res', data);
      let redirectTo = data.updateModel.name;
      successMessage('Model Updated');
      setModelName('');
      setIsDeleteModalVisible(false);
      setIsRenameModalVisible(false);
      setIsConvertModalVisible(false);
      setIsDuplicateModalVisible(false);
      navigate(`/console/models/${redirectTo}`);
    },
  });

  const handleModelConfirm = async (type: string, new_name: string) => {
    let variables = {};
    switch (type) {
      case 'convert':
        await updateModel({
          variables: {
            type: UpdateModelTypeEnum.Convert,
            model_name: currentModelName,
          },
          refetchQueries: [GET_ALL_MODELS],
        });
        break;
      case 'delete':
        await updateModel({
          variables: {
            type: UpdateModelTypeEnum.Delete,
            model_name: currentModelName,
          },
          refetchQueries: [GET_ALL_MODELS],
        });
        break;
      case 'duplicate':
        await updateModel({
          variables: {
            type: UpdateModelTypeEnum.Duplicate,
            model_name: currentModelName,
            new_name: new_name,
          },
          refetchQueries: [GET_ALL_MODELS],
        });
        break;
      case 'rename':
        await updateModel({
          variables: {
            type: UpdateModelTypeEnum.Rename,
            model_name: currentModelName,
            new_name: new_name,
          },
          refetchQueries: [GET_ALL_MODELS],
        });
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Duplicate',
      key: '1',
      icon: <CopyOutlined />,
    },
    {
      label: 'Rename',
      key: '2',
      icon: <EditOutlined />,
    },
    {
      label: 'Convert',
      key: '3',
      icon: <SwapOutlined />,
    },
    {
      label: 'Delete',
      key: '4',
      icon: <DeleteOutlined />,
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('model', params?.model);
    setModelName(params?.model);
    switch (e.key) {
      case '4':
        setIsDeleteModalVisible(true);
        break;
      case '3':
        setIsConvertModalVisible(true);
        break;
      case '2':
        setIsRenameModalVisible(true);
        break;
      case '1':
        setIsDuplicateModalVisible(true);
        break;
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <ContentContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <ModelsLayout setIsCreate={setIsCreate}>
        {isCreate || modelList?.projectModelsInfo?.length === 0 ? (
          <CreateModel />
        ) : (
          <div className={styles.root}>
            <div className={styles.modelListWrapper}>
              <MainLayout
                title={
                  <span style={{ textTransform: 'capitalize' }}>
                    <Space>
                      {isSinglePage ? <FileTextOutlined /> : null}
                      {params?.model}
                    </Space>
                  </span>
                }
                // subtitle="Model"
                breadcrumList={['Model']}
                extra={[
                  /*<Button
                    size={'small'}
                    icon={<CopyOutlined />}
                    onClick={(e) => {
                      //setIsCreate(true);
                    }}
                  >
                    DUPLICATE MODEL
                  </Button>,
                  <Button
                    size={'small'}
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      //setIsCreate(true);
                    }}
                  >
                    RENAME
                  </Button>,
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    className={styles.createBtn}
                    onClick={(e) => {
                      setModelName(params?.model);
                      setIsDeleteModalVisible(true);
                    }}
                  >
                    DELETE
                  </Button>, */
                  <Dropdown menu={menuProps} placement={'bottomRight'}>
                    <Button>
                      <Space>
                        Option
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>,
                ]}
              >
                {loading ? (
                  <Skeleton loading />
                ) : (
                  currentModelInfo && (
                    <Models
                      modelInfo={currentModelInfo.projectModelsInfo[0]}
                      setPopover={setPopover}
                      setRepeatedFieldIdentifier={setRepeatedFieldIdentifier}
                      repeatedFieldIdentifier={repeatedFieldIdentifier}
                    />
                  )
                )}
              </MainLayout>
            </div>
            <div className={styles.modelSidebar}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#f3f3f3',
                  overflow: 'auto',
                  height: '100vh',
                  right: 0,
                }}
              >
                <ModelsRightSidebar
                  repeatedFieldIdentifier={repeatedFieldIdentifier}
                  setPopover={setPopover}
                  popover={popover}
                />
              </div>
            </div>
          </div>
        )}
        <DeleteConfirmModal
          loader={loadingUpdate}
          isVisible={isDeleteModalVisible}
          setIsVisible={setIsDeleteModalVisible}
          handleModelConfirm={handleModelConfirm}
        />
        <ConvertConfimModal
          loader={loadingUpdate}
          isVisible={isConvertModalVisible}
          setIsVisible={setIsConvertModalVisible}
          isSinglePage={isSinglePage}
          handleModelConfirm={handleModelConfirm}
        />
        <RenameConfimModal
          loader={loadingUpdate}
          isVisible={isRenameModalVisible}
          setIsVisible={setIsRenameModalVisible}
          isSinglePage={isSinglePage}
          handleModelConfirm={handleModelConfirm}
        />
        <DuplicateConfimModal
          loader={loadingUpdate}
          isVisible={isDuplicateModalVisible}
          setIsVisible={setIsDuplicateModalVisible}
          isSinglePage={isSinglePage}
          handleModelConfirm={handleModelConfirm}
        />
      </ModelsLayout>
    </ContentContext.Provider>
  );
};

export default ModelsContainer;

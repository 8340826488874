import { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Typography } from 'antd';

import { CLIENT_GRAPHQL_URL } from 'configs';
import FixedListSelector from 'components/inputs/FixedListSelector';
import {
  GetSettingsTeamsRolesQuery,
  useGenerateApiKeyMutation,
  useGetCurrentProjectTokensLazyQuery,
} from 'generated/graphql';
import { successMessage } from 'utils/message';
import AvailableKeysTable from './AvailableKeysTable';
// import useAuth from '../../../../context/AuthContext';

const { Text, Title } = Typography;

const ApiSecrets = ({ roles }: { roles: GetSettingsTeamsRolesQuery }) => {
  const rolesObj = roles?.currentProject?.roles ?? {};
  const roleList = rolesObj ? Object.keys(rolesObj) : [];
  const [projectTokenList, setProjectTokenList] = useState([]);

  // const { jwtToken } = useAuth();
  // const projectData = jwtToken ? jwt_decode(jwtToken()) : {};

  const [getCurrentProjectData, { error, data: currentProjectData }] =
    useGetCurrentProjectTokensLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  if (error) {
    console.log(error);
  }

  const [generateAPIKey, { loading }] = useGenerateApiKeyMutation({
    onCompleted: (data) => {
      successMessage('API Token generated successfully!');
      getCurrentProjectData();
    },
  });

  const onFinish = (values) => {
    generateAPIKey({ variables: { ...values } });
  };

  useEffect(() => {
    if (currentProjectData) {
      const tokenList = currentProjectData?.currentProject.tokens ?? [];
      setProjectTokenList([...tokenList]);
    } else {
      getCurrentProjectData();
    }
  }, [currentProjectData, getCurrentProjectData]);

  return (
    <Form
      name="general-setting"
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Row>
        <Col span={7}>
          <Form.Item
            name="name"
            label="Token Name"
            rules={[{ required: true }]}
            extra="The name of the token"
          >
            <Input placeholder="e.g. Editor Access" />
          </Form.Item>
        </Col>

        <Col span={7} offset={1}>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true }]}
            extra="The duration for which the token will be active"
          >
            <FixedListSelector list={roleList || []} />
          </Form.Item>
        </Col>

        <Col span={7} offset={1}>
          <Form.Item
            name="duration"
            label="Token Duration"
            rules={[{ required: true }]}
            extra="The duration for which the token will be active"
          >
            <DatePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              placeholder="Select duration time"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          GENERATE
        </Button>
      </Form.Item>

      <div style={{ margin: '50px 0 0' }}>
        <Title level={3} className="globalTitle">
          <Text>API Endpoints & Secrets</Text>
        </Title>
      </div>

      <Row>
        <table
          style={{
            width: '50%',
            margin: '20px 0 20px',
          }}
        >
          <tr>
            <th>Engine</th>
            <th>Endpoint</th>
          </tr>
          <tr>
            <td>GraphQL API Endpoints</td>
            <td>{CLIENT_GRAPHQL_URL}</td>
          </tr>
          <tr>
            <td>REST API Endpoints</td>
            <td>{/* {BASE_URL}/secured/rest/{projectData?.project_id} */}</td>
          </tr>
        </table>
        <Form.Item>
          <div style={{ margin: '15px 0 0' }}>
            <Text
              style={{
                fontSize: '16px',
                color: '#687074',
              }}
            >
              Authentication Type &nbsp;&nbsp;&nbsp;&nbsp;:
            </Text>{' '}
            <Text>&nbsp;&nbsp;&nbsp;Bearer {'<token>'}</Text>
          </div>
        </Form.Item>
      </Row>

      <Row>
        <Col
          span={24}
          className="mt-4 mb-2"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
          }}
        >
          <Title level={4}>
            <Text style={{ fontSize: '16px' }}>Available Secrets</Text>
          </Title>
          <AvailableKeysTable keysDataList={projectTokenList} />
        </Col>
      </Row>
    </Form>
  );
};

export default ApiSecrets;

import { useState } from 'react';
import { Drawer, PageHeader } from 'antd';
// import { DrawerCloseComponent } from 'components/custom';

// import SupportDrawerComponent from './SupportDrawerComponent';

// export function GetSupportToken() {
//   return <Audit />;
// }

const Support = () => {
  const [isSupportDrawerVisible, setIsSupportDrawerVisible] = useState(false);

  return (
    <PageHeader title="Help & Audit" subTitle="The Community">
      <div className="mt-3">
        <p>
          For any Queries and Questions e-mail us at support@apito.io | Alternatively you can join
          our Discord Community for both Help & Audit. Our community & support channel in discord is
          live 24/7
          <br />
          <strong>
            <a rel="noreferrer" target="_blank" href="https://discord.gg/4ehRp3nk">
              Discord Channel
            </a>
          </strong>
        </p>
        <iframe
          title="discord"
          src="https://discord.com/widget?id=794883412636467221&theme=dark"
          width="350"
          height="500"
          //   allowTransparency="true"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        />
      </div>

      <Drawer // Field Configuration  - Drawer
        title="Create Ticket"
        width={450}
        // closeIcon={<DrawerCloseComponent />}
        closable
        onClose={() => {
          setIsSupportDrawerVisible(false);
        }}
        visible={isSupportDrawerVisible}
      >
        {/* <SupportDrawerComponent /> */}
      </Drawer>
    </PageHeader>
  );
};

export default Support;

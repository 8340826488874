import { Layout } from 'antd';

import SettingsSidebar from 'components/sidebars/SettingsSidebar';
import { Outlet } from 'react-router-dom';

import styles from './layouts.module.scss';
import { useAuthContext } from '../../hooks/useAuth';

const { Sider, Content } = Layout;

const SettingsLayout = () => {
  const { decodeTokenData } = useAuthContext();
  const tokenData = decodeTokenData();

  return (
    <Layout hasSider className={styles.layoutWrapper}>
      <Sider className={styles.sidebar}>
        <SettingsSidebar tokenData={tokenData} />
      </Sider>
      <div className={styles.layoutContent}>
        <Layout className={styles.layoutContentInner}>
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </div>
    </Layout>
  );
};

export default SettingsLayout;

import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  PageHeader,
  Row,
  Typography,
} from 'antd';
import { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// import Terminal from 'react-console-emulator';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { runtimeEnvList } from 'data/runtimeEnvList';
import {
  CloudFunctionType,
  Plugin_Type_Enum,
  useGetAllModelsQuery,
  useListPluginIdsQuery,
  useUpsertFunctionToProjectMutation,
} from 'generated/graphql';
import { successMessage } from 'utils/message';
import { SelectContent } from '../../../components/custom/SelectContent';
import CloudProviderDrawerComponent from './drawer/cloud-provider/CloudProviderDrawerComponent';
import { FuncEnvDynamicFormList } from './FuncEnvDynamicFormList';
import styles from './functionForm.module.scss';
import { GET_ALL_FUNCTION_INFO } from '../../../graphql/queries/functions';

dayjs.extend(relativeTime);

const { Content } = Layout;

const { Title, Text } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

function FunctionForm({ functionData }: { functionData: CloudFunctionType }) {
  const {
    name,
    request,
    response,
    function_connected,
    function_provider_id,
    created_at,
    updated_at,
    runtime_config,
    env_vars,
  } = functionData || {};

  const terminal_ref = useRef();

  const [resModelList, setResModelList] = useState([]);
  const [isProviderDrawer, setIsProviderDrawer] = useState(false);
  const [payload, setPayload] = useState({});

  const avatarSrc = function_connected
    ? '/assets/function/svg/connected.svg'
    : '/assets/function/svg/disconnected.svg';

  const createdAt = dayjs().from(dayjs(created_at), true);
  const editedAt = dayjs().from(dayjs(updated_at), true);

  const onCloseDrawer = () => {
    setIsProviderDrawer(false);
  };

  // const { data: funcList } = useQuery(GET_ALL_FUNCTION_INFO);

  const { error, data: modelList } = useGetAllModelsQuery();
  if (error) console.log(error);

  let terminal = null;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    terminal = terminal_ref.current;
  });

  useEffect(() => {
    if (modelList) {
      // console.log('Log: onFinish -> modelList', modelList)
      const resModelArr = modelList?.projectModelsInfo?.map((item) => item.name) ?? [];
      // console.log('Log: onFinish -> resModelArr', resModelArr)
      setResModelList([...resModelArr, 'JSON']);
    }
  }, [modelList]);

  const updateCache = (cache, { data }) => {
    const resData = data.upsertFunctionToProject;
    // console.log('Log: updateCache -> resData', resData)
    const existingData = cache.readQuery({
      query: GET_ALL_FUNCTION_INFO,
    })?.projectFunctionsInfo;
    // console.log('Log: updateCache -> existingData', existingData)

    cache.writeQuery({
      query: GET_ALL_FUNCTION_INFO,
      data: {
        projectFunctionsInfo: [
          ...existingData.map((item) => {
            if (item.name === functionData?.name) {
              return resData;
            }
            return item;
          }),
        ],
      },
    });
  };

  const [disconnectFunction, { loading: disconnectLoading }] = useUpsertFunctionToProjectMutation({
    onCompleted: (data) => {
      // console.log('Log: data', data)
      successMessage('Function Disconnected Successfully!');
      window.location.reload();
    },
    update: updateCache,
  });

  const [updateFunction, { loading: updateLoading }] = useUpsertFunctionToProjectMutation({
    onCompleted: (data) => {
      // console.log('Log: data', data)
      successMessage('Function Updated Successfully!');
    },
    update: updateCache,
  });

  const onDisconnect = async () => {
    await disconnectFunction({
      variables: {
        name,
        function_connected: false,
        update: true, // provider_config: funcConfig,
      },
    });
  };

  const onFinish = async (values) => {
    // console.log('Log ~ file: FunctionForm.js ~ line 132 ~ onFinish ~ values', values);
    await updateFunction({
      variables: {
        name,
        ...payload,
        env_vars: values?.env_vars?.length ? values?.env_vars : undefined, //! assign right after payload
        update: true,
      },
    });
  };

  const onValuesChange = (values) => {
    if (values?.config) {
      setPayload({
        ...payload,
        config: {
          // @ts-ignore
          ...payload.config,
          ...values.config,
        },
      });
    } else {
      setPayload({
        ...payload,
        ...values,
      });
    }
  };

  const { data: pluginList, loading: pluginListLoading } = useListPluginIdsQuery({
    variables: {
      type: Plugin_Type_Enum.Function,
    },
    onCompleted: (data) => {},
  });

  // if (contentSiderList?.listModelData?.length <= 0) return <div style={{ margin: '30vh auto 0 auto' }}><EmptyContent model={target} setIsCreate={setIsCreate} /></div>
  if (!name) {
    return (
      <div style={{ margin: '35vh auto 0 auto' }}>
        <SelectContent />
      </div>
    );
  }

  return (
    <PageHeader>
      <Content key={name} className={styles.contentFormContainer}>
        <Form
          {...layout}
          name="content-form"
          layout="horizontal"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={{ remember: false }}
          onValuesChange={onValuesChange}
        >
          <Form.Item>
            <Row>
              <Col span={24}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Text className={styles.subtitle}>Apito Function</Text>
                    <Title level={4} className={styles.title}>
                      {name}
                    </Title>
                  </div>
                  <img
                    src={avatarSrc}
                    style={{
                      margin: '25px 64px',
                      width: `${function_connected ? 54 : 68}px`,
                      height: 'auto',
                    }}
                    alt=""
                  />
                  {function_connected ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Text className={styles.subtitle}>Provider: {function_provider_id}</Text>
                      <Title level={4} className={styles.title}>
                        Logic Executioner: {function_provider_id || ''}
                      </Title>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '-15px',
                      }}
                    >
                      <Text className={styles.subtitle}>Cloud Provider</Text>
                      <Button
                        type="primary"
                        onClick={() => {
                          // terminal.pushToStdout('Hello after 1 second!');
                          setIsProviderDrawer(true);
                        }}
                      >
                        CONNECT
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: '-5px',
                  }}
                >
                  {editedAt ? <Text type="secondary">Edited {editedAt} ago,&nbsp;</Text> : null}
                  {createdAt ? (
                    <Text type="secondary">
                      {' '}
                      Published <span> {createdAt} ago </span>
                    </Text>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Form.Item>

          <Row>
            <Col span={24} lg={{ span: 11 }}>
              <Form.Item>
                <Text className={styles.title_2}>Function Provider</Text>
              </Form.Item>

              <Form.Item
                name="function_provider_id"
                label="Execution Provider"
                rules={[{ message: 'Execution Provider is required', required: true }]}
              >
                <FixedListSelector
                  value={function_provider_id}
                  list={pluginList?.listPluginIds?.plugins}
                  loading={pluginListLoading}
                />
              </Form.Item>

              <Form.Item>
                <Text className={styles.title_2}>Function Request And Response Parameter</Text>
              </Form.Item>

              <Form.Item
                name="request"
                label="Request Parameter"
                initialValue={request?.model}
                rules={[{ required: true }]}
              >
                <FixedListSelector list={resModelList} />
              </Form.Item>

              <Form.Item
                name="response"
                label="Response Parameter"
                initialValue={response?.model}
                rules={[{ required: true }]}
              >
                <FixedListSelector list={resModelList} />
              </Form.Item>

              <Form.Item
                label="Environment Variable"
                name="env_vars"
                initialValue={
                  env_vars?.length
                    ? env_vars.map(({ key, value }) => ({
                        key,
                        value,
                      }))
                    : []
                }
              >
                <FuncEnvDynamicFormList />
              </Form.Item>

              {function_connected ? (
                <div>
                  <Form.Item>
                    <Text className={styles.title_2}>Function Configurations</Text>
                  </Form.Item>

                  <Form.Item
                    name={['config', 'runtime']}
                    label="Runtime Environment"
                    initialValue={runtime_config?.runtime}
                  >
                    <FixedListSelector
                      value={function_provider_id}
                      list={runtimeEnvList || []}
                      islabelValue
                    />
                  </Form.Item>

                  <Form.Item
                    name={['config', 'handler']}
                    label="Handler Name"
                    initialValue={runtime_config?.handler}
                  >
                    <Input placeholder="Handler Name" />
                  </Form.Item>

                  <Form.Item
                    name={['config', 'memory']}
                    label="Memory Allocation"
                    initialValue={runtime_config?.memory}
                  >
                    <Input type="number" placeholder="Memory Allocation" />
                  </Form.Item>

                  <Form.Item
                    name={['config', 'time_out']}
                    label="Request Timeout"
                    initialValue={runtime_config?.time_out}
                  >
                    <Input type="number" placeholder="Request Timeout" />
                  </Form.Item>
                </div>
              ) : null}
            </Col>
            <Col
              span={24}
              lg={{
                span: 12,
                offset: 1,
              }}
            >
              <Form.Item>
                <Text className={styles.title_2}>Request And Response Logs</Text>
              </Form.Item>

              {/* <Terminal
                noDefault
                dangerMode
                noAutomaticStdout
                styleEchoBack="textonly"
                ref={terminal_ref}
                // welcomeMessage={"Welcome to the React terminal!"}
                // promptLabel={"me@React:~$"}
                readOnly
                disabled
              /> */}
            </Col>
          </Row>

          <Row>
            <div style={{ display: 'flex' }}>
              <Button type="primary" htmlType="submit" loading={updateLoading}>
                UPDATE
              </Button>
              {function_connected ? (
                <>
                  <Divider type={'vertical'} />
                  <Button className="ml-2" onClick={onDisconnect} loading={disconnectLoading}>
                    DISCONNECT FUNCTION
                  </Button>
                </>
              ) : null}
            </div>
          </Row>
        </Form>

        <Drawer
          title="Connect to Function Provider"
          placement="right"
          width={320}
          closable
          onClose={onCloseDrawer}
          visible={isProviderDrawer}
          extra={<Button type="link" onClick={onCloseDrawer}>{`< Back`}</Button>}
        >
          <CloudProviderDrawerComponent functionData={functionData} />
        </Drawer>
      </Content>
    </PageHeader>
  );
}

export default FunctionForm;

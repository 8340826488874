import { Button, Card, notification, Spin } from 'antd';
import { httpService } from 'utils/httpService';
import { GithubOutlined } from '@ant-design/icons';
import styles from './cards.module.scss';
import { errorMessage } from 'utils/message';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from 'configs';
import { PROJECT_SWITCH } from '../../configs/index';

const { Meta } = Card;

const DemoProjectCard = ({ project }) => {
  const config = {
    withCredentials: true,
  };
  const navigate = useNavigate();

  const openNotification = (placement) => {
    notification.info({
      message: `Entering into Demo Project ...`,
      description:
        "Please be patient, while we take you to the demo project. Please note that you can only explore the demo project but won't be able to change anything inside it.",
      placement,
      icon: <Spin size="small" />,
    });
  };

  const switchDemoProject = async (projectID) => {
    await openNotification('topRight');
    await httpService
      .post(
        PROJECT_SWITCH,
        {
          id: projectID,
        },
        config
      )
      .then((res) => {
        // console.log(res?.data);
        // const token = res?.data?.token || '';
        // setIsSwitchLoader ? setIsSwitchLoader(false) : null;
      })
      .catch((err) => {
        errorMessage(err?.response?.data?.message);
        // setIsSwitchLoader ? setIsSwitchLoader(false) : null;
      });
  };

  return (
    <Card
      hoverable
      className={styles.demoProjectCard}
      cover={<img alt="project_cover" height="210px" src={project.coverImg} />}
      actions={[
        <Button
          icon={<GithubOutlined />}
          onClick={async () => {
            window.open(project.link, '_blank', 'noopener,noreferrer');
            return null;
          }}
        >
          Source
        </Button>,
        <Button
          type="primary"
          onClick={async () => {
            await switchDemoProject(project.id);
            navigate('/console/contents');
            window.location.reload();
          }}
        >
          ENTER
        </Button>,
      ]}
    >
      <Meta title={project.title} description={project.description} />
    </Card>
  );
};

export default DemoProjectCard;

import { GithubOutlined, GoogleOutlined, KeyOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Input, Tag } from 'antd';
import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styles from 'pages/Login/styles.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuthContext } from 'hooks/useAuth';
import BackToButton from './BackToButton';
import { GITHUB_LOGIN_URL, GOOGLE_LOGIN_URL } from '../../configs';
import { env } from '../../env';

const schema = yup
  .object({
    email: yup.string().required('Email is Required'),
    secret: yup.string().required('Password is also Required'),
  })
  .required();

export type LoginFormData = {
  email: string;
  secret: string;
};

export type LoginFormProps = {
  //message?: string;
};

const LoginForm: React.FC<LoginFormProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const authProvider = env.REACT_APP_AUTH_PROVIDER;

  const { signIn, loading } = useAuthContext();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (data) => {
    const message = await signIn(data);
    if (message) {
      setErrorMessage(message);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      {errorMessage && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errorMessage}
        </Tag>
      )}
      {/* eslint-disable-next-line eqeqeq */}
      {searchParams.get('action') === 'demo' ? (
        <p>
          <h3>Demo Account Credentials</h3>
          E-mail : demo@apito.io
          <br />
          Password : Apitorocks247!
        </p>
      ) : null}
      {authProvider === 'apito' && (
        <>
          <Button
            icon={<GoogleOutlined />}
            type={'default'}
            size="large"
            onClick={() => {
              window.location.href = GOOGLE_LOGIN_URL;
            }}
          >
            Login with Google
          </Button>
          <Divider type={'vertical'}></Divider>
          <Button
            icon={<GithubOutlined />}
            type={'default'}
            size="large"
            onClick={() => {
              window.location.href = GITHUB_LOGIN_URL;
            }}
          >
            Login with Github
          </Button>
          <div style={{ height: '20px ' }}></div>
        </>
      )}
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Email"
            type="email"
            prefix={<MailOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.email && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.email?.message}
        </Tag>
      )}
      <Controller
        name="secret"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Password"
            type="password"
            prefix={<KeyOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.secret && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.secret?.message}
        </Tag>
      )}
      <div className={styles.links}>
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Link to="/forgot-password">Forgot your password ?</Link>
        <a href={'https://apito.io'}>Back to Website</a>
      </div>
      <div className={styles.buttons}>
        {authProvider === 'apito' && <BackToButton label="CREATE AN ACCOUNT" path={'/register'} />}
        <div></div>
        <Button type="primary" size="large" htmlType="submit" loading={loading}>
          LOGIN
        </Button>
      </div>
      <div style={{ height: '20px' }}></div>
      {authProvider === 'apito' && (
        <div style={{ width: '500px', padding: '20px', backgroundColor: '#ffcccb', color: '#333' }}>
          <p>
            Dear User,
            <br />
            <br />
            We are currently deploying a new version of our application. During this time, you might
            experience temporary login issues. We apologize for any inconvenience this may cause and
            appreciate your patience.
            <br />
            <br />
            Please try logging in again after a few minutes. If the issue persists, feel free to
            contact our support team at <a href="mailto:fahim@apito.io">fahim@apito.io</a> for quick
            assistance or use the chat widget at the bottom right on this page.
            <br />
            <br />
            Thank you for your understanding.
            <br />
            <br />
            Best regards,
            <br />
            The Apito Team
          </p>
        </div>
      )}
    </form>
  );
};

export default LoginForm;

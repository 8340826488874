import { Divider, Empty, Space, Spin, Tabs } from 'antd';
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  GetFormDataQuery,
  useGetFormDataLazyQuery,
  useGetFormGenerationDataQuery,
} from 'generated/graphql';
import { capitalize } from 'lodash';
import { errorMessage } from 'utils/message';
import { SelectContent } from '../../../components/custom/SelectContent';
import RevisionContentFormContainer from './RevisionContentFormContainer';
import RevisionContentFormContainer2 from './RevisionContentFormContainer2';

const localsData = require('data/setting/locals.json');

dayjs.extend(relativeTime);

const { TabPane } = Tabs;

const RevisionDiffContainer = (props) => {
  const { contentData } = props;

  const { error, data } = useGetFormGenerationDataQuery({
    variables: {
      model_name: `${contentData?.model}`,
    },
    // skip: !contentData?.id,
  });

  if (!contentData?.id) {
    return (
      <div style={{ margin: '35vh auto 0 auto' }}>
        <SelectContent />
      </div>
    );
  }

  // if (loading) return <Skeleton active />;

  if (error) {
    errorMessage(error);
    return <Empty />;
  }

  if (data) {
    const projectModelInfo = data?.projectModelsInfo;
    return <GetRevisionContentData projectModelInfo={projectModelInfo} {...props} />;
  }
  return null;
};

function GetRevisionContentData(props) {
  const { contentData, projectModelInfo } = props;
  const [formData, setFormData] = useState<GetFormDataQuery['getSingleData'] | undefined>();
  const [revisionData, setRevisionData] = useState<GetFormDataQuery['getSingleData'] | undefined>();

  const localsArr =
    projectModelInfo?.locals?.length > 0
      ? Array.from(new Set(['en', ...projectModelInfo?.locals]))
      : ['en']; // 'En' First Sorting
  const [localNavKey, setLocalNavKey] = useState(localsArr?.[0]);

  const [getRevisionDocById, { loading, error, data }] = useGetFormDataLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data !== undefined && contentData?.id) {
      setFormData(data?.getSingleData);
    }
  }, [data, contentData]);

  const onLocalTabChange = (localKey) => {
    setLocalNavKey(localKey);
  };

  useEffect(() => {
    if (contentData?.id && localNavKey) {
      getRevisionDocById({
        variables: {
          _id: contentData?.id,
          model: contentData?.model,
          local: localNavKey,
          //revision: contentData?.revision,
          single_page_data: contentData?.single_page_data,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localNavKey, contentData]);

  if (error) return <Empty />;

  return (
    <div>
      <Tabs
        defaultActiveKey="en"
        type="card"
        size="small"
        style={{ marginTop: 10 }}
        onChange={onLocalTabChange}
      >
        {localsArr.map((local, i) => (
          <TabPane tab={capitalize(localsData?.[`${local}`] || local || '')} key={local} />
        ))}
      </Tabs>
      <Spin spinning={loading}>
        <Space size={20}>
          <RevisionContentFormContainer
            localsArr={localsArr}
            formData={formData}
            revisionData={revisionData}
            local={localNavKey}
            {...props}
          />
          <Divider />
          <RevisionContentFormContainer2
            localsArr={localsArr}
            formData={formData}
            revisionData={revisionData}
            local={localNavKey}
            {...props}
          />
        </Space>
      </Spin>
    </div>
  );
}

export default RevisionDiffContainer;

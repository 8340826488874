import { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button, Input, PageHeader as ContentHeader, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import debounce from 'lodash/debounce';

import ProjectsTable from './ProjectsTable';
import { TableSkeleton } from './TableSkeleton';
import { gqlErrorMessage } from '../../../utils/message';
import { SEARCH_USERS } from '../../../graphql/queries/settings';

// import styles from './ApitoNavigationBar.module.scss'

const { Title } = Typography;

function Admin() {
  const [searchStr, setSearchStr] = useState('');

  const [searchUsers, { loading, data }] = useLazyQuery(SEARCH_USERS, {
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
    },
    onCompleted: () => {
      // console.log('Log: data', data)
    },
  });

  let onSearch = (searchStr) => {
    searchUsers({
      variables: {
        name: searchStr,
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  onSearch = useCallback(debounce(onSearch, 800), []);

  useEffect(() => {
    if (!searchStr) {
      onSearch();
    } else if (searchStr) {
      onSearch(searchStr);
    }
  }, [onSearch, searchStr]);

  return (
    <ContentHeader admin>
      <div style={{ padding: 24 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 56,
            color: '#687074',
          }}
        >
          <Title
            level={5}
            style={{
              fontFamily: 'Raleway',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: 24,
              color: '#687074',
            }}
          >
            Projects
          </Title>
          <Input
            style={{ margin: '0 15px', height: 36, width: '60vw' }}
            className="filterBtn"
            placeholder="Search"
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            prefix={<SearchOutlined style={{ marginRight: 5 }} />}
          />
          <Button type="primary" size="medium" style={{ width: 150 }}>
            SEARCH
          </Button>
        </div>

        <div className="mt-3">
          <TableSkeleton loading={loading}>
            <ProjectsTable tableData={data?.listAllUserProjects || []} />
          </TableSkeleton>
        </div>
      </div>
    </ContentHeader>
  );
}

export default Admin;

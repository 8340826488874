import { Table } from 'antd';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { ANY_FIXME } from 'types/common';

dayjs.extend(relativeTime);
dayjs.extend(duration);

type SubscriptionTableProps = {
  loading: boolean;
  tableData: ANY_FIXME;
};

const SubscriptionTable = ({ tableData, loading }: SubscriptionTableProps) => {
  const expandedRowRender = (record, index) => {
    const tableNestedColumns = [
      {
        title: 'Payment Method',
        dataIndex: 'payment_method',
        key: 'payment_method',
        ellipsis: true,
      },
      {
        title: 'Charged Amount',
        dataIndex: 'charged_amount',
        key: 'charged_amount',
        ellipsis: true,
        render: (data) => (data == null ? `$0.0` : `${data}`),
      },
      {
        title: 'Next Billable Amount',
        dataIndex: 'next_amount_to_be_charged',
        key: 'next_amount_to_be_charged',
        ellipsis: true,
        render: (data) => (data == null ? `$0.0` : `${data}`),
      },
      {
        title: 'Receipt URL',
        dataIndex: 'receipt_url',
        key: 'receipt_url',
        ellipsis: true,
        render: (data) => (
          <span>
            {' '}
            <a href={data} target="_blank" rel="noreferrer">
              Download
            </a>{' '}
          </span>
        ),
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 180,
        render: (created_at) =>
          created_at
            ? dayjs?.duration(dayjs(created_at).diff(new Date()))?.humanize(true) ?? ''
            : '',
      },
      /*            {
                title: 'Options',
                key: 'option',
                align: 'center',
                width: 120,
                render: (rowData) => (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {/!* {console.log('Log: rowData', rowData)} *!/}
                        <Space>
                            <Tooltip title="Enter Project">
                                <SelectOutlined onClick={() => onEnterProject(rowData.id)}/>
                            </Tooltip>
                        </Space>
                    </div>
                ),
            }, */
    ];

    return (
      <Table
        columns={tableNestedColumns}
        dataSource={record?.invoices || []}
        pagination={false}
        rowKey={(data) => data?.id}
      />
    );
  };

  const tableColumns = [
    {
      title: 'Title',
      dataIndex: ['subscription', 'title'],
      key: 'title', // unique for eatch column (ignore if dataIndex is unique)
    },
    {
      title: 'Subscribed Date',
      dataIndex: ['subscription', 'start_date'],
      key: 'start_date', // unique for eatch column (ignore if dataIndex is unique)
      render: (start_date) =>
        start_date ? dayjs?.duration(dayjs(start_date).diff(new Date()))?.humanize(true) ?? '' : '',
    },
    {
      title: 'Next Bill Cycle',
      dataIndex: ['subscription', 'end_date'],
      key: 'end_date',
      render: (end_date) =>
        end_date ? dayjs?.duration(dayjs(end_date).diff(new Date()))?.humanize(true) ?? '' : '',
    },
    {
      title: 'Status',
      dataIndex: ['subscription', 'subscription_status'],
      key: 'subscription_status',
    },
    {
      title: 'Cancel Subscription',
      dataIndex: ['subscription', 'cancel_url'],
      key: 'cancel_url',
      render: (data) => (
        <span>
          {' '}
          <a href={data} target="_blank" rel="noreferrer">
            Cancel
          </a>{' '}
        </span>
      ),
    },
    {
      title: 'Payment Status',
      dataIndex: ['subscription', 'payment_status'],
      key: 'payment_status',
    },
  ];

  return (
    <Table
      dataSource={tableData}
      columns={tableColumns}
      //   pagination
      bordered
      size="small"
      expandable={{ expandedRowRender }}
      rowKey={(record) => record?.subscription?.id}
      // style={{ maxWidth: '980px' }}
      loading={loading}
    />
  );
};

export default SubscriptionTable;

import MainLayout from 'components/layouts/MainLayout';
import { useGetLimitAndUsageQuery } from 'generated/graphql';
import APIUsages from './ApiUsages';

const ApiUsagesContainer = () => {
  const { data: limitAndUsageData, loading: limitAndUsageLoading } = useGetLimitAndUsageQuery();

  return (
    <MainLayout
      title="API Usages"
      subtitle="How Much You Have Used & What Remains!"
      breadcrumList={['Setting', 'Usages']}
    >
      <APIUsages projectUsages={limitAndUsageData} limitAndUsageLoading={limitAndUsageLoading} />
    </MainLayout>
  );
};

export default ApiUsagesContainer;

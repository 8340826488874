import { Col, Row } from 'antd';
import styles from './layouts.module.scss';

const AuthLayout = ({ children }) => {
  return (
    <Row className={styles.authLayout} align="middle">
      <Col md={12}>
        <div className={styles.leftColContentWrapper}>
          <img src="static/login/login_page_bg2.svg" alt="leftBanner" />
        </div>
      </Col>
      <Col
        md={12}
        xs={24}
        // className={styles.rightColumn}
      >
        {children}
      </Col>
    </Row>
  );
};

export default AuthLayout;

import { Breadcrumb, Layout, Tag, Typography } from 'antd';
import styles from './headers.module.scss';

type PageHeaderProps = {
  projectName: string;
  trialText: string;
  breadcrumList: string[];
};

const PageHeader: React.FC<PageHeaderProps> = ({ projectName, breadcrumList, trialText }) => {
  const { Header } = Layout;
  const { Text } = Typography;

  const handleProjectShortName = (projectName): string => {
    const nameArrLength = projectName?.split(' ')?.length;
    return nameArrLength > 1
      ? projectName
          .split(' ')
          .splice(0, 2)
          .map((word) => word[0])
          .join('')
      : projectName?.slice(0, 2);
  };

  return (
    <Header className={styles.pageHeader}>
      <div className={styles.headerContent}>
        <div className={styles.titleWrapper}>
          <div className={styles.projectIcon}>
            <div className={styles.iconWrapper}>
              <Text className={styles.iconFont}>{handleProjectShortName(projectName)}</Text>
            </div>
          </div>
          <Breadcrumb className={styles.breadCrumb}>
            <Breadcrumb.Item key="piper_todo" className={styles.breadCrumbItem}>
              {projectName || ''}
            </Breadcrumb.Item>
            {breadcrumList?.map((item) => (
              <Breadcrumb.Item className={styles.breadCrumbItem} key={item}>
                {item}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          {trialText && (
            <div style={{ marginLeft: '25px' }}>
              <Tag color="red">Trial expires in 10 days</Tag>
            </div>
          )}
        </div>
      </div>
    </Header>
  );
};

export default PageHeader;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Divider, Popover, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { ConnectionType } from 'types/contents';

type ActionColumnProps = {
  connection: ConnectionType;
  onActionClick: Function;
  projectModelName: string;
  textId: string;
  deleteLoading: boolean;
  onContentDelete: Function;
};

const ActionColumn = ({
  connection,
  onActionClick,
  projectModelName,
  textId,
  deleteLoading,
  onContentDelete,
}: ActionColumnProps) => {
  const [isPopOver, setIsPopOver] = useState(false);
  const hidePopOver = (value) => {
    setIsPopOver(value);
  };
  return connection === undefined ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: 100,
        margin: '0 auto',
      }}
    >
      <Tooltip title="Add/Edit Relation">
        <img
          src="/static/pages/console/content/relations.svg"
          alt="relations"
          style={{ cursor: 'pointer', borderRadius: '5px' }}
          onClick={() =>
            onActionClick({
              action: 'relation',
              id: textId,
              model: projectModelName,
            })
          }
        />
        {/* <LinkOutlined
          onClick={() =>
            onActionClick({
              action: 'relation',
              id: textId,
              model: projectModelName,
            })
          }
        /> */}
      </Tooltip>
      <Tooltip title="Edit Data">
        <img
          src="/static/pages/console/content/edit.svg"
          alt="add/edit"
          style={{ cursor: 'pointer', borderRadius: '5px' }}
          onClick={() => {
            onActionClick({
              action: 'edit',
              id: textId,
              model: projectModelName,
            });
          }}
        />
        {/* <EditOutlined
          onClick={() => {
            onActionClick({
              action: 'edit',
              id: textId,
              model: projectModelName,
            });
          }}
        /> */}
      </Tooltip>
      <Tooltip title="Delete">
        <Popover
          title="Are You Sure?"
          trigger="click"
          placement="left"
          open={isPopOver}
          onOpenChange={hidePopOver}
          content={
            <Space>
              <button
                onClick={() => {
                  setIsPopOver(false);
                }}
              >
                No
              </button>
              <Divider />
              <Button
                type={'primary'}
                loading={deleteLoading}
                onClick={() => onContentDelete(textId)}
                className="ml-1"
              >
                Yes
              </Button>
            </Space>
          }
        >
          <img
            src="/static/pages/console/content/delete.svg"
            alt="delete"
            style={{ cursor: 'pointer', borderRadius: '5px' }}
            onClick={() => {
              setIsPopOver(true);
            }}
          />
          {/* <DeleteOutlined
            onClick={() => {
              setIsPopOver(true);
            }}
          /> */}
        </Popover>
      </Tooltip>
    </div>
  ) : (
    <div>
      <Tooltip title="Edit Data">
        <img
          src="/static/pages/console/content/edit.svg"
          alt="add/edit"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            onActionClick({
              tagMore: true,
              action: 'edit',
              id: textId,
              model: projectModelName,
            })
          }
        />
        {/* <EditOutlined
          onClick={() =>
            onActionClick({
              tagMore: true,
              action: 'edit',
              id: textId,
              model: projectModelName,
            })
          }
        /> */}
      </Tooltip>
    </div>
  );
};

export default ActionColumn;

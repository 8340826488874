import { PROJECT_DELETE, PROJECT_LIST, PROJECT_SWITCH } from 'configs/index';
import constate from 'constate';
import { IProjectList } from 'dto/project-list.dto';
import { ProjectSwitchDto } from 'dto/project-switch.dto';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { httpService } from 'utils/httpService';
import { useApolloClient } from '@apollo/client';

const fetchProjects = async (): Promise<IProjectList> => {
  const res = await httpService.post(PROJECT_LIST);
  return res.data;
};

const switchProject = async (project_id: string): Promise<ProjectSwitchDto> => {
  console.log('switching project : ', project_id);
  const res = await httpService.post(PROJECT_SWITCH, { id: project_id });
  return res.data;
};

const deleteProject = async (project_id: string): Promise<ProjectSwitchDto> => {
  const res = await httpService.post(PROJECT_DELETE, { id: project_id });
  return res.data;
};

const useProjectsContext = () => {
  const client = useApolloClient();
  const [selectedProject, setSelectedProject] = useState<string>(null);
  const query = useQuery(['projects'], fetchProjects);
  const switchProjectRequest = useMutation((id: string) => switchProject(id));
  const deleteProjectRequest = useMutation((id: string) => deleteProject(id));

  const handleSwitchProject = useCallback(
    async (id: string) => {
      await switchProjectRequest.mutateAsync(id);
      await client.resetStore();
      setSelectedProject(id);
    },
    [client, switchProjectRequest]
  );

  const handleProjectDelete = useCallback(
    async (id: string) => {
      await deleteProjectRequest.mutateAsync(id);
      await client.resetStore();
      //setSelectedProject(id);
    },
    [client, deleteProjectRequest]
  );

  return {
    query,
    handleSwitchProject,
    handleProjectDelete,
    selectedProject,
  };
};

export const [
  ProjectsProvider,
  useProjectList,
  refetchProject,
  //useProjectSwitchMutation,
  useSwitchProject,
  useDeleteProject,
  useSelectedProject,
] = constate(
  useProjectsContext,
  ({ query }) => query,
  ({ query: { refetch } }) => refetch,
  //({ mutation }) => mutation,
  ({ handleSwitchProject }) => handleSwitchProject,
  ({ handleProjectDelete }) => handleProjectDelete,
  ({ selectedProject }) => selectedProject
);

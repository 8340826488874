import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const InfoToolTip = ({ tooltipMsg }: { tooltipMsg: string }) => {
  return (
    <Tooltip title={tooltipMsg || ''} style={{ margin: '8px' }}>
      <InfoCircleOutlined style={{ color: '#9197A0' }} />
    </Tooltip>
  );
};

export default InfoToolTip;

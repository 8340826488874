import {
  ApiOutlined,
  AppstoreOutlined,
  AuditOutlined,
  CreditCardOutlined,
  CustomerServiceOutlined,
  KeyOutlined,
  LockOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './sidebars.module.scss';

type SidebarMenuItem = {
  name: string;
  key: string;
  icon?: React.ReactNode;
  subMenu?: SidebarMenuItem[];
  is_super_admin: boolean;
};

const PROJECT_SIDEBAR_MENUS: SidebarMenuItem[] = [
  {
    name: 'Projects',
    key: '/',
    icon: <AppstoreOutlined />,
    subMenu: [
      {
        name: 'Active Projects',
        key: '/projects',
        is_super_admin: false,
      },
      {
        name: '+ New Project',
        key: '/projects/start',
        is_super_admin: false,
      },
    ],
    is_super_admin: false,
  },
  {
    name: 'Account Settings',
    key: '/projects/accounts',
    icon: <KeyOutlined />,
    is_super_admin: false,
  },
  {
    name: 'Subscriptions',
    key: '/projects/subscription',
    icon: <CreditCardOutlined />,
    is_super_admin: false,
  },
  {
    name: 'Help & Support',
    key: '/projects/support',
    icon: <CustomerServiceOutlined />,
    is_super_admin: false,
  },
  // {
  //   name: 'Users',
  //   key: '/projects/users',
  //   icon: <TeamOutlined />,
  //   is_super_admin: false,
  // },
  {
    name: 'Admin',
    key: '/projects/admin',
    is_super_admin: true,
    icon: <LockOutlined />,
  },
  {
    name: 'System API',
    key: '/projects/explorer',
    is_super_admin: true,
    icon: <ApiOutlined />,
  },
  {
    name: 'Audit Log',
    key: '/projects/audit-log',
    is_super_admin: true,
    icon: <AuditOutlined />,
  },
];

const { SubMenu } = Menu;
const ProjectSidebar = ({ tokenData }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuNavigation = ({ key }) => {
    navigate(key);
  };

  const is_super_admin = tokenData?.is_super_admin;

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.menuGroup}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[PROJECT_SIDEBAR_MENUS[0]?.key]}
          defaultOpenKeys={[PROJECT_SIDEBAR_MENUS[0]?.key]}
          onClick={handleMenuNavigation}
          // className={styles.projectMenu}
          // defaultSelectedKeys={['projects']}
          // defaultOpenKeys={['projects']} // for subMenu
          className={styles.menu}
          selectedKeys={[location?.pathname]}
        >
          {PROJECT_SIDEBAR_MENUS?.filter((menu) => {
            if (is_super_admin) return true;
            return menu?.is_super_admin !== !is_super_admin;
          }).map((menu) =>
            menu?.subMenu ? (
              <SubMenu
                key={menu?.key}
                icon={menu.icon}
                title={menu?.name}
                className={styles.submenuTitle}
              >
                {menu?.subMenu?.map((sub) => (
                  <Menu.Item key={sub?.key} className={styles.menuItem}>
                    {sub?.name}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={menu?.key} icon={menu?.icon} className={styles.menuItem}>
                <div className={styles.menuDetails}>{menu?.name}</div>
              </Menu.Item>
            )
          )}
        </Menu>
      </div>
    </div>
  );
};

export default ProjectSidebar;

import VarificationForm from 'components/forms/VarificationForm';
import AuthLayout from 'components/layouts/AuthLayout';
import Logo from 'components/Logo';
import styles from './styles.module.scss';

type VarificationProps = {
  //
};

const Varification: React.FC<VarificationProps> = () => {
  return (
    <AuthLayout>
      <div className={styles.rightColContentWrapper}>
        <div>
          <div className={styles.logo}>
            <Logo />
          </div>
          <h2>
            Create <span>API</span> instantly,
            <br /> Deliver <span>Projects</span> in time.
          </h2>
          <VarificationForm />
        </div>
      </div>
    </AuthLayout>
  );
};

export default Varification;

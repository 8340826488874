import {
  ApartmentOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
  DeploymentUnitOutlined,
  KeyOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './sidebars.module.scss';
import { TokenData } from '../../dto/project-list.dto';
import React from 'react';

type SidebarMenuItem = {
  key: string;
  name: string;
  children: {
    key: string;
    name: string;
    route: string;
    icon?: React.ReactNode;
    subMenu?: SidebarMenuItem[];
  }[];
};

type SettingsProps = {
  tokenData: TokenData;
};

const SettingsSidebar: React.FC<SettingsProps> = ({ tokenData }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const SIDEBAR_MENUS: SidebarMenuItem[] = [
    {
      key: 'common',
      name: 'Common',
      children: [
        {
          key: 'settings',
          name: 'General',
          route: '/console/settings',
          icon: <SettingOutlined />,
        },
        {
          key: 'roles',
          name: 'Roles',
          route: '/console/settings/roles',
          icon: <UserOutlined />,
        },
        {
          key: 'api_secrets',
          name: 'API Secrets',
          route: '/console/settings/api-secrets',
          icon: <KeyOutlined />,
        },
        {
          key: 'webhook',
          name: 'Webhooks',
          route: '/console/settings/webhooks',
          icon: <DeploymentUnitOutlined />,
        },
        {
          key: 'usages',
          name: 'API Usages',
          route: '/console/settings/api-usages',
          icon: <BarChartOutlined />,
        },
      ],
    },
    {
      key: 'teams',
      name: 'Teams',
      children: [
        {
          key: 'teams',
          name: 'Teams & Collaboration',
          route: '/console/settings/teams',
          icon: <TeamOutlined />,
        },
      ],
    },
    {
      key: 'integrations',
      name: 'Integrations',
      children: [
        {
          key: 'addons',
          name: 'Addons',
          route: '/console/settings/addons',
          icon: <AppstoreAddOutlined />,
        },
        {
          key: 'extensions',
          name: 'Plugins',
          route: '/console/settings/extensions',
          icon: <ApartmentOutlined />,
        },
      ],
    },
  ];

  const getSideBarMenuItem = (tokenData) => {
    return SIDEBAR_MENUS.filter((m) => {
      let child = m.children.filter((m) => tokenData.project_access.includes(m.key));
      return child.length > 0;
    });
  };

  return (
    <div className={styles.menuWrapper}>
      {getSideBarMenuItem(tokenData).map((group) => (
        <div className={styles.menuGroup}>
          <p className={styles.groupTitle}>{group.name}</p>
          <Menu
            key={group.key}
            mode="inline"
            selectedKeys={[location?.pathname]}
            onClick={({ key }) => navigate(key)}
            className={styles.menu}
          >
            {group.children
              .filter((m) => tokenData.project_access.includes(m.key))
              .map((menu) => (
                <Menu.Item key={menu?.route} icon={menu?.icon} className={styles.menuItem}>
                  <div className={styles.menuDetails}>{menu?.name}</div>
                </Menu.Item>
              ))}
          </Menu>
        </div>
      ))}
    </div>
  );
};

export default SettingsSidebar;

import RegistrationForm from 'components/forms/RegistrationForm';
import AuthLayout from 'components/layouts/AuthLayout';
import Logo from 'components/Logo';
import styles from './styles.module.scss';

type RegistrationProps = {
  //
};

const Registration: React.FC<RegistrationProps> = () => {
  return (
    <AuthLayout>
      <div className={styles.rightColContentWrapper}>
        <div>
          <div className={styles.logo}>
            <Logo />
          </div>
          <h2>
            Create <span>API</span> instantly,
            <br /> Deliver <span>Projects</span> in time.
          </h2>
          <RegistrationForm />
        </div>
      </div>
    </AuthLayout>
  );
};

export default Registration;

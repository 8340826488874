import { useContext, useState } from 'react';
import { ContentContext } from 'context/ContentContext';
import styles from './modelContainer.module.scss';

import { Dropdown, Menu, MenuProps, Typography } from 'antd';
import {
  DeleteOutlined,
  LockOutlined,
  PartitionOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import pluralize from 'pluralize';

import DeleteConfirmModal from 'components/modals/DeleteConfimModal';
import { capitalize } from 'lodash';
import { gqlErrorMessage, successMessage } from 'utils/message';
import { useDeleteFieldFromModelMutation } from 'generated/graphql';
import { GET_FORM_GENERATION_DATA } from '../../../../graphql/queries/contents';

const { Text } = Typography;

const RelationContainer = ({ connection, index, onOpneDrawer }) => {
  const { state } = useContext(ContentContext);
  const { model, relation, known_as } = connection;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);

  const _replace = (str) => str.replace(/_/g, ' ');

  const items: MenuProps['items'] = [
    {
      key: '2',
      label: 'Delete',
      onClick: () => {
        setIsDeleteModalVisible(true);
      },
      icon: <DeleteOutlined />,
    },
  ];

  /*const menu = (
    <Menu mode="vertical" className={styles.menu}>
      <Menu.Item key="2" icon={<DeleteOutlined />} onClick={() => {}}></Menu.Item>
    </Menu>
  );*/

  const [deleteRelationFromModel] = useDeleteFieldFromModelMutation({
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
      setIsDeleteLoader(false);
    },
    onCompleted: () => {
      successMessage(`${model} Relation Deleted!`);
      setIsDeleteModalVisible(false);
    },
  });

  const deleteRelation = async () => {
    setIsDeleteLoader(true);
    await deleteRelationFromModel({
      variables: {
        identifier: model,
        model_name: state.target,
        is_relation: true,
      },
      refetchQueries: [
        {
          query: GET_FORM_GENERATION_DATA,
          variables: {
            model_name: `${state?.target}`,
          },
        },
      ],
    });
  };

  return (
    <div className={styles.singleModelRelationContainer}>
      <div className={styles.contentContainer} onClick={() => onOpneDrawer(index)}>
        <div className={styles.iconWrapper}>
          <PartitionOutlined />
        </div>
        <div style={{ marginLeft: '8px' }}>
          {known_as ? (
            <Text className={styles.title}>
              {capitalize(model)} {`-> ${capitalize(known_as)}`}
            </Text>
          ) : (
            <Text className={styles.title}>{capitalize(model)}</Text>
          )}
          {known_as ? (
            <Text className={styles.desc}>
              {capitalize(
                `${state.target} ${_replace(relation)} ${
                  relation === 'has_many' ? pluralize(known_as) : known_as
                }`
              )}
            </Text>
          ) : (
            <Text className={styles.desc}>
              {capitalize(
                `${state.target} ${_replace(relation)} ${
                  relation === 'has_many' ? pluralize(model) : model
                }`
              )}
            </Text>
          )}
        </div>
      </div>
      {!connection?.system_generated ? (
        <Dropdown menu={{ items }}>
          <SettingOutlined className={styles.dropDownMenuIcon} />
        </Dropdown>
      ) : (
        <LockOutlined />
      )}
      <DeleteConfirmModal
        loader={isDeleteLoader}
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        handleModelConfirm={deleteRelation}
      />
    </div>
  );
};

export default RelationContainer;

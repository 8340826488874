import { Progress } from 'antd';
import { GetLimitAndUsageQuery } from 'generated/graphql';
import React from 'react';
import styles from './apiUsages.module.scss';

type SingleProgressBarProps = {
  progressData: { item: string; key: string; is_space?: boolean };
  projectUsages: GetLimitAndUsageQuery;
};

const SingleProgressBar: React.FC<SingleProgressBarProps> = ({
  progressData,
  projectUsages: {
    projectUsages: { limits, usages },
  },
}) => {
  // const strokeColor = {
  //   '0%': '#26ae60',
  //   '70%': '#F4C724',
  //   '100%': '#E94A62',
  // };

  const getPercent = (limit, usages) => {
    if (usages) return (usages * 100) / limit;
    return 0;
  };

  const getStrokColor = (percent) => {
    if (percent > 80) {
      return {
        '0%': '#26ae60',
        '70%': '#F4C724',
        [`${80 + (100 - getPercent(100, percent))}%`]: '#E94A62',
      };
    }
    if (percent > 50 && percent <= 80) {
      return {
        '0%': '#26ae60',
        [`${70 + (100 - getPercent(100, percent))}%`]: '#F4C724',
      };
    }

    return {
      from: '#26ae60',
      to: '#26ae60',
    };
  };

  const getFixedNumber = (number, isSpace) => {
    if (isSpace) {
      if (number >= 1024) {
        return `${Number((number / 1024).toFixed()).toLocaleString('en-IN')}GB`;
      }
      return `${Number(number.toFixed()).toLocaleString('en-IN')}MB`;
    }
    return Number(number.toFixed()).toLocaleString('en-IN');
  };

  const singleLimit = limits?.[`${progressData.key}`] || usages?.[`${progressData.key}`] || 0; //! usage?.[`${item.key}`] => for validation purpose
  const singleUsage = usages?.[`${progressData.key}`] ?? 0;
  const percent = getPercent(singleLimit, singleUsage);

  console.log(singleLimit, limits, usages);

  return (
    <div className={styles.singleProgressWrapper}>
      <span style={{ textTransform: 'capitalize' }}>{progressData?.item}</span>
      <div className={styles.progressWrapper}>
        <Progress
          className={styles.progress}
          percent={percent}
          strokeWidth={12}
          showInfo={false}
          strokeColor={getStrokColor(percent)}
          status="active"
        />
        <span>
          {getFixedNumber(singleUsage, progressData.is_space)}/
          {getFixedNumber(singleLimit, progressData.is_space)}
        </span>
      </div>
    </div>
  );
};

export default SingleProgressBar;

import { Button, Form, Input, Typography } from 'antd';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { runtimeEnvList } from 'data/runtimeEnvList';

import { useUpsertFunctionToProjectMutation } from 'generated/graphql';
import { successMessage } from 'utils/message';
import styles from '../../functionForm.module.scss';
import { GET_ALL_FUNCTION_INFO } from '../../../../../graphql/queries/functions'; // don't edit this file

const { Text } = Typography;

export default function CreateFunctionDrawerComponent() {
  // const [form] = Form.useForm();

  const updateCache = (cache, { data }) => {
    const resData = data.upsertFunctionToProject;
    // console.log('Log: updateCache -> resData', resData)
    const existingData = cache.readQuery({
      query: GET_ALL_FUNCTION_INFO,
    })?.projectFunctionsInfo;
    // console.log('Log: updateCache -> existingData', existingData)

    cache.writeQuery({
      query: GET_ALL_FUNCTION_INFO,
      data: {
        projectFunctionsInfo: [...existingData, resData],
      },
    });
  };

  const [createFunction, { loading: createLoading }] = useUpsertFunctionToProjectMutation({
    onCompleted: (data) => {
      // console.log('Log: data', data)
      successMessage('Function Created Successfully!');
    },
    update: updateCache,
  });

  const onFinish = async (values) => {
    await createFunction({
      variables: {
        ...values,
      },
    });
  };

  return (
    <div>
      <Form
        name="function-form"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ remember: false }}
        style={{ marginBottom: '25px' }}
      >
        <Text className={styles.title_2}>Function Name</Text>
        <Form.Item name="name" style={{ marginTop: '10px' }} rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Text className={styles.title_2}>Function Configurations</Text>
        </Form.Item>

        <Form.Item name={['config', 'runtime']} label="Runtime Environment">
          <FixedListSelector list={runtimeEnvList || []} islabelValue />
        </Form.Item>

        <Form.Item name={['config', 'handler']} label="Handler Name">
          <Input />
        </Form.Item>

        <Form.Item name={['config', 'memory']} label="Memory Allocation">
          <Input />
        </Form.Item>

        <Form.Item name={['config', 'time_out']} label="Request Timeout ">
          <Input />
        </Form.Item>

        <div
          style={{
            position: 'absolute',
            bottom: '15px',
            display: 'flex',
            justifyContent: 'center',
            width: '85%',
          }}
        >
          <Button type="primary" htmlType="submit" className="mt-4" loading={createLoading}>
            CREATE
          </Button>
        </div>
      </Form>
    </div>
  );
}

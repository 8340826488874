import { Button } from 'antd';
import React from 'react';
import styles from './cards.module.scss';
import { AddOnsDetails } from '../../generated/graphql';

type AddonCardButtonProps = {
  cardKey?: string;
  addon?: AddOnsDetails;
  onClick: () => void;
};

const AddonCardButton: React.FC<AddonCardButtonProps> = ({ cardKey, addon, ...props }) => {
  function PrimaryBtn({ btnLabel }) {
    return (
      <Button
        className={`${styles.cardBtn} ${styles.primaryCardBtn}`}
        style={{ margin: '0 auto', display: 'block' }}
        {...props}
      >
        {btnLabel}
      </Button>
    );
  }

  const ConfigBtn = ({ btnLabel }) => {
    return (
      <Button
        type="primary"
        className={`${styles.cardBtn}`}
        style={{ margin: '0 auto', display: 'block' }}
        {...props}
      >
        {btnLabel}
      </Button>
    );
  };

  switch (cardKey) {
    case 'locals':
      return Array.isArray(addon.locals) ? (
        <ConfigBtn btnLabel="Configure" />
      ) : (
        <PrimaryBtn btnLabel={'Enable'} />
      );
    /*    case 'auth':
      return addon.enable_auth ? (
        <ConfigBtn btnLabel="Configure" />
      ) : (
        <PrimaryBtn btnLabel={'Enable'} />
      );*/
    case 'history':
      return addon.enable_revision_history ? (
        <ConfigBtn btnLabel="Configure" />
      ) : (
        <PrimaryBtn btnLabel={'Enable'} />
      );
    case 'webhook':
      return addon.system_graphql_hooks ? (
        <ConfigBtn btnLabel="Configure" />
      ) : (
        <PrimaryBtn btnLabel={'Enable'} />
      );
    default:
      return <PrimaryBtn btnLabel={'Configure'} />;
  }
};

export default AddonCardButton;

import { useAuthContext } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Login from './Login';

const LoginContainer = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const message = params.get('message');

  useEffect(() => {
    const email = window.localStorage.getItem('verifyEmail') || false;
    if (email) {
      navigate('/verify');
    }
  }, [navigate]);

  if (auth.isAuthenticated()) return <Navigate to={auth.navigateFrom} />;

  return <Login message={message} />;
};

export default LoginContainer;

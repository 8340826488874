import React from 'react';
import Support from './Support';

const SupportContainer = () => {
  return (
    <div>
      <Support />
    </div>
  );
};

export default SupportContainer;

import { gql } from '@apollo/client';

export const GET_SETTINGS_WEBHOOKS = gql`
  query getSettingsWebhooks {
    listWebHooks {
      _key
      events
      id
      model
      name
      type
      url
      logic_executions
    }
  }
`;
export const GET_SETTINGS_WEBHOOKS_EXECUTABLE_FUNCTIONS_BY_MODEL = gql`
  query getSettingsWebhooksExecutableFunctionsByModel($project_id: String, $model: String) {
    listExecutableFunctions(_id: $project_id, model_name: $model) {
      functions
    }
  }
`;

export const GET_SETTINGS_GENERAL = gql`
  query getSettingsGeneral {
    currentProject {
      id
      _key
      name
      description
    }
  }
`;

export const GET_SETTINGS_TEAMS_ROLES = gql`
  query getSettingsTeamsRoles {
    currentProject {
      roles
    }
  }
`;

export const GET_SETTINGS_TEAMS_MEMBERS = gql`
  query getSettingsTeamsMembers {
    teamMembers {
      first_name
      last_name
      role
      project_user
      id
      email
      avatar
      administrative_permissions
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers(
    $project_id: String
    $limit: Int
    $page: Int
    $first_name: String
    $last_name: String
    $organization_id: String
    $username: String
    $email: String
  ) {
    searchUsers(
      _id: $project_id
      filter: { limit: $limit, page: $page }
      where: {
        email: { eq: $email }
        first_name: { eq: $first_name }
        last_name: { eq: $last_name }
        organization_id: { eq: $organization_id }
        username: { eq: $username }
      }
    ) {
      id
      avatar
      email
      first_name
      last_name
      role
      organizations {
        id
        name
      }
    }
  }
`;

export const GET_SETTINGS_TEAMS_ROLE_SCOPE = gql`
  query getSettingsTeamsRoleScope {
    listRoleScopes {
      administrations
    }
  }
`;

export const GET_LIMIT_AND_USAGE = gql`
  query getLimitAndUsage($project_id: String) {
    projectUsages(_id: $project_id) {
      usages {
        api_bandwidth
        api_calls
        media_bandwidth
        media_storage
        number_of_media
        number_of_records
      }
      limits {
        api_bandwidth
        api_calls
        media_bandwidth
        media_storage
        number_of_media
        number_of_records
      }
    }
  }
`;

export const GET_SETTINGS_ADDONS = gql`
  query getSettingsAddons {
    currentProject {
      id
      _key
      roles
      addons {
        locals
        enable_revision_history
        system_graphql_hooks
      }
    }
  }
`;

import { Typography } from 'antd';

import styles from './headers.module.scss';

const { Text } = Typography;

const ProjectHeader = ({ projectName, isCurrentProject }) => {
  const nameArrLength = projectName?.split(' ')?.length;
  const iconTitle =
    nameArrLength > 1
      ? projectName
          .split(' ')
          .splice(0, 2)
          .map((word) => word[0])
          .join('')
      : projectName?.slice(0, 2);

  return (
    // place this formItem inside a formItem with description prop
    <div
      className={styles.projectHeader}
      style={{ background: `${!isCurrentProject ? '#000000' : ''}` }}
    >
      <Text className={styles.iconFont}>{String(iconTitle).toUpperCase() || ''}</Text>
    </div>
  );
};

export default ProjectHeader;

import Logo from 'components/Logo';
import styles from './styles.module.scss';
import LoginForm from 'components/forms/LoginForm';
import AuthLayout from 'components/layouts/AuthLayout';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Alert } from 'antd';

type LoginProps = {
  message?: string;
};

// Configure Firebase.
const config = {
  apiKey: 'AIzaSyCdeusUrnrISqWawXzfJgxO1s6LemNFVqM',
  authDomain: 'apito-cms.firebaseapp.com',
  projectId: 'apito-cms',
  storageBucket: 'apito-cms.appspot.com',
  messagingSenderId: '981552126007',
  appId: '1:981552126007:web:8d86f206ffa7b6bc3a07b2',
};
firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/console',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

const Login: React.FC<LoginProps> = ({ message }) => {
  // Your web app's Firebase configuration
  return (
    <AuthLayout>
      <div className={styles.rightColContentWrapper}>
        <div>
          <div className={styles.logo}>
            <Logo />
          </div>
          <h2>
            Create <span>API</span> instantly,
            <br /> Deliver <span>Projects</span> in time.
          </h2>
          {message && <Alert style={{ width: '500px', marginBottom: '25px' }} message={message} />}
          <LoginForm />
          {/*<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />*/}
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;

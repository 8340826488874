import { MailOutlined } from '@ant-design/icons';
import { Button, Input, Tag } from 'antd';
import React, { useState } from 'react';
import styles from 'pages/ForgotPassword/styles.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuthContext } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const schema = yup
  .object({
    email: yup.string().required('Email is a required field.'),
  })
  .required();

export type ForgotPasswordFormData = {
  email: string;
};

export type ForgotPasswordFormProps = {
  //
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = () => {
  const navigate = useNavigate();

  const { passRecoveryRequest, loading } = useAuthContext();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({
    resolver: yupResolver(schema),
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handlePassRequest = async (data) => {
    const message = await passRecoveryRequest(data);
    if (message) {
      setErrorMessage(message);
    }
  };

  return (
    <form onSubmit={handleSubmit(handlePassRequest)}>
      {errorMessage && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errorMessage}
        </Tag>
      )}
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Email"
            type="text"
            prefix={<MailOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.email && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.email?.message}
        </Tag>
      )}
      <p>A recovery instruction e-mail will be send to the given address</p>
      <div className={styles.buttons} style={{ marginBottom: '1rem' }}>
        <Button type="primary" size="large" htmlType="submit" loading={loading}>
          RECOVER
        </Button>
        <Button
          size="large"
          onClick={() => {
            navigate('/login');
          }}
        >
          BACK TO LOGIN
        </Button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;

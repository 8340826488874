import { ApartmentOutlined, HistoryOutlined, TranslationOutlined } from '@ant-design/icons';

export const ADDON_DATA = [
  {
    key: 'locals',
    icon: TranslationOutlined,
    title: 'Localization',
    description:
      'Write your content in more than one language with advanced local filter option support in API',
  },
  /*  {
    key: 'auth',
    icon: KeyOutlined,
    title: 'Authentication API',
    description: 'Add support for user authentication system to your graphql api',
  },*/
  {
    key: 'history',
    icon: HistoryOutlined,
    title: 'Revision History',
    description:
      'Keep track of your content editing history in realtime and restore from/to a revision of your content with ease',
  },
  {
    key: 'webhook',
    icon: ApartmentOutlined,
    title: 'Websocket',
    description:
      'Build realtime application with GraphQL Subscription feature which is powered by websocket',
  },
  // {
  //     key: 'api_req',
  //     icon: <ApiOutlined />,
  //     title: 'Additional API Requests',
  //     description: 'Ran out of your api request limit ? Upgrade to a better subscription plan or add some additional api request for your api',
  // },
  // {
  //     key: 'records',
  //     icon: <ReconciliationOutlined />,
  //     title: 'Additional Records',
  //     description: 'Ran out of your content storage limit ? add some additional content space your app',
  // }
];

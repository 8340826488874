import React, { SVGProps } from 'react';

const ArangodbLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="74.499 105.376 55.861 54.285"
    >
      <path
        d="M128.867 130.714c-1.045-3.482-3.378-6.163-5.467-9.02-3.97-5.362-9.123-8.322-13.86-8.357-9.088-.174-15.495 3.97-18.246 10.48-.348.836-.8 1.184-1.637 1.358-3.03.696-6.06 1.393-8.88 2.82-1.915.975-3.865 1.88-5.084 3.76-1.532 2.368-1.358 4.875-.766 7.487 1.532 6.476 5.467 10.48 11.978 11.943 4.005.87 7.904.766 11.56-1.323 2.298-1.288 4.144-3.133 5.955-5.015.453-.487.8-.592 1.393-.313l3.343 1.637c3.552 1.776 7.173 3.238 11.247 2.994 6.477-.383 10.342-4.736 9.924-11.073-.14-2.507-.73-4.944-1.463-7.382zm-23.992 8.8c-3.9 2.6-7.94 4.875-12.605 5.815-1.497.314-2.995.418-4.005.348-3.76 0-6.93-.382-9.715-2.298-3.273-2.3-4.666-7.313-2.786-10.83.592-1.15 1.602-1.985 2.716-2.647 3.865-2.403 8.183-3.517 12.57-4.422 4.56-.975 9.192-2.02 13.963-1.985 2.298 0 4.352.905 5.815 2.75 1.288 1.67.906 3.552.28 5.328-1.22 3.343-3.308 6-6.233 7.94zm23.713 2.856c-1.463 3.656-4.562 4.875-8.078 5.397-3.76.557-6.86-1.22-10.063-2.716-1.045-.487-2.055-1.114-3.134-1.532-.94-.348-1.08-.697-.313-1.358 1.184-1.497 2.368-2.995 3.308-4.667 1.15-2.472 1.846-5.084 1.846-7.834 0-4.248-2.264-6.13-6.546-6.616-.836-.104-1.706-.035-2.577-.035-2.9.2-5.78.662-8.636 1.184-.592-.035-1.323.662-1.706.035-.278-.453.418-1 .73-1.497 2.1-3.62 5.223-5.85 9.158-7.104 3.447-1.08 6.86-1.323 10.4-.383 2.925.766 5.014 2.542 6.93 4.666 2.368 2.6 4.527 5.328 6.268 8.357 1.706 2.96 2.577 6.128 2.82 9.506.07 1.566.2 3.1-.418 4.597z"
        fill="#577138"
      />
      <path
        d="M115.565 145.93c2.576 1.114 5.153.905 7.695-.035 3.1-1.15 4.562-3.517 4.77-6.686.28-4.457-1.114-8.426-3.656-12.013-1.602-2.263-3.24-4.56-5.12-6.616-2.577-2.786-5.64-4.527-9.54-4.63-4.04-.104-7.8.836-11.177 3.03-1.88 1.22-3.447 2.9-4.144 5.153 2.855-.522 5.745-.975 8.636-1.184.174-.07.348-.2.522-.174 1.498.104 2.9-.2 4.318-.696 1.67-.592 3.24.07 4.562 1.044 2.855 2.124 4.84 4.805 4.84 8.53 0 2.68-2.333 5.78-5.64 5.57-.453-.034-.94-.07-1.358.244-.905 1.706-2.124 3.168-3.308 4.666 1.114 1.15 2.68 1.324 4.04 1.95 1.463.766 3.065 1.22 4.562 1.846z"
        fill="#dcdf72"
      />
      <path
        d="M126.185 128.277c-1.776-3.03-3.935-5.78-6.268-8.357-1.915-2.124-4.005-3.9-6.93-4.666-3.55-.94-6.964-.697-10.4.383-3.935 1.22-7.07 3.482-9.158 7.104-.278.487-1 1.045-.73 1.497.383.592 1.114-.07 1.706-.035.696-2.263 2.228-3.935 4.144-5.153 3.378-2.194 7.138-3.1 11.178-3.03 3.9.104 6.964 1.8 9.54 4.63 1.88 2.054 3.517 4.353 5.12 6.616 2.542 3.587 3.935 7.556 3.656 12.013-.2 3.17-1.67 5.537-4.77 6.686-2.542.94-5.12 1.15-7.695.035-1.497-.627-3.065-1.08-4.527-1.8-1.358-.66-2.925-.836-4.04-1.95-.8.66-.627 1 .313 1.358 1.08.417 2.1 1.045 3.134 1.532 3.204 1.497 6.337 3.273 10.063 2.716 3.517-.522 6.616-1.775 8.078-5.397.592-1.497.488-3.03.383-4.56-.2-3.482-1.08-6.65-2.786-9.6zm-15.112 3.308c.627-1.776 1-3.656-.28-5.328-1.462-1.88-3.482-2.75-5.815-2.75-4.736-.035-9.367 1-13.998 1.985-4.387.94-8.705 2.02-12.57 4.422-1.114.697-2.124 1.532-2.716 2.647-1.845 3.517-.487 8.53 2.786 10.83 2.786 1.95 5.954 2.298 9.715 2.298 1 .07 2.542-.07 4.004-.348 4.666-.94 8.705-3.204 12.605-5.815 2.994-1.95 5.084-4.596 6.268-7.94zm-8.044 8.183c-3.308 1.985-6.65 3.9-10.516 4.596-3.134.557-6.338.732-9.47-.07-3.656-.87-6.477-4.18-6.616-7.94-.07-2.055.94-3.726 2.507-5.05 2.438-2.054 5.328-3.134 8.392-3.796.07 0 .174.104.278.174 2.577-1.985 5.502-2.368 8.636-2.124.278-.66.94-.453 1.428-.592 2.68-.662 5.397-.94 8.078-.383 5.05 1 5.676 3.83 4.04 7.94-1.288 3.308-3.83 5.467-6.755 7.243zm8.6-2.508c3.308.2 5.64-2.9 5.64-5.57 0-3.726-1.985-6.407-4.84-8.53-1.358-.975-2.9-1.636-4.562-1.045-1.428.487-2.82.8-4.318.697-.174 0-.348.14-.522.174.87 0 1.706-.07 2.577.034 4.283.488 6.546 2.368 6.546 6.616 0 2.75-.696 5.362-1.846 7.835.383-.245.836-.245 1.323-.2z"
        fill="#a2b24f"
      />
      <path
        d="M105.745 124.62c-2.68-.557-5.432-.243-8.078.383-.488.104-1.15-.07-1.428.592 3.064.592 5.432 2.263 7.034 4.944 1.323 2.194.975 4.318-1.044 6.024-2.124 1.846-4.666 2.716-7.452 3.03-3.726.418-7.07-.696-10.203-2.6-.87-.522-1.114-1.22-1-2.16.348-2.96 1.845-5.258 4.04-7.138-.105-.07-.2-.174-.278-.174-3.064.662-5.955 1.74-8.392 3.796-1.567 1.323-2.577 2.995-2.507 5.05.14 3.726 2.995 7.034 6.616 7.94 3.17.766 6.338.626 9.47.07 3.83-.696 7.208-2.6 10.48-4.596 2.925-1.776 5.467-3.935 6.755-7.173 1.67-4.144 1.045-6.964-4.004-7.974z"
        fill="#dcdf72"
      />
      <path
        d="M83.564 134.823c-.105.905.14 1.636 1 2.16 3.134 1.915 6.477 3.03 10.203 2.6 2.75-.313 5.327-1.184 7.452-3.03 1.985-1.706 2.368-3.83 1.044-6.024-1.637-2.68-3.97-4.353-7.034-4.944-3.1-.244-6.06.14-8.636 2.124-2.228 1.88-3.726 4.18-4.04 7.104z"
        fill="#5e3108"
      />
    </svg>
  );
};

export default ArangodbLogo;

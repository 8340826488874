import { Button, Drawer, Form, Input } from 'antd';
import { useState } from 'react';

import FixedListSelector from 'components/inputs/FixedListSelector';
import { CloudFunctionType, useUpsertFunctionToProjectMutation } from 'generated/graphql';
import { successMessage } from 'utils/message';
import CreateFunctionDrawerComponent from '../create-function/CreateFunctionDrawerComponent';
import { GET_ALL_FUNCTION_INFO } from '../../../../../graphql/queries/functions';

function CloudProviderDrawerComponent({ functionData }: { functionData: CloudFunctionType }) {
  // const [pluginList, setPluginList] = useState([]);
  const [form] = Form.useForm();
  const [isConnect, setIsConnect] = useState<boolean>(false);

  const [isFunctionCreateDrawer, setIsFunctionCreateDrawer] = useState(false);

  /*const region =
    payload?.function_provider === Function_Provider_Enum['Lambda']
      ? awsRegion
      : payload?.function_provider === Function_Provider_Enum['GCLOUD']
      ? gCloudRegion
      : [''];*/

  /*const [getListPluginIds, { loading }] = useListPluginIdsLazyQuery({
    onError: ({ graphQLErrors, networkError }) => {
      setPluginList([]);
      gqlErrorMessage(graphQLErrors, networkError);
    },
    onCompleted: (data) => {
      const list = data?.listPluginIds?.plugins;
      setPluginList(list);
      // setFuncList(data?.thirdPartyFunctionQuery)
    },
  });*/

  const onCloseDrawer = () => {
    setIsFunctionCreateDrawer(false);
  };

  const onFinish = async (values) => {
    // console.log('Log: CloudProviderDrawerComponent -> values', values)
    // const funcConfig = funcList.filter(item => item?.remote_function_name === payload?.function)?.[0]
    console.log(values);
    await connectFunction({
      variables: {
        name: functionData?.name,
        function_provider_id: values.function_provider_id,
        function_path: values.function_path,
        function_exported_variable: values.exported_variable,
        //region: payload?.region,
        update: true,
        // provider_config: funcConfig,
      },
    });
  };

  const updateCache = (cache, { data }) => {
    const resData = data.upsertFunctionToProject;
    const existingData = cache.readQuery({
      query: GET_ALL_FUNCTION_INFO,
    })?.projectFunctionsInfo;

    cache.writeQuery({
      query: GET_ALL_FUNCTION_INFO,
      data: {
        projectFunctionsInfo: [
          ...existingData.map((item) => {
            if (item.name === functionData?.name) {
              // console.log('name matched');
              return resData;
            }
            return item;
          }),
        ],
      },
    });

    // const existingData1 = cache.readQuery({
    //   query: GET_ALL_FUNCTION_INFO,
    // })?.projectFunctionsInfo;
    // console.log('Log: updateCache -> existingData1', existingData1)
  };

  const [connectFunction, { loading: connectLoading }] = useUpsertFunctionToProjectMutation({
    onCompleted: (data) => {
      // console.log('Log: data', data);
      successMessage('Function Connected Successfully!');
      // form.resetFields()
      window.location.reload();
    },
    update: updateCache,
  });

  const onValuesChange = (values) => {
    // if (values?.function_provider === Function_Type_Enum['GCLOUD']) {
    //   errorMessage('Google Cloud Function Is Not Supported Right Now ! Coming Soon.');
    //   setShowSettings(false);
    // } else {
    console.log('selected -> ', values);
    if (values?.function_provider_name) {
      setIsConnect(true);
    } else if (values?.function_path) {
      setIsConnect(true);
    }
    // }
  };

  function getProviderForm(type: string) {
    switch (type) {
      case 'local':
        return (
          <>
            <Form.Item name="function_path" label="Installed Functions List">
              <FixedListSelector
                placeholder="Choose a Function"
                //list={pluginList}
                islabelValue
              />
            </Form.Item>
            <Form.Item name="exported_variable" label="Exported Variable Name">
              <Input placeholder="Ex: HelloWorldFunc" />
            </Form.Item>
          </>
        );
      case 'external':
        return (
          <>
            <Form.Item name="function_path" label="Function Path (.so)">
              <Input placeholder=".so File Path. Ex: hello-world/hello-world.so" />
            </Form.Item>

            <Form.Item name="exported_variable" label="Exported Variable Name">
              <Input placeholder="Ex: HelloWorldFunc" />
            </Form.Item>
          </>
        );
    }
  }

  return (
    <div>
      <Form
        name="cloud-provider"
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <div>{getProviderForm('external')}</div>

        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={connectLoading}
              disabled={!isConnect}
            >
              CONNECT
            </Button>
            {/*
            <Button className="ml-2"
                  style={{ background: '#E7ECF3', border: '1px solid #E7ECF3', color: 'var(--themeColor)' }}
                  onClick={() => { setIsFunctionCreateDrawer(true) }}
                  > ADD NEW FUNCTION
            </Button>
            */}
          </div>
        </Form.Item>
      </Form>

      <Drawer
        title="Create a Lambda Function"
        placement="right"
        extra={<Button type="link" onClick={onCloseDrawer}>{`< Back`}</Button>}
        closable
        onClose={onCloseDrawer}
        visible={isFunctionCreateDrawer}
      >
        <CreateFunctionDrawerComponent />
      </Drawer>
    </div>
  );
}

export default CloudProviderDrawerComponent;

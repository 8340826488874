import { useCallback, useEffect, useState } from 'react';

import { Form, Button, Input, PageHeader as ContentHeader, Select, Typography } from 'antd';

import debounce from 'lodash/debounce';

import { TableSkeleton } from './TableSkeleton';
import { gqlErrorMessage } from '../../../utils/message';
import AuditLogsTable from './AuditLogsTable';
import {
  useGetAuditLogsLazyQuery,
  useGetAuditLogsQuery,
  useSearchApitoUsersLazyQuery,
} from '../../../generated/graphql';
import { Option } from 'antd/es/mentions';

const { Title } = Typography;

function Audit() {
  const [searchStr, setSearchStr] = useState('');

  const [searchAudits, { loading, data }] = useGetAuditLogsLazyQuery({
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
    },
    onCompleted: () => {
      // console.log('Log: data', data)
    },
  });

  let onSearch = (searchStr) => {
    searchAudits({
      variables: {
        first_name: searchStr,
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  onSearch = useCallback(debounce(onSearch, 800), []);

  useEffect(() => {
    if (!searchStr) {
      onSearch();
    } else if (searchStr) {
      onSearch(searchStr);
    }
  }, [onSearch, searchStr]);

  // @ts-ignore
  return (
    <ContentHeader admin>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 56,
          color: '#687074',
        }}
      >
        <Title
          level={5}
          style={{
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 24,
            color: '#687074',
          }}
        >
          Audit Logs
        </Title>
        <div style={{ height: 36, width: '40vw' }}>
          <Form.Item
            name={['address', 'province']}
            noStyle
            rules={[{ required: true, message: 'Province is required' }]}
          >
            <Select placeholder="Select Organization">
              <Option value="Zhejiang">Zhejiang</Option>
              <Option value="Jiangsu">Jiangsu</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={['address', 'province']}
            noStyle
            rules={[{ required: true, message: 'Province is required' }]}
          >
            <Select placeholder="Select Organization">
              <Option value="Zhejiang">Zhejiang</Option>
              <Option value="Jiangsu">Jiangsu</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={['address', 'street']}
            noStyle
            rules={[{ required: true, message: 'Street is required' }]}
          ></Form.Item>
          <Button type="primary" size="medium" style={{ width: 150 }} loading={loading}>
            FILTER
          </Button>
        </div>
      </div>

      <div className="mt-3">
        <TableSkeleton loading={loading}>
          <AuditLogsTable tableData={data?.getAuditLogs || []} />
        </TableSkeleton>
      </div>
    </ContentHeader>
  );
}

export default Audit;

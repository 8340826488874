import React, { useEffect } from 'react';
import { Button, Checkbox, Empty, Form, Input, Select, Skeleton } from 'antd';
import GroupedCheckBoxWithAllSelect from 'components/inputs/GroupedCheckBoxWithAllSelect';
import Loader from 'components/loader/Loader';
import {
  useCreateSettingsWebhookMutation,
  useGetAllModelsQuery,
  useGetSettingsWebhooksExecutableFunctionsByModelLazyQuery,
  useRoleScopeQuery,
} from 'generated/graphql';
import { successMessage } from 'utils/message';
import { GET_SETTINGS_WEBHOOKS } from '../../../../graphql/queries/settings';

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

const AddWebhooksDrawerComponent = () => {
  const [modelList, setModelList] = React.useState([]);
  const [executableFunctions, setExecutableFunctions] = React.useState([]);
  const [payload, setPayload] = React.useState<Record<string, any>>({});
  const [form] = Form.useForm();
  const formValueUpdater = form.setFieldsValue;

  const { loading: modelsListLoading, data: modelData } = useGetAllModelsQuery();

  const { data: funcData } = useRoleScopeQuery();

  const [getExecutableFunctions, { loading }] =
    useGetSettingsWebhooksExecutableFunctionsByModelLazyQuery({
      onCompleted: (data) => {
        setExecutableFunctions(data?.listExecutableFunctions?.functions);
      },
    });

  useEffect(() => {
    if (modelData) {
      const modelList = modelData?.projectModelsInfo?.map((item) => item.name) ?? [];
      setModelList([...modelList]);
    }
  }, [modelData]);

  useEffect(() => {
    if (payload?.model) {
      getExecutableFunctions({
        variables: {
          model: payload?.model,
        },
      });
    }
  }, [getExecutableFunctions, payload?.model]);

  const cacheUpdate = (cache, { data }) => {
    const existingHooks = cache.readQuery({
      query: GET_SETTINGS_WEBHOOKS,
    })?.listWebHooks;

    cache.writeQuery({
      query: GET_SETTINGS_WEBHOOKS,
      data: {
        listWebHooks: [...existingHooks, data],
      },
    });
  };

  const [addHook, { loading: addHookLoading }] = useCreateSettingsWebhookMutation({
    onCompleted: (data) => {
      successMessage('New Webhook created successfully!');
      form.resetFields();
      setPayload({});
    },
    update: cacheUpdate,
  });

  const onFinish = (values) => {
    addHook({
      variables: {
        ...values,
      },
    });
  };

  const onFormValueChange = async (values) => {
    await setPayload({ ...payload, ...values });
    if (values.url) form.validateFields(['logic_executions']);
    else if (values.logic_executions) form.validateFields(['url']);
  };

  const getWebUrlAndFuncExecValidationRules = (formItemName: string = '') => {
    const required = !(payload?.url || payload?.logic_executions?.length);
    return [
      {
        required,
        message:
          formItemName === 'logic_executions' ? 'Function Execution is required!' : undefined,
      },
    ];
  };

  return (
    <div>
      <Form
        name="webhook"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onFormValueChange}
        validateMessages={validateMessages}
        initialValues={{ remember: true }}
        form={form}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name="model" label="Model" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder="Select a Model"
            notFoundContent={
              modelsListLoading ? (
                <Loader size="small" />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
            optionFilterProp="children"
            style={{ width: '100%' }}
          >
            {modelList.length > 0
              ? modelList.map((name, i) => (
                  <Select.Option key={i} label={name} value={name}>
                    {name}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>

        <Form.Item name="events" label="Operation" rules={[{ required: true }]}>
          <Checkbox.Group options={['create', 'update', 'delete']} />
        </Form.Item>

        <Form.Item name="url" label="Webhook URL" rules={getWebUrlAndFuncExecValidationRules()}>
          <Input />
        </Form.Item>
        <div>
          {loading ? (
            <Skeleton.Input active style={{ display: 'block' }} />
          ) : funcData?.listRoleScopes?.functions?.length > 0 ? (
            <GroupedCheckBoxWithAllSelect
              options={
                payload?.model
                  ? executableFunctions || []
                  : funcData?.listRoleScopes?.functions || []
              }
              formValueUpdater={formValueUpdater}
              name="logic_executions"
              label="Function Execution"
              subTitle="Select Which Function You would like to Execute While Triggering this Event"
              rules={getWebUrlAndFuncExecValidationRules('logic_executions')}
            />
          ) : null}
        </div>

        <Form.Item style={{ position: 'absolute', bottom: 0, width: '88%' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={addHookLoading}
            style={{ display: 'block', margin: '0 auto' }}
          >
            ADD HOOK
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddWebhooksDrawerComponent;

import { AddOnsDetails } from 'generated/graphql';
import React from 'react';
import LanguageSetting from './LanguageSetting';

type AddonDrawerProps = {
  data: AddOnsDetails;
  roles: string[];
  componentKey: string;
};

const AddonDrawer: React.FC<AddonDrawerProps> = ({ data, roles, componentKey }) => {
  switch (componentKey) {
    case 'locals':
      return <LanguageSetting data={data} />;
    case 'auth':
    //return <AuthSetting roles={roles} data={data} />;
    case 'history':
      return (
        <div>
          <div>
            <span>
              This feature is available in pro project. Upgrade Your Project to Use this Feature
            </span>
          </div>
          <div style={{ height: '50px' }} />
          <div>
            <span>
              If you need this feature is your free project! Kindly drop an email at
              accounts@apito.io
            </span>
          </div>
        </div>
      );
    default:
      return (
        <div>
          <div
            style={{
              color: '#000000',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '19px',
            }}
          >
            <span>This feature will be</span>
            <br />
            <span>integrated soon ....</span>
            <br />
            <br />
            <span>Stay tuned</span>
          </div>
          {/* <Button className={styles.cardBtn}>I WANT THIS</Button> */}
        </div>
      );
  }
};

export default AddonDrawer;

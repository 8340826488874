import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import ContentSidebar from 'components/sidebars/ContentSidebar';
import { ContentContext, ContentReducer } from 'context/ContentContext';
import { Reducer, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './layouts.module.scss';

const ContentsLayout = () => {
  const [state, dispatch] = useReducer<Reducer<any, any>>(ContentReducer, { target: '' });

  return (
    <ContentContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Layout hasSider className={styles.layoutWrapper}>
        <Sider className={styles.sidebar}>
          <ContentSidebar />
        </Sider>
        <div className={styles.layoutContent}>
          <Layout className={styles.layoutContentInner}>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </div>
      </Layout>
    </ContentContext.Provider>
  );
};

export default ContentsLayout;

import { useState } from 'react';
import { Button, Col, Drawer, Row } from 'antd';

import styles from './addons.module.scss';
import { ADDON_DATA } from './addonsData';
import AddonDrawer from './AddonDrawer';
import AddonCardButton from 'components/cards/AddonCardButton';
import { AddOnsDetails } from '../../../../generated/graphql';
import AddonCard from '../../../../components/cards/AddonCard';

const Addons = ({ data, roles }: { roles: string[]; data: AddOnsDetails }) => {
  const [visible, setVisible] = useState(false);
  const [componentKey, setComponentKey] = useState('');
  const [drawerTitle, setDrawerTitle] = useState('');

  const onAddHandle = (item) => {
    setDrawerTitle(item.title);
    setComponentKey(item.key);
    setVisible(true);
  };

  return (
    <>
      <div className={styles.AddonContainer}>
        <Row gutter={[16, 16]}>
          {ADDON_DATA.map((addon) => (
            <Col sm={4} key={addon.key}>
              <AddonCard
                title={addon.title}
                description={addon.description}
                icon={<addon.icon style={{ fontSize: 40 }} />}
                className="h-100"
              >
                <AddonCardButton
                  addon={data}
                  cardKey={addon.key}
                  onClick={() => onAddHandle(addon)}
                />
              </AddonCard>
            </Col>
          ))}
        </Row>
      </div>

      <Drawer
        title={drawerTitle}
        placement="right"
        width={320}
        extra={<Button type="link" onClick={() => setVisible(false)}>{`< Back`}</Button>}
        closable
        onClose={() => setVisible(false)}
        destroyOnClose
        visible={visible}
      >
        <AddonDrawer roles={roles} data={data} componentKey={componentKey} />
      </Drawer>
    </>
  );
};

export default Addons;

import { Col, Row } from 'antd';
import PriceCard from 'components/cards/PriceCard';

const Upgrades = () => {
  const upgrades = [
    {
      isUpgrade: true,
      title: 'Medium API Calls',
      modalTitle: '50K API Calls',
      subtitle: 'Adds 50,000 API Calls to Existing Project',
      price: 4.99,
      code: 651147,
    },
    {
      isUpgrade: true,
      title: 'Heavy API Calls',
      modalTitle: '1M API Calls',
      subtitle: 'Adds 1 Million API Calls to Existing Project',
      price: 10,
      code: 641682,
    },
    {
      isUpgrade: true,
      title: 'Content Records',
      modalTitle: '10,000 Document Records',
      subtitle: 'Extends Projects with 10,000 Document Records',
      price: 10,
      code: 641683,
    },
    {
      isUpgrade: true,
      title: 'Media CDN',
      modalTitle: '1 GB of Media Storage',
      subtitle: 'Adds 1 GB of Media Storage to Existing Project',
      price: 1.5,
      code: 641684,
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      {upgrades.map((data) => (
        <Col span={5}>
          <PriceCard title={data.title} price={data.price} subtitle={data.subtitle} />
        </Col>
      ))}
    </Row>
  );
};

export default Upgrades;

import { DeleteOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import DeleteConfirmModal from 'components/modals/DeleteConfimModal';
import {
  GetAllFunctionInfoQuery,
  useDeleteFunctionFromProjectMutation,
  useGetAllFunctionInfoQuery,
} from 'generated/graphql';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { successMessage } from 'utils/message';
import styles from './sidebars.module.scss';

export type LogicSidebarProps = {
  children: ReactNode;
  setIsCreate: (data: boolean) => void;
  setFunctionData: (data: GetAllFunctionInfoQuery['projectFunctionsInfo'][0]) => void;
  selectedFunction: string;
  funcList: GetAllFunctionInfoQuery;
};

const LogicSidebar = ({ setIsCreate, setFunctionData }: LogicSidebarProps) => {
  const { data: funcList, refetch } = useGetAllFunctionInfoQuery();

  const navigate = useNavigate();
  const location = useLocation();
  const firstFunction = funcList?.projectFunctionsInfo?.[0]?.name;
  const params = useParams();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [functionNameForDelete, setFunctionNameForDelete] = useState('');

  useEffect(() => {
    if (funcList?.projectFunctionsInfo?.length) {
      if (!params?.logic) {
        const item = funcList?.projectFunctionsInfo?.find((item) => item.name === params?.logic);
        setFunctionData(item);
        navigate(`/console/logic/${firstFunction}`);
      }
    }
  }, [
    firstFunction,
    funcList?.projectFunctionsInfo,
    navigate,
    params?.logic,
    setFunctionData,
    setIsCreate,
  ]);

  const [deleteFunction, { loading: deleteLoader }] = useDeleteFunctionFromProjectMutation({
    onCompleted: () => {
      successMessage('Function Deleted!');
      setFunctionNameForDelete('');
      navigate(`/console/logic/${firstFunction}`);
      setIsDeleteModalVisible(false);
      refetch();
    },
  });

  const handleDeleteModel = () => {
    deleteFunction({
      variables: {
        function: functionNameForDelete,
      },
    });
  };

  const menu = (function_name) => (
    <Menu className={styles.menu}>
      {/* <Menu.Item key="1" icon={<ArrowRightOutlined />} onClick={() => { enterProject(projectID) }}>Enter Project</Menu.Item> */}
      <Menu.Item
        key="1"
        icon={<DeleteOutlined />}
        onClick={(e) => {
          setFunctionNameForDelete(function_name);
          setIsDeleteModalVisible(true);
          e.domEvent.stopPropagation();
        }}
      >
        Delete Function
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Button
        icon={<PlusOutlined />}
        className={styles.createBtn}
        onClick={() => {
          setIsCreate(true);
        }}
      >
        ADD A FUNCTION
      </Button>
      <div className={styles.menuWrapper}>
        <div className={styles.menuGroup}>
          <p className={styles.groupTitle}>Logic</p>
          <Menu mode="inline" className={styles.menu} selectedKeys={[location?.pathname]}>
            {funcList?.projectFunctionsInfo?.map((item, i) => (
              <Menu.Item
                key={`/console/logic/${item.name}`}
                onClick={() => {
                  navigate(`/console/logic/${item.name}`);
                  setFunctionData(item);
                  setIsCreate(false);
                }}
                className={styles.menuItem}
              >
                <div className={styles.menuDetails}>{item.name}</div>
                <Dropdown overlay={menu(item.name)}>
                  <MoreOutlined className={styles.dropDownMenuIcon} />
                </Dropdown>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
      <DeleteConfirmModal
        loader={deleteLoader}
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        handleModelConfirm={handleDeleteModel}
        confirmText={functionNameForDelete}
      />
    </div>
  );
};

export default LogicSidebar;

import { Button, Form, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import pluralize from 'pluralize';
import { ANY_FIXME } from '../../types/common';

export function DynamicFormList({
  field_item,
  ...props
}: {
  field_item: { identifier: string; label: string };
}) {
  return (
    <Form.List name={['form', `${field_item.identifier}`]} {...props}>
      {/* formList name must be same as parent to catch initial value */}
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <Form.Item
              required={false}
              key={field.name}
              style={{
                width: '100%',
                marginBottom: '10px',
              }}
              {...field}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `Please input ${field_item.label} or delete this field.`,
                },
              ]}
              noStyle
            >
              <Input
                placeholder={pluralize(`${field_item.label}`, 1)}
                suffix={
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                }
              />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              style={{ width: '100%' }}
            >
              <PlusOutlined />
              {`Add ${pluralize(`${field_item.label}`, 1)}`}
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
}

export function DynamicFormInsideArrayList({
  field_item,
  namePath,
  ...props
}: {
  field_item: { identifier: string; label: string };
  namePath: ANY_FIXME[];
}) {
  console.log(namePath);
  return (
    <Form.List name={namePath ? [...namePath] : ['form', `${field_item.identifier}`]} {...props}>
      {/* formList name must be same as parent to catch initial value */}
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <Form.Item
              name={namePath ? [...namePath, index] : ['form', `${field_item.identifier}`]}
              required={false}
              key={field.name}
              style={{
                width: '100%',
                marginBottom: '10px',
              }}
              {...field}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `Please input ${field_item.label} or delete this field.`,
                },
              ]}
              noStyle
            >
              <Input
                placeholder={pluralize(`${field_item.label}`, 1)}
                suffix={
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                }
              />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              style={{ width: '100%' }}
            >
              <PlusOutlined />
              {`Add ${pluralize(`${field_item.label}`, 1)}`}
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
}

import { Form, Input, InputNumber } from 'antd';

export function InputGeoField(props) {
  const { field_item, value, namePath, disabled } = props || {};
  const { identifier } = field_item || {};

  const getValidationRules = (item, label) => {
    const required = item?.validation?.required ?? false;
    return [
      {
        required,
        message: `${label} is required!`,
      },
    ];
  };

  return (
    <Form.Item style={{ marginBottom: 0 }}>
      <Input.Group compact>
        <Form.Item
          name={namePath ? [...namePath, 'lat'] : ['form', `${identifier}`, 'lat']}
          initialValue={value?.lat}
          rules={getValidationRules(field_item, 'Latitude')}
          noStyle
        >
          <InputNumber
            style={{ width: '50%' }}
            placeholder="Latitude"
            title="Latitude"
            disabled={disabled}
          />
        </Form.Item>

        <Form.Item
          name={namePath ? [...namePath, 'lon'] : ['form', `${identifier}`, 'lon']}
          initialValue={value?.lon}
          rules={getValidationRules(field_item, 'Longitude')}
          noStyle
        >
          <InputNumber
            style={{ width: '50%' }}
            placeholder="Longitude"
            title="Longitude"
            disabled={disabled}
          />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
}

import { create } from 'zustand';
import { ProjectModel } from '../generated/graphql';

interface ProjectProps {
  projectDetails: ProjectModel;
  setProjectDetails: (data: ProjectModel) => void;
}

const useProjectStore = create<ProjectProps>((set) => ({
  projectDetails: {},
  setProjectDetails: (projectDetails: ProjectModel) =>
    set(() => ({
      projectDetails,
    })),
}));

export default useProjectStore;

import React from 'react';
import { Table } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import ApiSecretsTablesDelete from './ApiSecretsTablesDelete';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const AvailableKeysTable = ({ keysDataList }) => {
  const [deletedToken, setDeletedToken] = React.useState('');

  const tableColumns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name', // unique for each column (ignore if dataIndex is unique)
      width: 240,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role', // unique for each column (ignore if dataIndex is unique)
      width: 240,
    },
    {
      title: 'API Secret / Token',
      dataIndex: 'token',
      key: 'token',
      ellipsis: true,
    },
    {
      title: 'Expired In',
      dataIndex: 'expire',
      key: 'expire',
      width: 250,
      render: (expire) => (
        <span>
          {expire ? dayjs?.duration(dayjs(expire).diff(new Date()))?.humanize(true) ?? '' : ''}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: 120,
      render: (text) => (
        <ApiSecretsTablesDelete
          text={text}
          deletedToken={deletedToken}
          setDeletedToken={setDeletedToken}
        />
      ),
    },
  ];

  return (
    <Table
      dataSource={keysDataList}
      columns={tableColumns}
      bordered
      rowKey="token"
      // style={{ maxWidth: '1380px' }}
      size="small"
    />
  );
};

export default AvailableKeysTable;

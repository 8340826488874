import { Input } from 'antd';
import GraphiQL from 'graphiql';

import { createGraphiQLFetcher, FetcherOpts, FetcherParams } from '@graphiql/toolkit';
import { explorerPlugin } from '@graphiql/plugin-explorer';

//import 'graphiql/graphiql.css';
import '@graphiql/plugin-explorer/dist/style.css';

type GraphqlExplorerWrapperProps = {
  url: string;
};
const fetcher = (url: string) => {
  return createGraphiQLFetcher({
    //url: 'https://swapi-graphql.netlify.app/.netlify/functions/index',
    url: url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Use-Cookies': 'true',
    },
    fetch: (url, args) => {
      return fetch(url, {
        ...args,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Use-Cookies': 'true',
        },
        credentials: 'include',
      });
    },
    schemaFetcher: (graphqlParam: FetcherParams, opts?: FetcherOpts): any => {
      try {
        return fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Use-Cookies': 'true',
          },
          credentials: 'include',
          body: JSON.stringify(graphqlParam),
        })
          .then(function (response) {
            return response.text();
          })
          .then(function (responseBody) {
            try {
              return JSON.parse(responseBody);
            } catch (e) {
              return responseBody;
            }
          });
      } catch (e) {
        console.log('gql fetch error', e);
      }
    },
  });
};

const GraphqlExplorerWrapper: React.FC<GraphqlExplorerWrapperProps> = ({ url }) => {
  // pass the explorer props here if you want
  const explorer = explorerPlugin({
    showAttribution: true,
    explorerIsOpen: true,
    title: 'System API',
  });

  return (
    <div>
      <div>
        <Input addonBefore="Graphql Endpoint" defaultValue={url || ''} readOnly />
      </div>
      <div style={{ height: 'calc(100vh - 150px)' }}>
        <GraphiQL
          //query={query}
          //onEditQuery={setQuery}
          visiblePlugin={explorer}
          fetcher={fetcher(url)}
          plugins={[explorer]}
        />
      </div>
      {/*<GraphqlExplorer url={url} />*/}
    </div>
  );
};

export default GraphqlExplorerWrapper;

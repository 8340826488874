import React, { useState } from 'react';

import { message, Table, TableColumnsType } from 'antd';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import Loader from '../../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useSwitchProject } from '../../../hooks/useProjectList';
import { client } from '../../../index';

dayjs.extend(relativeTime);
dayjs.extend(duration);

interface TableData {
  key: React.Key;
  name: string;
  email: string;
  created_at: string;
  project_limit: number;
}

function UsersProjectsTable({ tableData }) {
  // const secondaryToken = Cookies.get('secondaryToken')
  const [loading, setLoading] = useState(false);

  const tableColumns: TableColumnsType<TableData> = [
    {
      title: 'Name',
      dataIndex: ['first_name'],
      key: 'name', // unique for each column (ignore if dataIndex is unique)
    },
    {
      title: 'Email',
      dataIndex: ['email'],
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: ['role'],
      key: 'role',
    },
    {
      title: 'Organization',
      dataIndex: ['organizations', 0, 'name'],
      key: 'organization',
    },
  ];

  return (
    <div>
      {loading ? (
        <Loader spin tip="Switching Project..." />
      ) : (
        <Table
          dataSource={tableData}
          columns={tableColumns}
          pagination={{ position: ['topRight'] }}
          bordered
          size="small"
          //expandable={{ expandedRowRender }}
          rowKey={(record) => record?.key}
          // style={{ maxWidth: '980px' }}
        />
      )}
    </div>
  );
}

export default UsersProjectsTable;

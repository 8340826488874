import constate from 'constate';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpService } from 'utils/httpService';
import { LoginFormData } from 'components/forms/LoginForm';
import { useState } from 'react';
import { client } from 'index';
import { RegistrationFormData } from 'components/forms/RegistrationForm';
import { VarificationFormData } from 'components/forms/VarificationForm';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import { deleteAllCookies } from '../utils/lib';
import { ForgotPasswordFormData } from '../components/forms/ForgotPasswordForm';
import { ChangePasswordFormData } from '../components/forms/ChangePasswordForm';
import {
  EMAIL_VERIFY,
  FORGET_PASSWORD,
  LOGIN_URL,
  LOGOUT_URL,
  REGISTER_URL,
  VERIFY_PASSWORD,
} from '../configs';

type LocationState = {
  from: string;
};

const useAuth = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _] = useCookies(['userToken']);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const locState = location.state as LocationState;
  const navigateFrom = locState?.from || '/';

  const isAuthenticated = (): boolean => {
    return !(cookies.userToken === undefined || cookies.userToken === null);
  };

  const readJWTToken = (): string => {
    if (cookies.userToken === undefined || cookies.userToken === null) {
      return null;
    }
    return cookies?.userToken;
  };

  const decodeTokenData = (): any => {
    const token = readJWTToken();
    if (token === null) {
      return token;
    }
    return jwt_decode(token);
  };

  const handleSignupAPI = (data: RegistrationFormData) => {
    delete data.confirmSecret;
    setLoading(true);
    return httpService
      .post(REGISTER_URL, data)
      .then((res) => {
        window.localStorage.setItem('verifyEmail', data?.email);
        setLoading(false);
        navigate('/verify');
      })
      .catch((err) => {
        setLoading(false);
        if (Number(err.response.status) === 400) {
          return err.response.data.message;
        }
      });
  };

  const handleSigninAPI = (data: LoginFormData) => {
    setLoading(true);
    return httpService
      .post(LOGIN_URL, data)
      .then((res) => {
        if (Number(res.status) === 200) {
          setLoading(false);
          window.location.reload();
          // await navigate('/projects');
        }
      })
      .catch((err) => {
        setLoading(false);
        return err.response.data.message;
      });
  };

  const handleLogoutAPI = async () => {
    try {
      await httpService.post(LOGOUT_URL, {});
    } catch (error) {
      console.log(error);
    }
    await deleteAllCookies();
    await setLoading(false);
    await navigate('/login');
  };

  const handleEmailVerifyAPI = (data: VarificationFormData) => {
    setLoading(true);
    data.email = window.localStorage.getItem('verifyEmail');

    return httpService
      .post(EMAIL_VERIFY, data)
      .then((res) => {
        window.localStorage.removeItem('verifyEmail');
        //window.location.reload();
        setLoading(false);
        navigate('/login');
      })
      .catch((err) => {
        setLoading(false);
        if (Number(err.response.status) === 400) {
          return err.response.data.message;
        }
      });
  };

  const handleForgotPasswordRequestAPI = (data: ForgotPasswordFormData) => {
    setLoading(true);
    return httpService
      .post(FORGET_PASSWORD, data)
      .then((res) => {
        //window.location.reload();
        window.localStorage.setItem('passwordRecoveryEmail', data?.email);
        navigate('/change-password');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (Number(err.response.status) === 400) {
          return err.response.data.message;
        }
      });
  };

  const handleForgotPasswordConfirmationAPI = (data: ChangePasswordFormData) => {
    setLoading(true);
    return httpService
      .post(VERIFY_PASSWORD, data)
      .then((res) => {
        //window.location.reload();
        navigate('/login');
        setLoading(true);
      })
      .catch((err) => {
        setLoading(false);
        if (Number(err.response.status) === 400) {
          return err.response.data.message;
        }
      });
  };

  const handleLogout = async () => {
    await setLoading(true);
    await handleLogoutAPI();
    await client.resetStore();
    await navigate(navigateFrom);
  };

  return {
    loading,
    isAuthenticated,
    signIn: handleSigninAPI,
    signUp: handleSignupAPI,
    emailVerify: handleEmailVerifyAPI,
    passRecoveryRequest: handleForgotPasswordRequestAPI,
    passRecoveryConfirm: handleForgotPasswordConfirmationAPI,
    logout: handleLogout,
    navigateFrom,
    decodeTokenData,
  };
};

export const [AuthProvider, useAuthContext] = constate(useAuth);

import { createContext } from 'react';

// context for Setting-Menu (first-second column)
export const FunctionContext = createContext(null);

export function FunctionReducer(state, action) {
  switch (action.type) {
    case 'SET_FUNCTION':
      return { target: action.payload.target };
    default:
      throw new Error();
  }
}

import AuthLayout from 'components/layouts/AuthLayout';
import Logo from 'components/Logo';
import styles from './styles.module.scss';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';

type ForgotPasswordProps = {
  //
};

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  return (
    <AuthLayout>
      <div className={styles.rightColContentWrapper}>
        <div>
          <div className={styles.logo}>
            <Logo />
          </div>
          <h2>
            Create <span>API</span> instantly,
            <br /> Deliver <span>Projects</span> in time.
          </h2>
          <ForgotPasswordForm />
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;

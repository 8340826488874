import { useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { Alert, Button, Divider, Empty, Form, InputNumber, Layout } from 'antd';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LoadingBar from 'react-top-loading-bar';
import { flatten } from 'lodash/array';
// import { generateLabel } from '../../utility/LabelGenerator';
import { fakeDataList } from './fakeDataList';
// import { RepeatedFakerFieldsGenerator } from './RepeatedFakerFieldsGenerator';
import { useCreateModelDataMutation, useGetFormGenerationDataQuery } from 'generated/graphql';
import { CONNECT_DISCONNECT_QUERY, CONTENT_MEDIA_QUERY } from '../../../localQuery';
import { errorMessage, successMessage } from 'utils/message';
import Loader from 'components/loader/Loader';
import { capitalize } from 'lodash';
import { generateLabel } from 'utils/GenerateLabelAndIcon';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { RepeatedFakerFieldsGenerator } from './RepeatedFakerFieldsGenerator';

dayjs.extend(relativeTime);

const { Content } = Layout;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

const FakeContentForm = (props) => {
  const { contentData } = props;
  const client = useApolloClient();

  const { loading, error, data } = useGetFormGenerationDataQuery({
    variables: {
      model_name: `${contentData.model}`,
    },
  });

  // clear cache for create (relation)
  client.cache.writeQuery({
    query: CONNECT_DISCONNECT_QUERY,
    data: {
      connect_ids: {},
      disconnect_ids: {},
    },
  });

  // clear cache for create (mediaUpload)
  client.cache.writeQuery({
    query: CONTENT_MEDIA_QUERY,
    data: { media_data: {} },
  });

  if (loading) return <Loader />;
  if (error) {
    errorMessage(error);
    return <Empty />;
  }

  if (data) {
    const projectModelInfo = data?.projectModelsInfo;
    return <FakeContentFormContainer projectModelInfo={projectModelInfo} {...props} />;
  }
  return null;
};

function FakeContentFormContainer({ contentData, projectModelInfo, onContentCreated }) {
  const client = useApolloClient();
  const localsArr =
    projectModelInfo?.locals?.length > 0
      ? Array.from(new Set(['en', ...projectModelInfo?.locals]))
      : ['en']; // 'En' First Sorting
  const [localNavKey, setLocalNavKey] = useState(localsArr?.[0]);
  const [form] = Form.useForm();
  const [payload, setPayload] = useState({});
  const [mediaData, setMediaData] = useState({});
  const [connect, setConnect] = useState({});
  const [progress, setProgress] = useState(0);

  const [formErrors, setFormErrors] = useState([]);

  const getValidationRules = (item) => {
    const required = item?.validation?.required ?? false;
    return [
      {
        required,
        message: `${capitalize(item.identifier)} is required`,
      },
    ];
  };

  const renderForm = (modelInfo) =>
    modelInfo.fields.map((item, i) => {
      if (item?.field_type === 'multiline') {
        return (
          <Form.Item
            key={i}
            name={['form', `${item.identifier}`, 'html']}
            label={generateLabel(item)}
            rules={getValidationRules(item)}
            tooltip={item.description}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      if (item?.field_type === 'geo') {
        return (
          <Form.Item
            key={i}
            name={['form', `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      return (
        <Form.Item
          key={i}
          name={['form', `${item.identifier}`]}
          label={generateLabel(item)}
          tooltip={item.description}
          rules={getValidationRules(item)}
        >
          {getFormField(item)}
        </Form.Item>
      );
    });

  const getFormField = (field_item) => {
    const field_Type = field_item?.field_type;
    switch (field_Type) {
      case 'text':
        return <FixedListSelector list={fakeDataList.text} />;
      case 'multiline':
        return <FixedListSelector list={fakeDataList.multiline} />;
      case 'date':
        return <FixedListSelector list={fakeDataList.date} />;
      case 'number':
        return <FixedListSelector list={fakeDataList.number} />;
      case 'boolean':
        return <FixedListSelector list={fakeDataList.boolean} />;
      case 'media':
        return <FixedListSelector list={fakeDataList.media} />;
      case 'geo':
        return <FixedListSelector list={fakeDataList.geo} />;
      case 'repeated':
        return <RepeatedFakerFieldsGenerator field_item={field_item} />;
      case 'list':
        const list = field_item?.validation?.fixed_list_elements || [];
        // console.log('Log: getFormField -> list', list)
        const multiChoice = field_item?.validation?.is_multi_choice;

        if (list.length > 0 && !multiChoice) {
          return <FixedListSelector list={fakeDataList.random} />;
        }
        if (list.length > 0 && multiChoice) {
          return <FixedListSelector list={fakeDataList.random} />;
        }
        if (list.length === 0 && !multiChoice) {
          return <FixedListSelector list={fakeDataList.text} />;
        }
        break;
      default:
        break;
    }
  };

  // const renderConnections = (connectionArr) => {
  //   // console.log('Log: renderConnections -> connectionArr', connectionArr)
  //   if (connectionArr.length >= 0) {
  //     return connectionArr.map((connectionItem, index) =>
  //       getConnectionField(connectionItem, index)
  //     );
  //   }
  // };

  // const getConnectionField = (connectionItem, index) => (
  //   <CustomSelectForConnection
  //     connectionItem={connectionItem}
  //     fromModel={projectModelInfo.name}
  //     key={index}
  //   />
  // );

  const [createSingleModel] = useCreateModelDataMutation({
    onCompleted: (data) => {
      successMessage(`New ${projectModelInfo.name} created successfully!`);
      setProgress(0);
      onContentCreated(data?.upsertModelData); // pass the data
    },
    // update: updateCache
  });

  const onFinish = async ({ status, faker }) => {
    try {
      // console.log('Log: values', values)
      const { form: payload } = await form.validateFields();
      const connect_disconnect_data: { connect_ids: number } = client.cache.readQuery({
        query: CONNECT_DISCONNECT_QUERY,
      });
      const cache_media_data: { media_data: string } = client.cache.readQuery({
        query: CONTENT_MEDIA_QUERY,
      });

      const connect_ids = connect_disconnect_data?.connect_ids || {};
      const media_data = cache_media_data?.media_data || {};
      // const disconnect_ids = connect_disconnect_data.disconnect_ids || {}
      setPayload(payload);
      // console.log('Log: payload', payload)
      setConnect(connect_ids);
      setMediaData(media_data);
      // setDisconnect(disconnect_ids)
      if (projectModelInfo?.name) {
        setProgress(80);
        await createSingleModel({
          variables: {
            model_name: `${projectModelInfo?.name}`,
            status,
            faker,
            payload: { ...payload, ...mediaData },
            local: localNavKey,
            connect,
          },
        });
      }
      await setFormErrors([]);
      await form.resetFields();
    } catch (e) {
      const errors = flatten(form.getFieldsError().map((error) => error.errors));
      setFormErrors(errors);
    }
  };

  const onValuesChange = (values) => {
    console.log('Log: onValuesChange -> values', values);
  };

  if (contentData.model !== projectModelInfo?.name) return <Loader />;

  return (
    <div>
      <LoadingBar color="#f11946" progress={progress} />
      <Content key={contentData.model}>
        <Form
          {...layout}
          layout="vertical"
          form={form}
          name="content-form"
          className="apitoCreateFormHideValidation"
          // onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={{ remember: false }}
          onValuesChange={onValuesChange}
        >
          {formErrors.length > 0
            ? formErrors.map((e) => (
                <div style={{ paddingBottom: 5 }}>
                  <Alert message={e} type="error" showIcon />
                </div>
              ))
            : null}
          <Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '24px 0 0 0',
              }}
            >
              <Button
                onClick={() =>
                  onFinish({
                    status: 'draft',
                    faker: true,
                  })
                }
              >
                SAVE AS DRAFT
              </Button>
              <Divider type="vertical" />
              <Button
                type="primary"
                onClick={() =>
                  onFinish({
                    status: 'published',
                    faker: true,
                  })
                }
              >
                PUBLISH
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            />
          </Form.Item>
          <div>
            <Form.Item
              key="number_of_records"
              name={['form', `number_of_records`]}
              label="Number Of Records"
              rules={[
                {
                  required: true,
                  message: 'Number of Records Field is required',
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            {renderForm(projectModelInfo)}
          </div>
        </Form>
        {/* @ts-ignore */}
        <style jsx="true">
          {`
            .ant-form-item-explain {
              color: transparent !important;
            }
          `}
        </style>
      </Content>
    </div>
  );
}

export default FakeContentForm;

import { Layout, Menu, MenuProps } from 'antd';
import styles from './headers.module.scss';
import { Link, useLocation } from 'react-router-dom';
import LogoIcon from 'components/LogoIcon';
import {
  ApiOutlined,
  DatabaseOutlined,
  FileImageOutlined,
  FileOutlined,
  FunctionOutlined,
  HomeOutlined,
  LayoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import UserMenu from 'components/menu/UserMenu';
import { TokenData } from '../../dto/project-list.dto';
import { useAuthContext } from '../../hooks/useAuth';

type HeaderProps = {
  showNav?: boolean;
  title?: string;
  subtitle?: string;
};

const Header: React.FC<HeaderProps> = ({ showNav = true, title, subtitle }) => {
  const { Header } = Layout;
  const location = useLocation();

  const { decodeTokenData } = useAuthContext();
  const tokenData: TokenData = decodeTokenData();

  const allMenu = [
    {
      key: 'content',
      name: 'Content',
      icon: DatabaseOutlined,
      slug: '/console/contents',
      groupName: 'console',
    },
    {
      key: 'model',
      name: 'Model',
      icon: LayoutOutlined,
      slug: '/console/models',
      groupName: 'console',
    },
    {
      key: 'media',
      name: 'Media',
      icon: FileImageOutlined,
      slug: '/console/media',
      groupName: 'console',
    },
    {
      key: 'logic',
      name: 'Logic',
      icon: FunctionOutlined,
      slug: '/console/logic',
      groupName: 'console',
    },
    {
      key: 'api_explorer',
      name: 'API',
      icon: ApiOutlined,
      slug: '/console/api-explorer',
      groupName: 'console',
    },
    {
      key: 'settings',
      name: 'Settings',
      icon: SettingOutlined,
      slug: '/console/settings',
      groupName: 'console',
    },
  ];

  const items: MenuProps['items'] = [
    {
      label: 'Develop',
      key: '1',
    },
    {
      label: 'Stage',
      key: '2',
    },
    {
      label: 'Production',
      key: '3',
    },
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
  };

  return (
    <Header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.leftContent}>
          <div className={styles.logo}>
            <Link to="/">
              <LogoIcon />
            </Link>
            <div className={styles.layoutTitle}>
              <h6>{title || 'Apito'}</h6>
              {subtitle && <p>{subtitle}</p>}
            </div>
          </div>
          {showNav && (
            <Menu
              onClick={onClick}
              mode="horizontal"
              forceSubMenuRender={false}
              className={styles.menu}
              selectedKeys={[location.pathname]}
            >
              {allMenu
                .filter((m) => (tokenData?.project_access?.includes(m.key) ? true : false))
                .map(({ icon: Icon, ...m }) => (
                  <Menu.Item
                    className={styles.menuItem}
                    key={location.pathname.includes(m.slug) ? location.pathname : m.slug}
                    icon={<Icon className={styles.menuIcon} />}
                  >
                    <Link to={m.slug}>{m.name}</Link>
                  </Menu.Item>
                ))}

              {/* {projectData?.is_super_admin ? (
                <Menu.Item key="admin" icon={<UserOutlined />} style={{ bottom: 0 }}>
                  <Link to="/console/admin">Admin</Link>
                </Menu.Item>
              ) : null} */}
            </Menu>
          )}
        </div>
        <div className={styles.rightContent}>
          {/*          <Dropdown.Button
            icon={<DownOutlined />}
            loading={false}
            menu={{ items }}
            onClick={() => {}}
          >
            Workspaces
          </Dropdown.Button>*/}

          {showNav && (
            <Menu mode="horizontal" forceSubMenuRender={false} className={styles.menu}>
              <Menu.Item
                key="link1"
                className={styles.menuItem}
                icon={<FileOutlined style={{ fontSize: '18px' }} />}
              >
                <a href="https://apito.io/docs" target="_blank" rel="noreferrer">
                  Docs
                </a>
              </Menu.Item>
              <Menu.Item
                key="/projects"
                className={styles.menuItem}
                icon={<HomeOutlined style={{ fontSize: '18px' }} />}
              >
                <Link to="/projects">Project Home</Link>
              </Menu.Item>
            </Menu>
          )}

          <div className={styles.userInfo}>
            <p className={styles.userName}>Hi, {tokenData?.email.split('@')[0]}..</p>
            <UserMenu avatar={''} email={tokenData?.email} role={tokenData?.project_role} />
          </div>
        </div>
      </div>
    </Header>
  );
};

export default Header;

import React from 'react';
import Users from './Users';

const UsersContainer = () => {
  return (
    <div>
      <Users />
    </div>
  );
};

export default UsersContainer;

import { Radio } from 'antd';

export function BooleanSelector(props) {
  return (
    <Radio.Group {...props}>
      <Radio value>True</Radio>
      <Radio value={false}>False</Radio>
    </Radio.Group>
  );
}

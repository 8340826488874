import {
  GithubOutlined,
  GoogleOutlined,
  KeyOutlined,
  MailOutlined,
  ScanOutlined,
} from '@ant-design/icons';
import { Button, Divider, Input, Select, Tag } from 'antd';
import React, { useState } from 'react';
import styles from 'pages/Registration/styles.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuthContext } from 'hooks/useAuth';
import BackToButton from './BackToButton';
import { GITHUB_LOGIN_URL, GOOGLE_LOGIN_URL } from '../../configs';

const schema = yup
  .object({
    email: yup.string().required('Email is Required'),
    secret: yup
      .string()
      .required('Password is a required field.')
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
        'Minimum eight characters, at least one letter, one number and one special character for the password.'
      ),
    confirmSecret: yup
      .string()
      .required('Confirm Password is a required field.')
      .oneOf([yup.ref('secret')], 'Passwords must match'),
    profession: yup.string().required('Prefession is a required field.'),
    fullName: yup.string().required('Full Name is a required field.'),
  })
  .required();

export type RegistrationFormData = {
  fullName: string;
  email: string;
  secret: string;
  confirmSecret: string;
  profession: string;
};

export type RegistrationFormProps = {
  //
};

const RegistrationForm: React.FC<RegistrationFormProps> = () => {
  const { signUp, loading } = useAuthContext();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schema),
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegistration = async (data) => {
    const message = await signUp(data);
    if (message) {
      setErrorMessage(message);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleRegistration)}>
      {errorMessage && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errorMessage}
        </Tag>
      )}

      <>
        <Button
          icon={<GoogleOutlined />}
          type={'default'}
          size="large"
          onClick={() => {
            window.location.href = GOOGLE_LOGIN_URL;
          }}
        >
          Register with Google
        </Button>
        <Divider type={'vertical'}></Divider>
        <Button
          icon={<GithubOutlined />}
          type={'default'}
          size="large"
          onClick={() => {
            window.location.href = GITHUB_LOGIN_URL;
          }}
        >
          Register with Github
        </Button>
        <div style={{ height: '20px ' }}></div>
      </>

      {/*<Controller
        name="fullName"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Full Name"
            type="text"
            prefix={<ScanOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.fullName && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.fullName?.message}
        </Tag>
      )}
      <Controller
        name="profession"
        control={control}
        render={({ field }) => (
          <Select
            style={{ width: 120 }}
            showSearch
            className={styles.input}
            placeholder="Profession"
            {...field}
          >
            <Select.Option value="eng">Engineer</Select.Option>
            <Select.Option value="dev">Developer</Select.Option>
            <Select.Option value="founder">Founder</Select.Option>
            <Select.Option value="sales">Sales</Select.Option>
            <Select.Option value="marketing">Marketing</Select.Option>
            <Select.Option value="others">Others</Select.Option>
          </Select>
        )}
      />
      {errors?.profession && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.profession?.message}
        </Tag>
      )}
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Email"
            type="email"
            prefix={<MailOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.email && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.email?.message}
        </Tag>
      )}
      <Controller
        name="secret"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Password"
            type="password"
            prefix={<KeyOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.secret && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.secret?.message}
        </Tag>
      )}
      <Controller
        name="confirmSecret"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Confirm Password"
            type="password"
            prefix={<KeyOutlined />}
            className={styles.input}
          />
        )}
      />
      {errors?.confirmSecret && (
        <Tag color="red" style={{ marginBottom: '1rem' }}>
          {errors?.confirmSecret?.message}
        </Tag>
      )}

      <div className={styles.buttons}>
        <Button type="primary" size="large" htmlType="submit" loading={loading}>
          REGISTER
        </Button>
        <BackToButton label={'BACK TO LOGIN'} path={'/login'} />
      </div>*/}
      <div className={styles.buttons}>
        <BackToButton type={'primary'} label={'BACK TO LOGIN'} path={'/login'} />
      </div>
    </form>
  );
};

export default RegistrationForm;

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, message, Row, Space, Spin, Typography } from 'antd';
import {
  FieldInfo,
  SubFieldInfo,
  useDeleteFieldFromModelMutation,
  useUpdateFieldSerialMutation,
} from 'generated/graphql';
import { capitalize } from 'lodash';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import RelationContainer from './modelContainer/RelationContainer';
import styles from './models.module.scss';
//import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { gqlErrorMessage, successMessage } from 'utils/message';
import FieldContainer from './modelContainer/FieldContainer';
import { ConnectionSettingDrawerComponent } from './drawers/ConnectionSettingDrawerComponent';
import FieldOperationDrawerComponent from './drawers/FieldOperationDrawerComponent';
import FieldReconfigurationDrawerComponent from './drawers/FieldReconfigurationDrawerComponent';
import DeleteConfirmModal from '../../../components/modals/DeleteConfimModal';
import { DrawerParam, ModelsProps } from './interface/interface';
import { ContentContext } from '../../../context/ContentContext';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';
import { GET_FORM_GENERATION_DATA } from '../../../graphql/queries/contents';

const { Text, Paragraph } = Typography;

const Models: React.FC<ModelsProps> = ({
  modelInfo,
  setPopover,
  setRepeatedFieldIdentifier,
  repeatedFieldIdentifier,
}) => {
  const { state } = useContext(ContentContext);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { name, connections, fields } = modelInfo || {};
  const [typeOfOperation, setTypeOfOperation] = useState('');

  const [isSettingDrawer, setIsSettingDrawer] = useState(false);
  const [isConnectionSettingDrawer, setIsConnectionSettingDrawer] = useState(false);

  const [fieldInfo, setFieldInfo] = useState<FieldInfo>();
  const [connectionIndex, setConnectionIndex] = useState(null);
  const [connectionStringDrawerProp, setConnectionStringDrawerProp] = useState([]);
  const [isFieldOperationDrawer, setFieldOperationDrawer] = useState(false);

  const [mutableFields, setMutableFields] = useState([]);

  //const SortableItem = SortableElement(({ value }) => <div>{value}</div>);
  //const SortableList = SortableContainer(({ children }) => <div>{children}</div>);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);

  const openDrawerCallback = (data: DrawerParam) => {
    setRepeatedFieldIdentifier(data.objectIdentifier);
    setFieldInfo(data.field);
    setTypeOfOperation(data.type);
    switch (data.type) {
      case 'duplicate':
      case 'rename':
        setFieldOperationDrawer(true);
        break;
      case 'delete':
        setIsDeleteModalVisible(true);
        break;
      case 'configure':
        setIsSettingDrawer(true);
        break;
    }
  };

  const [deleteFieldFromModel] = useDeleteFieldFromModelMutation({
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
      setIsDeleteLoader(false);
    },
    onCompleted: () => {
      successMessage(`${fieldInfo.label} Field Deleted!`);
      setIsDeleteModalVisible(false);
      setIsDeleteLoader(false);
    },
  });

  const openConnectionDrawer = (index) => {
    setConnectionIndex(index);
    const propModel = connections[index]?.model ?? '';
    const connectionProp =
      connections.length > 0 ? connections.filter((item) => item.model === propModel) : [];
    setConnectionStringDrawerProp(connectionProp);
    setIsConnectionSettingDrawer(true);
  };

  const onSettingDrawerClose = () => {
    setRepeatedFieldIdentifier('');
    setIsSettingDrawer(false);
  };
  const onFieldOperationDrawerClose = () => {
    console.log('closing ....');
    setRepeatedFieldIdentifier('');
    setFieldOperationDrawer(false);
  };
  const onConnectionSettingDrawerClose = () => {
    setIsConnectionSettingDrawer(false);
  };

  const [rearrangeFieldOfModel, { loading }] = useUpdateFieldSerialMutation({
    onCompleted: (data) => {
      successMessage('Fields Rearranged Successfully');
    },
  });

  const SortableItem = ({ field, value }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: field,
    });

    const style: CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
        {value}
      </div>
    );
  };

  /*  const onDndEnd = async (event) => {
    const { active, over } = event;
    console.log('active', active);
    console.log('over', over);
  };*/

  const onSortEnd = async (event) => {
    const { active, over } = event;
    console.log('active', active);
    console.log('over', over);

    const field_name = active?.id?.identifier;
    const oldIndex = active?.id?.serial;
    const newIndex = over?.id?.serial;
    const parent_field = active?.id?.parent_field ?? '_root';
    const _type = active?.id?.__typename;

    console.log('got ', oldIndex, newIndex, parent_field, _type);

    switch (_type) {
      case 'SubFieldInfo':
        const newArray = mutableFields.slice().map((f) => {
          if (f.identifier === parent_field) {
            const some = arrayMoveImmutable(f.sub_field_info, oldIndex - 1, newIndex - 1);
            f = {
              ...f,
              sub_field_info: some,
            };
          }
          return f;
        });
        setMutableFields(newArray);
        break;
      case 'FieldInfo':
        setMutableFields(arrayMoveImmutable(mutableFields, oldIndex - 1, newIndex - 1));
        break;
      default:
        message.error('field type unknown');
        return;
    }
    await rearrangeFieldOfModel({
      variables: {
        model_name: name,
        field_name: field_name,
        parent_field: parent_field,
        new_serial: newIndex,
        old_serial: oldIndex,
      },
    });
  };

  const renderSingleStructure = (field, repeatIdentifier) => {
    let repeatedFieldIdentifier = repeatIdentifier || '_root';
    const item = [
      <FieldContainer
        field={field}
        openDrawer={openDrawerCallback}
        objectIdentifier={repeatedFieldIdentifier || ''}
      />,
    ];
    if (
      (field.field_type === 'repeated' || field.field_type === 'object') &&
      field?.sub_field_info?.length >= 0
    ) {
      repeatedFieldIdentifier = field?.identifier ?? '';
      item.push(
        <div style={{ marginLeft: 40 }}>
          <DndContext sensors={sensors} onDragEnd={onSortEnd}>
            <SortableContext items={field?.sub_field_info} strategy={rectSortingStrategy}>
              {field?.sub_field_info
                .slice()
                .sort((a: FieldInfo, b: FieldInfo) => a.serial < b.serial)
                .map((subField: SubFieldInfo, index: number) => {
                  let _subfield = {
                    ...subField,
                    parent_field: field?.identifier,
                  };
                  //subField.parent_field = field?.identifier;
                  return renderSingleStructure(_subfield, repeatedFieldIdentifier);
                })}
            </SortableContext>
          </DndContext>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            className={styles.addBtn}
            style={{ backgroundColor: '#9f1329', borderColor: '#9f1329' }}
            onClick={() => {
              setRepeatedFieldIdentifier(field?.identifier);
              // setSerial(mutableFields.length + 1);
            }}
          >
            {field?.field_type === 'object'
              ? `ADD ${field.label.toUpperCase()} FILED`
              : `ADD ${field.label.toUpperCase()} FIELD`}
          </Button>
        </div>
      );
      repeatedFieldIdentifier = '_root'; // reset the repeated root
    }
    if (field.system_generated) {
      return item;
    }
    return <SortableItem field={field} value={item} />;
  };

  const deleteField = async () => {
    setIsDeleteLoader(true);
    await deleteFieldFromModel({
      variables: {
        identifier: fieldInfo.identifier,
        model_name: state.target,
        repeated_group_identifier: repeatedFieldIdentifier || undefined,
        is_relation: false,
      },
      refetchQueries: [GET_FORM_GENERATION_DATA],
    });
  };

  useEffect(() => {
    setMutableFields(fields);
  }, [fields]);

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <div className={styles.modelContainer}>
      <Row gutter={[24, 24]}>
        <Col span={connections?.length > 0 ? 12 : 24} className={styles.structureContainer}>
          {mutableFields.length > 0 ? (
            <>
              <Space direction="vertical">
                <Text>
                  <strong>Structure</strong>
                </Text>
                <Text type="secondary">Long press on fields to drag and rearrange</Text>
              </Space>
              <Spin spinning={loading}>
                <DndContext sensors={sensors} onDragEnd={onSortEnd}>
                  <SortableContext items={mutableFields} strategy={rectSortingStrategy}>
                    {mutableFields
                      .slice()
                      // .sort((a, b) => (a.serial < b.serial ? 1 : -1))
                      .map((field, index) => {
                        return renderSingleStructure(field, repeatedFieldIdentifier);
                      })}
                  </SortableContext>
                </DndContext>
              </Spin>
            </>
          ) : (
            <Paragraph style={{ marginBottom: 20 }}>
              <blockquote>
                Your model looks empty! Please define your model with some fields. <br />
                Ex: name, title, description, media, list etc..
              </blockquote>
            </Paragraph>
          )}
        </Col>
        <Col span={12} className={styles.structureContainer}>
          {connections?.length > 0 ? (
            <Space direction="vertical">
              <Text>
                <strong>Relations</strong>
              </Text>
              <Text type="secondary">Relations between models</Text>
            </Space>
          ) : null}
          {connections.map((connection, i) => (
            <RelationContainer
              connection={connection}
              key={i}
              onOpneDrawer={openConnectionDrawer}
              index={i}
            />
          ))}
        </Col>
      </Row>
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        className={styles.addBtn}
        style={{ marginBottom: 32 }}
        onClick={() => {
          setRepeatedFieldIdentifier('');
          setPopover(true);
          setTimeout(() => {
            setPopover(false);
          }, 3500);
        }}
      >
        ADD A ROOT FIELD
      </Button>
      <Drawer // Structure Setting  - Drawer
        title={`Reconfigure -> ${fieldInfo?.label || ''}`}
        width={340}
        closable
        onClose={onSettingDrawerClose}
        visible={isSettingDrawer}
        destroyOnClose
        extra={<Button type="link" onClick={onSettingDrawerClose}>{`< Back`}</Button>}
      >
        <FieldReconfigurationDrawerComponent
          key={fieldInfo?.identifier}
          fieldData={fieldInfo}
          repeatedFieldIdentifier={repeatedFieldIdentifier}
          onSettingDrawerClose={onSettingDrawerClose}
        />
      </Drawer>

      <Drawer // Rename - Drawer
        title={`${capitalize(typeOfOperation)} Field -> ${fieldInfo?.label || ''}`}
        width={340}
        closable
        onClose={onFieldOperationDrawerClose}
        visible={isFieldOperationDrawer}
        extra={<Button type="link" onClick={onFieldOperationDrawerClose}>{`< Back`}</Button>}
      >
        <FieldOperationDrawerComponent
          key={fieldInfo?.identifier}
          type={typeOfOperation}
          fieldData={fieldInfo}
          onFieldOperationDrawerClose={onFieldOperationDrawerClose}
          objectIdentifier={repeatedFieldIdentifier}
        />
      </Drawer>

      <Drawer // Relation Reconfigure - Drawer
        title={`${capitalize(connections[connectionIndex]?.model ?? '')} Relation Reconfigure`}
        width={340}
        closable
        onClose={onConnectionSettingDrawerClose}
        visible={isConnectionSettingDrawer}
        extra={<Button type="link" onClick={onConnectionSettingDrawerClose}>{`< Back`}</Button>}
      >
        <ConnectionSettingDrawerComponent data={connectionStringDrawerProp} key={connectionIndex} />
      </Drawer>
      <DeleteConfirmModal
        loader={isDeleteLoader}
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        handleModelConfirm={deleteField}
      />
    </div>
  );
};

export default Models;

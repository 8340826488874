import { useCallback, useEffect, useState } from 'react';

import { Form, Button, Input, PageHeader as ContentHeader, Select, Typography } from 'antd';

import debounce from 'lodash/debounce';

import { TableSkeleton } from './TableSkeleton';
import { gqlErrorMessage } from '../../../utils/message';
import UsersProjectsTable from './UsersProjectsTable';
import { Option } from 'antd/es/mentions';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchUsersLazyQuery } from '../../../generated/graphql';

const { Title } = Typography;

function Users() {
  const [searchStr, setSearchStr] = useState('');

  const [searchUsers, { loading, data }] = useSearchUsersLazyQuery({
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
    },
    onCompleted: () => {
      // console.log('Log: data', data)
    },
  });

  let onSearch = (searchStr) => {
    searchUsers({
      variables: {
        first_name: searchStr,
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  onSearch = useCallback(debounce(onSearch, 800), []);

  useEffect(() => {
    if (!searchStr) {
      onSearch();
    } else if (searchStr) {
      onSearch(searchStr);
    }
  }, [onSearch, searchStr]);

  // @ts-ignore
  return (
    <ContentHeader admin>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 56,
          color: '#687074',
        }}
      >
        <Title
          level={5}
          style={{
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 24,
            color: '#687074',
          }}
        >
          Teams
        </Title>
        <div style={{ height: 36, width: '40vw' }}>
          <Input.Group compact>
            <Form.Item
              name={['address', 'province']}
              noStyle
              rules={[{ required: true, message: 'Province is required' }]}
            >
              <Select placeholder="Select Organization">
                <Option value="Zhejiang">Zhejiang</Option>
                <Option value="Jiangsu">Jiangsu</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name={['address', 'street']}
              noStyle
              rules={[{ required: true, message: 'Street is required' }]}
            >
              <Input
                //prefix={<SearchOutlined />}
                style={{ width: '50%' }}
                placeholder="Search Names"
              />
            </Form.Item>
            <Button type="primary" size="medium" style={{ width: 150 }} loading={loading}>
              SEARCH
            </Button>
          </Input.Group>
        </div>
      </div>

      <div className="mt-3">
        <TableSkeleton loading={loading}>
          <UsersProjectsTable tableData={data?.searchUsers || []} />
        </TableSkeleton>
      </div>
    </ContentHeader>
  );
}

export default Users;

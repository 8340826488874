import React from 'react';
import styles from './models.module.scss';

import { Button, Checkbox, Form, Input, PageHeader } from 'antd';

import { useCreateModelMutation } from 'generated/graphql';
import { successMessage } from 'utils/message';
import { ANY_FIXME } from 'types/common';
import { GET_ALL_MODELS } from '../../../graphql/queries/models';

function CreateModel() {
  const [payload, setPayload] = React.useState<ANY_FIXME>({});

  const updateModelCache = (cache, { data }) => {
    const newModel = data?.addModelToProject;

    cache.writeQuery({
      query: GET_ALL_MODELS,
      data: { projectModelsInfo: [...newModel] },
    });
  };

  const [createModel, { loading }] = useCreateModelMutation({
    variables: {
      ...payload,
    },
    onCompleted: () => {
      successMessage('Model Created Successfully!');
    },
    update: updateModelCache,
  });

  const onFinish = (values) => {
    setPayload(values);
    createModel();
  };

  return (
    <PageHeader title="Create A New Model">
      <Form
        name="content-form"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ remember: false }}
      >
        <Form.Item
          name="name"
          label="Model Name"
          extra="Model Name Represents a Single Entity of Your Project"
          rules={[{ required: true }]}
        >
          <Input placeholder="Ex: Post, Content, Book, Product, Author etc.." />
        </Form.Item>

        <Form.Item
          className={styles.checkbox}
          name="single_record"
          valuePropName="checked"
          extra={
            <span>
              Check this if this model will have a single record instead of a collection of records.
              Checkout the{' '}
              <a
                href="https://docs.apito.io/model/choice-between-models"
                target="_blank"
                rel="noreferrer"
              >
                Doc
              </a>{' '}
              for the difference
            </span>
          }
        >
          <Checkbox>Single Record</Checkbox>
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              margin: '24px 0',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: '15px' }}
              loading={loading}
            >
              CREATE MODEL
            </Button>
            <Button>
              <a
                href="https://apito.io/docs/model/choice-between-models"
                target="_blank"
                rel="noreferrer"
              >
                SEE THE DOCUMENTATION
              </a>
            </Button>
          </div>
        </Form.Item>
      </Form>
    </PageHeader>
  );
}

export default CreateModel;

import { useState } from 'react';
import { Button, Form, Input } from 'antd';

import { GetSettingsGeneralQuery } from 'generated/graphql';

type GeneralFormProps = {
  data: GetSettingsGeneralQuery;
  onFinish: (value: Record<string, never>) => void;
  submissionLoading: boolean;
};

const GeneralForm: React.FC<GeneralFormProps> = ({
  data: { currentProject },
  onFinish,
  submissionLoading,
}) => {
  const [payload, setPayload] = useState({});

  const onValuesChange = (values) => {
    setPayload({ ...payload, ...values });
  };

  return (
    <Form
      name="general-setting"
      layout="vertical"
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Form.Item
        name="name"
        label="Project Name"
        initialValue={currentProject?.name}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="id" label="Project ID" initialValue={currentProject?.id}>
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        name="description"
        label="Project Description"
        initialValue={currentProject?.description}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={submissionLoading}>
        SAVE
      </Button>
      ,
    </Form>
  );
};

export default GeneralForm;

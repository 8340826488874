import { Button, Drawer, Image, List } from 'antd';
import { useContext, useEffect, useState } from 'react';

import { ContentContext } from 'context/ContentContext';

import { useApolloClient } from '@apollo/client';

import { DeleteOutlined, SelectOutlined } from '@ant-design/icons';
import { DynamicMediaURLFormList } from 'components/groupedInput/DynamicMediaURLFormList';
import { useGetAllPhotosLazyQuery } from 'generated/graphql';
import { CONTENT_MEDIA_QUERY } from 'localQuery';
import { gqlErrorMessage } from 'utils/message';
import MediaContentTable from '../../pages/Console/Media/MediaContentTable';
import { MediaTableDataProps } from '../../pages/Console/Media/interface';

export function ImageUploaderModal(props) {
  const client = useApolloClient();

  const { state } = useContext(ContentContext);
  // console.log('Log: ImageUploaderModal -> props', props.value)
  const { field_item, value: propValue } = props;
  const { identifier, validation } = field_item;
  const is_gallery = validation?.is_gallery ?? false;
  const is_url = validation?.is_url ?? false;

  // for modal
  const [showDrawer, setShowDrawer] = useState(false);
  const [modalMediaData, setModalMediaData] = useState([]);
  const [isFetchModalData, setIsFetchModalData] = useState(true);

  const limit = 5;
  const page = 1;
  const searchStr = '';

  // formList
  const [photos, setPhotos] = useState<MediaTableDataProps[]>([]);
  const [isListDataProp, setIsListDataProp] = useState(true);
  // for modal
  const [getAllPhotos] = useGetAllPhotosLazyQuery({
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
      setIsFetchModalData(false);
    },
    onCompleted: (data) => {
      setModalMediaData(data?.getPhotos?.results);
      setIsFetchModalData(false);
      // setModalMediaData([...modalMediaData, ...data?.listMediaData])
    },
  });

  // set the initial phto if any
  useEffect(() => {
    if (showDrawer && (!modalMediaData.length || isFetchModalData)) {
      getAllPhotos({
        variables: {
          limit,
          page,
          search: searchStr,
          models: `${state.target}`,
        },
      });
    }

    if (propValue?.length > 0 && is_gallery && isListDataProp) {
      const listdata = propValue;
      setPhotos(listdata);
      setIsListDataProp(false);
    } else if (propValue && !is_gallery && isListDataProp) {
      const listdata = [propValue];
      setPhotos(listdata);
      setIsListDataProp(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDrawer, propValue, page, searchStr]);

  const showModal = () => {
    setShowDrawer(true);
  };

  const handleCancel = async () => {
    setShowDrawer(false);
  };

  function ListAction({ listItem }) {
    return (
      <div>
        <DeleteOutlined
          onClick={() => {
            onListItemRemove(listItem);
          }}
        />
      </div>
    );
  }

  const onListItemRemove = (listItem) => {
    const newListData = photos.filter((d) => d.id !== listItem.id);
    if (is_gallery) {
      updateCache(newListData);
      setPhotos(newListData);
      if (newListData.length === 0) {
        // if gallery length is 0 then assign '' value which will result in removing the key on backend
        props.onChange({});
      } else {
        props.onChange({ newListData });
      }
    } else {
      updateCache(''); // it will replace singleMediaData from backend
      setPhotos([]);
      props.onChange({});
    }
  };

  const handleMediaLink = (mediaItems: MediaTableDataProps[]) => {
    setPhotos(mediaItems);
    // updateCache(newData);
    if (is_gallery) {
      props.onChange(mediaItems);
    } else {
      props.onChange(mediaItems[0]);
    }

    setShowDrawer(false);
  };

  const updateCache = (mediaData) => {
    const existing_media_data: { media_data: Record<string, never> } = client.cache.readQuery({
      query: CONTENT_MEDIA_QUERY,
    });

    client.cache.writeQuery({
      query: CONTENT_MEDIA_QUERY,
      data: {
        ...existing_media_data,
        media_data: {
          ...existing_media_data.media_data,
          [`${identifier}`]: mediaData,
        },
      },
    });
  };

  return (
    <div>
      {is_url ? <DynamicMediaURLFormList {...props} /> : null}
      <List
        itemLayout="horizontal"
        dataSource={photos}
        renderItem={(photo) => {
          console.log('inside', is_gallery, photo);
          return (
            <List.Item actions={[<ListAction listItem={photo} />]}>
              <div style={{ maxWidth: '250px' }}>
                <Image src={photo.url} />
              </div>
              <div
                style={{
                  marginRight: 'auto',
                  marginLeft: '30px',
                }}
              >
                <span>{photo.file_name}</span>
              </div>
            </List.Item>
          );
        }}
      />
      {!is_url ? (
        <Button
          type="primary"
          icon={<SelectOutlined />}
          style={{
            padding: '5px 8px',
            margin: '8px 0',
          }}
          onClick={showModal}
        >
          Select Media
        </Button>
      ) : null}

      <Drawer
        title="Select Media"
        placement="right"
        width="70%"
        closable
        onClose={handleCancel}
        visible={showDrawer}
        extra={<Button type="link" onClick={handleCancel}>{`< Back`}</Button>}
      >
        <div
          id="scrollableDiv"
          style={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <MediaContentTable
            projectDetails={{}} //send empty because activated plugin option is hidden on media drawer
            onImageSelectCallback={handleMediaLink}
            isGallery={is_gallery}
            selectMode={true}
            colSpan={6}
          />
        </div>
      </Drawer>
    </div>
  );
}

import React, { useState } from 'react';

import { message, Table, TableColumnsType } from 'antd';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import Loader from '../../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useSwitchProject } from '../../../hooks/useProjectList';
import { client } from '../../../index';

dayjs.extend(relativeTime);
dayjs.extend(duration);

interface TableData {
  key: React.Key;
  name: string;
  email: string;
  created_at: string;
  project_limit: number;
}

interface NestedTableData {
  key: React.Key;
  id: string;
  name: string;
  description: string;
  created_at: string;
  options: string;
}

function AuditLogsTable({ tableData }) {
  // const secondaryToken = Cookies.get('secondaryToken')
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const switchProject = useSwitchProject();

  // const [enterProject, { loading }] = useLazyQuery(ENTER_PROJECT, {
  //     onError: ({ graphQLErrors, networkError }) => {
  //         gqlErrorMessage(graphQLErrors, networkError)
  //     },
  //     onCompleted: (data) => {
  //         // const token = data?.enterProject?.token ?? ''
  //         // Cookies.set("userToken", token);
  //         Router.push('/console/content');
  //     }
  // })

  const onEnterProject = async (id: string) => {
    message
      .loading('Entering Into Project -> ' + id, 0.5)
      .then(async () => {
        await client.resetStore();
        setLoading(true);
        await switchProject(id);
        setLoading(false);
      })
      .then(async () => {
        navigate('/console');
        await message.success('Switched to Project ' + id, 1.5);
      });
    //await router.push('/console/content');
    //await router.reload();
  };

  // const menu = (rowData) => (
  //     <Menu>
  //         {/* <Menu.Item key="1" icon={<ArrowRightOutlined />} onClick={() => { enterProject(projectID) }}>Enter Project</Menu.Item> */}
  //         <Menu.Item
  //             key="2"
  //             icon={<ArrowRightOutlined />}
  //             onClick={() => {
  //                 onEnterProject(rowData.id);
  //             }}
  //         >
  //             Enter Project
  //         </Menu.Item>
  //     </Menu>
  // );

  /*
  const expandedRowRender = (record) => {
    const columns: TableColumnsType<NestedTableData> = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id', // unique for eatch column (ignore if dataIndex is unique)
      },
      {
        title: 'Name',
        dataIndex: 'project_name',
        key: 'project_name',
        ellipsis: true,
      },
      {
        title: 'Description',
        dataIndex: 'project_description',
        key: 'description',
        ellipsis: true,
      },
      {
        title: 'Register At',
        dataIndex: 'register_at',
        key: 'register_at',
        width: 180,
        render: (created_at: string) =>
          created_at
            ? dayjs?.duration(dayjs(created_at).diff(new Date()))?.humanize(true) ?? ''
            : '',
      },
      {
        title: 'Options',
        key: 'option',
        align: 'center',
        width: 120,
        render: (rowData) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/!* {console.log('Log: rowData', rowData)} *!/}
            <Space>
              <Tooltip title="Enter Project">
                <SelectOutlined
                  onClick={() => {
                    onEnterProject(rowData.id);
                  }}
                />
              </Tooltip>
            </Space>
          </div>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record?.projects || []}
        pagination={false}
        rowKey={(data) => data?.id}
      />
    );
  };
*/

  const tableColumns: TableColumnsType<TableData> = [
    {
      title: 'Activity',
      dataIndex: ['activity'],
      key: 'activity', // unique for each column (ignore if dataIndex is unique)
    },
    {
      title: 'Response Code',
      dataIndex: ['response_code'],
      key: 'response_code', // unique for each column (ignore if dataIndex is unique)
    },
    {
      title: 'Function Call',
      dataIndex: ['internal_function'],
      key: 'internal_function', // unique for each column (ignore if dataIndex is unique)
    },
    {
      title: 'Project ID',
      dataIndex: ['project_id'],
      key: 'project_id', // unique for each column (ignore if dataIndex is unique)
    },
    {
      title: 'GraphQL Operation',
      dataIndex: ['graphql_operation_name'],
      key: 'graphql_operation_name',
    },
    {
      title: 'Request Path',
      dataIndex: ['request_path'],
      key: 'request_path', // unique for each column (ignore if dataIndex is unique)
    },
    {
      title: 'User',
      dataIndex: ['user', 'first_name'],
      key: 'user_first_name',
    },
    {
      title: 'Project',
      dataIndex: ['project', 'project_name'],
      key: 'project_name',
    },
  ];

  return (
    <div>
      {loading ? (
        <Loader spin tip="Switching Project..." />
      ) : (
        <Table
          dataSource={tableData}
          columns={tableColumns}
          pagination={{ position: ['topRight'] }}
          bordered
          size="small"
          //expandable={{ expandedRowRender }}
          rowKey={(record) => record?.key}
          // style={{ maxWidth: '980px' }}
        />
      )}
    </div>
  );
}

export default AuditLogsTable;

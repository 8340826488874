export const DEMO_PROJECTS = [
  {
    key: 0,
    id: '',
    name: '',
    title: 'Empty Project',
    description: 'Project without any tempalte or code',
  },
  {
    key: 1,
    id: 'todo_note_p2a46',
    name: 'todo',
    title: 'Todo Note',
    description: 'App for Organizing Todo Notes',
  },
  {
    key: 2,
    id: 'quantum_ecommerce_gp4wj',
    name: 'ecommerce',
    title: 'E-Commerce Shop',
    description: 'An Online Store for Selling Stuff',
  },
  {
    key: 3,
    id: 'blog_starter_3m81x',
    name: 'blog',
    title: 'Blog Starter',
    description: 'Personal Blog Starter Template',
  },
  {
    key: 4,
    id: 'jira_clone_o5t3r',
    name: 'project',
    title: 'Project Management',
    description: 'Software for Project Management | Jira Clone',
  },
  {
    key: 4,
    id: 'restaurant_finder_dqlc7',
    name: 'restaurant',
    title: 'Restaurant Finder',
    description: 'Nearby Restaurants finder app based on Current Geo Location',
  },
];

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Empty, PageHeader, Row } from 'antd';
import DemoProjectCard from 'components/cards/DemoProjectCard';
import ProjectCard from 'components/cards/ProjectCard';
import MainLayout from 'components/layouts/MainLayout';
import CustomLoading from 'components/sidebars/CustomLoading';
import { useProjectList } from 'hooks/useProjectList';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DEMO_PROJECT_DATA from './demoProjectData';
import PostUpdateFormContainer from './PostUpdateFormContainer';

import styles from './projectList.module.scss';
import { useAuthContext } from '../../../hooks/useAuth';

type ProjectListProps = {
  //
};

const ProjectList: React.FC<ProjectListProps> = () => {
  const { decodeTokenData } = useAuthContext();
  const tokenData = decodeTokenData();

  const { data, isLoading } = useProjectList();
  const navigate = useNavigate();
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState('');

  const handleActiveProject = (id) => {
    setActiveProjectId(id);
    setSettingsDrawerOpen(true);
  };

  return (
    <MainLayout
      title="Projects"
      subtitle="carpe diem!"
      noPageHeader
      extra={
        <div>
          <a target="_" href="https://docs.apito.io">
            Apito Documentation
          </a>
          {data?.body?.length > 0 ? (
            <>
              <Divider type="vertical" />
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                className={styles.addBtn}
                onClick={() => {
                  navigate('/projects/start');
                }}
              >
                START ANOTHER PROJECT
              </Button>
            </>
          ) : null}
        </div>
      }
    >
      {/* {isSwitchingProject && <CustomLoading />} */}
      {isLoading ? (
        <CustomLoading />
      ) : (
        <Row gutter={[16, 16]} className={styles.projectCardListWrapper}>
          {data?.body?.length > 0 ? (
            data?.body?.map((project) => (
              <Col span={6} key={project?.id}>
                <ProjectCard
                  project={project}
                  tokenData={tokenData}
                  onActiveProject={handleActiveProject}
                />
              </Col>
            ))
          ) : (
            <Col span={22}>
              <Empty
                imageStyle={{
                  height: 80,
                }}
                description={
                  <span>
                    You haven't started any projects yet. Click{' '}
                    <a href="https://apito.io/docs/build-api-quick-start-guide">here</a> to get
                    started
                  </span>
                }
              >
                <Button
                  type="primary"
                  onClick={() => {
                    navigate('/projects/start');
                  }}
                >
                  Start Your First Project
                </Button>
              </Empty>
            </Col>
          )}
        </Row>
      )}
      <PageHeader
        title="Demo Projects"
        subTitle="Explore Sample Projects Instantly"
        className={styles.demoPageHeader}
      >
        <Row gutter={[32, 32]}>
          {DEMO_PROJECT_DATA.map((project, index) => (
            <Col key={index}>
              <DemoProjectCard project={project} />
            </Col>
          ))}
        </Row>
      </PageHeader>
      <Drawer
        title="Update project settings"
        placement="right"
        width="600px"
        closable
        onClose={() => setSettingsDrawerOpen(false)}
        visible={settingsDrawerOpen}
        extra={<Button type="link" onClick={() => setSettingsDrawerOpen(false)}>{`< Back`}</Button>}
      >
        {settingsDrawerOpen && (
          <PostUpdateFormContainer
            activeProjectId={activeProjectId}
            setSettingsDrawerOpen={setSettingsDrawerOpen}
          />
        )}
      </Drawer>
    </MainLayout>
  );
};

export default ProjectList;

const DEMO_PROJECT_DATA = [
  /*        {
                    title: 'Quantum Ecommerce',
                    description: 'A Full Featured Ecommerce build with Next.js and Apito GraphQL Server',
                    link: 'https://github.com/apito-io/next-ecommerce',
                    coverImg:
                        'https://cdn.apito.io/media/apito_documentation/005TJJR42K_Ecommerce.jpg',
                    id: 'quantum_ecommerce_ddlj4',
                }, */
  {
    title: 'Jira Clone',
    description: 'A Jira clone built with Vuejs & Nodejs/Graphql',
    link: 'https://github.com/TariqueNasrullah/jira_clone',
    coverImg: 'https://i.ibb.co/bvFPbwk/Screenshot-2020-03-24-Jira-Clone.png',
    id: 'jira_clone_o5t3r',
  },
  {
    title: 'Apito Website',
    description: 'Apito Website is Powered by Apito GraphQL Itself',
    link: 'https://github.com/sh0umik/apito-website',
    coverImg: 'https://cdn.apito.io/media/apito_documentation/7NYY6CH011_apito_io_website.png',
    id: 'apito_website',
  },
];

export default DEMO_PROJECT_DATA;

import React, { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={131}
      height={35}
      //viewBox="0 0 175 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.9758 20.0239H51.6878L50.6798 22.9999H46.3838L52.4798 6.15188H57.2318L63.3278 22.9999H58.9838L57.9758 20.0239ZM56.9198 16.8559L54.8318 10.6879L52.7678 16.8559H56.9198Z"
        fill="#263238"
      />
      <path
        d="M69.2871 11.5039C69.6871 10.8799 70.2391 10.3759 70.9431 9.99188C71.6471 9.60788 72.4711 9.41588 73.4151 9.41588C74.5191 9.41588 75.5191 9.69588 76.4151 10.2559C77.3111 10.8159 78.0151 11.6159 78.5271 12.6559C79.0551 13.6959 79.3191 14.9039 79.3191 16.2799C79.3191 17.6559 79.0551 18.8719 78.5271 19.9279C78.0151 20.9679 77.3111 21.7759 76.4151 22.3519C75.5191 22.9119 74.5191 23.1919 73.4151 23.1919C72.4871 23.1919 71.6631 22.9999 70.9431 22.6159C70.2391 22.2319 69.6871 21.7359 69.2871 21.1279V29.3839H65.1831V9.60788H69.2871V11.5039ZM75.1431 16.2799C75.1431 15.2559 74.8551 14.4559 74.2791 13.8799C73.7191 13.2879 73.0231 12.9919 72.1911 12.9919C71.3751 12.9919 70.6791 13.2879 70.1031 13.8799C69.5431 14.4719 69.2631 15.2799 69.2631 16.3039C69.2631 17.3279 69.5431 18.1359 70.1031 18.7279C70.6791 19.3199 71.3751 19.6159 72.1911 19.6159C73.0071 19.6159 73.7031 19.3199 74.2791 18.7279C74.8551 18.1199 75.1431 17.3039 75.1431 16.2799Z"
        fill="#263238"
      />
      <path
        d="M83.5362 8.21588C82.8162 8.21588 82.2242 8.00788 81.7602 7.59188C81.3122 7.15988 81.0882 6.63188 81.0882 6.00788C81.0882 5.36788 81.3122 4.83988 81.7602 4.42388C82.2242 3.99188 82.8162 3.77588 83.5362 3.77588C84.2402 3.77588 84.8162 3.99188 85.2642 4.42388C85.7282 4.83988 85.9602 5.36788 85.9602 6.00788C85.9602 6.63188 85.7282 7.15988 85.2642 7.59188C84.8162 8.00788 84.2402 8.21588 83.5362 8.21588ZM85.5762 9.60788V22.9999H81.4722V9.60788H85.5762Z"
        fill="#263238"
      />
      <path
        d="M96.0143 19.5199V22.9999H93.9263C92.4383 22.9999 91.2783 22.6399 90.4463 21.9199C89.6143 21.1839 89.1983 19.9919 89.1983 18.3439V13.0159H87.5663V9.60788H89.1983V6.34388H93.3023V9.60788H95.9903V13.0159H93.3023V18.3919C93.3023 18.7919 93.3983 19.0799 93.5903 19.2559C93.7823 19.4319 94.1023 19.5199 94.5503 19.5199H96.0143Z"
        fill="#263238"
      />
      <path
        d="M104.396 23.1919C103.084 23.1919 101.9 22.9119 100.844 22.3519C99.8043 21.7919 98.9803 20.9919 98.3723 19.9519C97.7803 18.9119 97.4843 17.6959 97.4843 16.3039C97.4843 14.9279 97.7883 13.7199 98.3963 12.6799C99.0043 11.6239 99.8363 10.8159 100.892 10.2559C101.948 9.69588 103.132 9.41588 104.444 9.41588C105.756 9.41588 106.94 9.69588 107.996 10.2559C109.052 10.8159 109.884 11.6239 110.492 12.6799C111.1 13.7199 111.404 14.9279 111.404 16.3039C111.404 17.6799 111.092 18.8959 110.468 19.9519C109.86 20.9919 109.02 21.7919 107.948 22.3519C106.892 22.9119 105.708 23.1919 104.396 23.1919ZM104.396 19.6399C105.18 19.6399 105.844 19.3519 106.388 18.7759C106.948 18.1999 107.228 17.3759 107.228 16.3039C107.228 15.2319 106.956 14.4079 106.412 13.8319C105.884 13.2559 105.228 12.9679 104.444 12.9679C103.644 12.9679 102.98 13.2559 102.452 13.8319C101.924 14.3919 101.66 15.2159 101.66 16.3039C101.66 17.3759 101.916 18.1999 102.428 18.7759C102.956 19.3519 103.612 19.6399 104.396 19.6399Z"
        fill="#263238"
      />
      <path
        d="M0 7.77512C0 3.48104 3.48104 0 7.77512 0H15.9091C20.2032 0 23.6842 3.48104 23.6842 7.77512V15.9091C23.6842 20.2032 20.2032 23.6842 15.9091 23.6842H7.77512C3.48104 23.6842 0 20.2032 0 15.9091V7.77512Z"
        fill="#EA3A60"
      />
      <path
        d="M11.0527 11.0532H20.5264C25.7586 11.0532 30.0001 15.2947 30.0001 20.5269C30.0001 25.7591 25.7586 30.0006 20.5264 30.0006C15.2942 30.0006 11.0527 25.7591 11.0527 20.5269V11.0532Z"
        fill="#263238"
      />
    </svg>
  );
};

export default Logo;

import { gql } from '@apollo/client';

export const CONTENT_MEDIA_QUERY = gql`
  query getMediaData {
    media_data @client
  }
`;

// local Cache Queries
export const CONNECT_DISCONNECT_QUERY = gql`
  query getConnectDisconnect($_id: String!) {
    connect_ids @client
    disconnect_ids @client
  }
`;

import { message } from 'antd';
import { ServerError } from '@apollo/client';
import { deleteAllCookies } from './lib';
import axios from 'axios';

const logout = (errMsg) => {
  deleteAllCookies();
  window.location.href = '/login';
};

const logoutWithMsg = async (errMsg) => {
  await deleteAllCookies();
  // Define a delay in milliseconds (e.g., 2000 milliseconds = 2 seconds)
  const delay = 1000;
  // Wait for the specified delay before redirecting
  setTimeout(() => {
    if (!window.location.href.includes('/login')) {
      window.location.href =
        '/login?message=Another person used your credential to login. Please use your own account to login.';
    }
  }, delay);
};

export const successMessage = (msg) => {
  if (msg.isLocal && msg.local !== 'en') {
    message.success(`Contents Updated Successfully`);
  } else if (!msg.isLocal) {
    message.success(msg);
  } else {
    message.success('Data Updated Successfully.');
  }
};

export const errorMessage = (err) => {
  if (typeof err === 'string') {
    message.error(err);
  } else if (axios.isAxiosError(err)) {
    // Handle AxiosError specific logic here, e.g., err.response.data.message
    const errorMsg = err.response?.data?.message || err.message;
    message.error(errorMsg);
  } else {
    message.error(err?.message || 'An unknown error occurred');
  }
};

export const gqlErrorMessage = (graphQLErrors, networkError) => {
  if (graphQLErrors) {
    console.log('g error', graphQLErrors);
    graphQLErrors.map(({ message, locations, path }) => {
      errorMessage(message);
      return '';
    });
  }
  if (networkError) {
    console.log('n error', networkError as ServerError);
    if (
      (networkError as ServerError)?.statusCode === 401 ||
      (networkError as ServerError)?.statusCode === 403
    ) {
      logout(networkError);
    } else if ((networkError as ServerError)?.statusCode === 409) {
      logoutWithMsg(networkError);
    } else {
      errorMessage((networkError as ServerError)?.result);
    }
    // if (errMsg.includes('401') || errMsg.includes('403')) {
    //   logout(errMsg);
    // }
  }
};

import { useState } from 'react';
import { Button, Form, Select, Typography } from 'antd';
import localsData from 'data/setting/locals.json';

import { AddOnsDetails, useUpdateSettingsAddonsMutation } from 'generated/graphql';
import { successMessage } from 'utils/message';

const { Text } = Typography;
const { Option } = Select;

const LanguageSetting = ({ data }: { data: AddOnsDetails }) => {
  const locals = data?.locals || [];
  const [payload, setPayload] = useState({});
  const localsEntries = Object.entries(localsData);

  const [updateCurrentProject, { loading }] = useUpdateSettingsAddonsMutation({
    onCompleted: (data) => {
      successMessage('Settings Saved Successfully');
    },
  });

  const onFinish = (values) => {
    if (Object.keys(payload)?.length > 0) {
      updateCurrentProject({
        variables: {
          addons: {
            ...payload,
          },
        },
      });
    }
  };

  const onValuesChange = (values) => {
    setPayload({ ...values });
  };

  return (
    <div>
      <Form
        name="local-setting"
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <Form.Item label="Locals">
          <Text type="secondary">Select language for localization</Text>
          <Form.Item
            name="locals"
            initialValue={locals}
            style={{ marginTop: '5px', marginBottom: 5 }}
          >
            <Select
              showSearch
              mode="multiple"
              placeholder="Select Local"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {localsEntries.length > 0
                ? localsEntries.map((d, i) => (
                    <Option key={i} label={d[0]} value={d[0]}>
                      {d[1]}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item style={{ position: 'absolute', bottom: 0, width: '88%' }}>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ display: 'block', margin: '0 auto' }}
          >
            SAVE SETTINGS
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LanguageSetting;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Divider,
  Empty,
  Form,
  Image,
  Input,
  Menu,
  MenuProps,
  message,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { gqlErrorMessage, successMessage } from 'utils/message';
import {
  FileDetails,
  ProjectModel,
  useDeleteMediaFileMutation,
  useGetAllPhotosLazyQuery,
  useUploadImageFromUrlMutation,
} from 'generated/graphql';
import { debounce } from 'lodash';
import MainLayout from 'components/layouts/MainLayout';
import styles from './media.module.scss';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment/moment';
import PrettyBytes from 'react-pretty-bytes';
import { LinkOutlined, UploadOutlined } from '@ant-design/icons';
import { MediaTableDataProps } from './interface';
import { useNavigate } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import { MEDIA_UPLOAD_URL } from '../../../configs';
const { Text } = Typography;

const { Search } = Input;
type MediaContentTableProps = {
  projectDetails: ProjectModel;
  filterByModelOpt?: string;
  onImageSelectCallback?: (data: MediaTableDataProps[]) => void;
  selectMode?: boolean;
  isGallery?: boolean;
  colSpan?: number;
};

const MediaContentTable = ({
  projectDetails,
  filterByModelOpt,
  onImageSelectCallback,
  isGallery,
  colSpan = 3,
  selectMode = false,
}: MediaContentTableProps) => {
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [searchStr, setSearchStr] = useState('');
  const [photos, setPhotos] = useState<MediaTableDataProps[]>([]);

  const [indexesForDelete, setIndexesForDelete] = useState('');
  // const [lightBoxObj, setLightBoxObj] = useState({ open: false, img: '' });

  // const [isDeleting, setIsDeleting] = useState(false);

  const [selectedImages, setSelectedImages] = useState<MediaTableDataProps[]>([]);

  const [form] = Form.useForm();

  const { default_storage_plugin, default_function_plugin } = projectDetails;

  const navigate = useNavigate();

  const [fetchMore, { loading, data }] = useGetAllPhotosLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
    },
    onCompleted: (data) => {
      console.log('data', data);
      successMessage(`${data?.getPhotos.count} photos found`);
      const _new = data?.getPhotos?.results;
      setPhotos(mapData(_new));
      setTotalCount(data?.getPhotos?.count);
    },
  });

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], photos: MediaTableDataProps[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', photos);
      setSelectedImages(photos);
    },
    getCheckboxProps: (record: MediaTableDataProps) => ({
      disabled: record.key === 'Disabled User', // Column configuration not to be checked
      id: record.key,
    }),
  };

  const mapData = (photos: FileDetails[]) => {
    return photos?.map((photo: FileDetails): MediaTableDataProps => {
      return {
        key: photo.id,
        id: photo.id,
        file_name: photo?.file_name,
        url: photo.url,
        asset_type: photo?.file_extension,
        format: photo?.file_extension,
        size: photo?.size,
        dimensions: 'none',
        access_control: 'public',
        tagged_in: photo.upload_param?.model_name,
        uploaded_at: photo?.created_at,
      };
    });
  };

  /*const updateCacheAfterUpload = (data) => {
    const resObj = {
      id: data.id,
      url: data.url,
      content_type: data.content_type,
      file_name: data.file_name,
      created_at: data.created_at,
      size: data.size,
    };
    setPhotos([resObj, ...photos]);
  };*/

  const [deleteMediaFile] = useDeleteMediaFileMutation({
    onCompleted: (data) => {
      successMessage('Image Deleted!');
      setPhotos(photos.filter((photo) => photo?.key !== indexesForDelete));
      setIndexesForDelete('');
      //setIsDeleting(false);
    },
  });

  const sendDebounceRequest = async (searchStr, filterByModelOpt, page) => {
    await fetchMore({
      variables: {
        page,
        limit,
        search: searchStr || undefined,
        models:
          Array.isArray(filterByModelOpt) && filterByModelOpt?.length > 0
            ? filterByModelOpt
            : undefined,
      },
    });
  };

  const updateQuery = () => sendDebounceRequest(searchStr, filterByModelOpt, page);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let delayedSearchRequest = useCallback(updateQuery, [searchStr, filterByModelOpt, page]);
  delayedSearchRequest = debounce(delayedSearchRequest, 500);

  useEffect(() => {
    if (default_storage_plugin !== '') {
      console.log(
        'page ',
        page,
        'search ',
        searchStr,
        'length ',
        filterByModelOpt,
        'storage id ',
        default_storage_plugin
      );
      delayedSearchRequest();
      // @ts-ignore
      return delayedSearchRequest.cancel;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr, filterByModelOpt, page, default_storage_plugin]);

  useEffect(() => {
    console.log('use effect -> data ', data);
    const tableData = data?.getPhotos?.results;
    setPhotos(mapData(tableData));
    setTotalCount(data?.getPhotos?.count);
    // setSkip(true)
  }, [data]);

  const onImageFetch = async () => {
    const { url } = await form.validateFields();
    await uploadImageFromUrl({
      variables: {
        url,
      },
    });
  };

  const menu = (id: string) => (
    <Menu
      items={[
        {
          label: 'Delete',
          key: '0',
          onClick: async () => {
            //setIsDeleting(true);
            setIndexesForDelete(id); // Todo: will be an array
            await deleteMediaFile({
              variables: {
                ids: [id],
              },
            });
          },
        },
      ]}
    />
  );

  const columns: ColumnsType<FileDetails> = [
    {
      title: 'Image',
      dataIndex: 'url',
      key: 'url',
      render: (data) => {
        return <Image width={85} src={data} />;
      },
    },
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
      width: 200,
      ellipsis: true,
    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      key: 'asset_type',
      render: (type) => {
        return type === 'jpg' || type === 'png' ? 'Image' : 'unknown';
      },
    },
    {
      title: 'Format',
      dataIndex: 'format',
      key: 'format',
      render: (type) => {
        switch (type) {
          case 'jpg':
            return <Tag color={'green'}>{type}</Tag>;
          case 'png':
            return <Tag color={'blue'}>{type}</Tag>;
          case 'svg':
            return <Tag color={'red'}>{type}</Tag>;
          default:
            return <Tag color={'yellow'}>{type}</Tag>;
        }
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      render: (size) => {
        return <PrettyBytes className={styles.size} bytes={size} />;
      },
    },
    {
      title: 'Dimensions',
      dataIndex: 'dimensions',
      key: 'dimensions',
    },
    {
      title: 'Access Control',
      dataIndex: 'access_control',
      key: 'access_control',
    },
    {
      title: 'Uploaded At',
      dataIndex: 'uploaded_at',
      key: 'uploaded_at',
      render: (time) => {
        return <p className={styles.time}>{moment(time).fromNow()}</p>;
      },
    },
    {
      title: 'Tagged In Model',
      dataIndex: 'tagged_in',
      key: 'tagged_in',
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text) => {
        return menu(text.id);
      },
    },
  ];

  const onTablePageChange = (currPage) => {
    setPage(currPage);
    console.log(currPage);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Develop',
      key: '1',
    },
    {
      label: 'Stage',
      key: '2',
    },
    {
      label: 'Production',
      key: '3',
    },
  ];

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [isURLUploadModalVisible, setIsURLUploadModalVisible] = useState(false);
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(false);

  const [uploadImageFromUrl, { loading: isFetching }] = useUploadImageFromUrlMutation({
    onCompleted: () => {
      successMessage('Image Uploaded');
      setIsURLUploadModalVisible(false);
      fetchMore({
        variables: {
          page,
          limit,
          search: undefined,
          // models: Array.isArray(filterByModelOpt) && filterByModelOpt?.length > 0 ? filterByModelOpt : undefined,
        },
      });
    },
  });

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files', file as RcFile);
      formData.append('plugin', default_storage_plugin);
    });
    setUploading(true);
    // You can use any AJAX library you like
    fetch(MEDIA_UPLOAD_URL, {
      method: 'POST',
      body: formData,
      headers: {
        'X-Use-Cookies': 'true',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then(() => {
        setFileList([]);
        message.success('Upload Successfully.');
        setIsURLUploadModalVisible(false);
        fetchMore({
          variables: {
            page,
            limit,
            search: undefined,
            // models: Array.isArray(filterByModelOpt) && filterByModelOpt?.length > 0 ? filterByModelOpt : undefined,
          },
        });
      })
      .catch(() => {
        message.error('Upload Failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return true;
      }
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        message.error('Image must smaller than 4MB!');
        return true;
      }
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return default_storage_plugin === '' ? (
    <MainLayout title={'Media Library'} breadcrumList={['Media']}>
      {' '}
      <Empty
        //image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 60,
        }}
        description={<h3>No Storage Plugin Activated.</h3>}
      >
        <Button
          type="primary"
          onClick={(e) => {
            navigate('/console/settings/extensions');
          }}
        >
          Activate One Now
        </Button>
      </Empty>
    </MainLayout>
  ) : (
    <MainLayout
      title={'Media Library'}
      breadcrumList={['Media']}
      extra={
        <Space>
          {selectedImages.length > 0 ? (
            <>
              <Alert
                message={`${selectedImages.length} Images Chosen`}
                type="info"
                action={
                  <Space direction="horizontal">
                    <Button type="primary" onClick={() => onImageSelectCallback(selectedImages)}>
                      SELECT
                    </Button>
                  </Space>
                }
              />
              <Divider type="vertical" style={{ height: 36 }} />
            </>
          ) : null}
          <Search
            placeholder="Search Images"
            onSearch={async (value) => {
              setPage(1); // rest the page
              await fetchMore({
                variables: {
                  page,
                  limit,
                  search: value || undefined,
                  // models: Array.isArray(filterByModelOpt) && filterByModelOpt?.length > 0 ? filterByModelOpt : undefined,
                },
              });
            }}
            onChange={(e) => setSearchStr(e.target.value.trim() || '')}
          />
          {!selectMode ? (
            <>
              <Divider type="vertical" style={{ height: 36 }} />
              <Text>Media Plugin : </Text>
              <Select
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                value={'1'}
                options={[
                  {
                    value: '1',
                    label: projectDetails?.default_storage_plugin,
                  },
                ]}
              />
            </>
          ) : null}
          <Divider type="vertical" style={{ height: 36 }} />
          <Button
            icon={<UploadOutlined />}
            type="primary"
            onClick={() => setIsFileUploadModalVisible(true)}
          >
            UPLOAD FILE
          </Button>
          <Divider type="vertical" style={{ height: 36 }} />
          <Button
            icon={<LinkOutlined />}
            type="primary"
            onClick={() => setIsURLUploadModalVisible(true)}
          >
            UPLOAD FROM URL
          </Button>
        </Space>
      }
    >
      <div className={styles.root}>
        <Table
          rowSelection={
            selectMode
              ? {
                  type: isGallery ? 'checkbox' : 'radio',
                  ...rowSelection,
                }
              : null
          }
          loading={loading}
          pagination={{
            onChange: onTablePageChange,
            pageSize: 10,
            current: page,
            total: totalCount,
            position: ['topRight'],
          }}
          showHeader={true}
          dataSource={photos}
          columns={columns}
        />

        <Modal
          title="Upload Image from URL"
          open={isURLUploadModalVisible}
          footer={[
            <Button key="back" onClick={() => setIsURLUploadModalVisible(false)}>
              CANCEL
            </Button>,
            <Button key="submit" type="primary" loading={isFetching} onClick={() => onImageFetch()}>
              UPLOAD FROM URL
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical" name="userForm">
            <Form.Item name="url" label="Image URL" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Upload From Computer"
          open={isFileUploadModalVisible}
          footer={[
            <Button key="back" onClick={() => setIsFileUploadModalVisible(false)}>
              CANCEL
            </Button>,
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{ marginTop: 16 }}
            >
              {uploading ? 'UPLOADING' : 'START UPLOAD'}
            </Button>,
          ]}
        >
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Modal>
      </div>
    </MainLayout>
  );
};

export default MediaContentTable;

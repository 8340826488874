import { Select, SelectProps } from 'antd';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

const { Option } = Select;

interface CustomSelectProps extends Partial<SelectProps> {
  setModel?: Function;
  modelList?: string[];
  forwardRelation?: string;
  initialVal?: string;
  data: string[];
}

const CustomSelect = ({
  data,
  initialVal,
  modelList,
  forwardRelation,
  setModel,
  ...props
}: CustomSelectProps) => {
  const _replace = (str) => str.replace(/_/g, ' ');

  return (
    <Select
      allowClear
      placeholder="Select One"
      value={initialVal}
      notFoundContent="No Model Found"
      defaultValue={initialVal}
      onSelect={(e) => (modelList?.includes(e) ? setModel(e) : null)}
      {...props}
    >
      {data.map((item, index) => (
        <Option value={item} key={index}>
          {modelList?.includes(item) && forwardRelation === 'has_many'
            ? capitalize(pluralize(item))
            : _replace(capitalize(item))}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;

import {
  LoadingOutlined,
  LogoutOutlined,
  ProjectOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Menu, Popover } from 'antd';
import { useAuthContext } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

type UserMenuProps = {
  email: string;
  role: string;
  avatar: string;
};

const UserMenu: React.FC<UserMenuProps> = ({ email, role, avatar }) => {
  const { logout, loading } = useAuthContext();
  const navigate = useNavigate();

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<SendOutlined />} onClick={() => navigate('/docs/quick-start')}>
        Quick Start
      </Menu.Item>
      <Menu.Item key="2" icon={<ProjectOutlined />} onClick={() => navigate('/projects')}>
        Projects
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={!loading ? <LogoutOutlined /> : <LoadingOutlined />}
        onClick={() => {
          logout();
        }}
        disabled={loading}
      >
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Popover content={menu} title={email} placement="bottomRight">
      <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#E73A55' }} size={40} />
    </Popover>
  );
};

export default UserMenu;

export const FILTER_ARR_FOR_NUMBER = [
  {
    label: 'Equals',
    value: 'eq',
  },
  {
    label: 'Not Equals',
    value: 'ne',
  },
  {
    label: 'Less Than',
    value: 'lt',
  },
  {
    label: 'Less Than Equals',
    value: 'lte',
  },
  {
    label: 'Greater Than',
    value: 'gt',
  },
  {
    label: 'Greater Than Equal',
    value: 'gte',
  },
];

export const DEFAULT_FILTER_ARR = [
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'IN',
    value: 'in',
  },
  {
    label: 'Not IN',
    value: 'not_in',
  },
  {
    label: 'Equals',
    value: 'eq',
  },
  {
    label: 'Not Equals',
    value: 'ne',
  },
];

export const DEFAULT_FILTER_STRING = [
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Equals',
    value: 'eq',
  },
  {
    label: 'Not Equals',
    value: 'ne',
  },
];

export const FILTER_ARR_FOR_MULTILINE = [
  {
    label: 'Contains',
    value: 'contains',
  },
];

export const FILTER_ARR_FOR_STRING = [
  {
    label: 'Equals',
    value: 'eq',
  },
  {
    label: 'Not Equals',
    value: 'ne',
  },
];

export const FILTER_ARR_FOR_ARRAY = [
  {
    label: 'IN',
    value: 'in',
  },
  {
    label: 'Not IN',
    value: 'not_in',
  },
];

export const FILTER_ARR_FOR_BOOLEAN = [
  {
    label: 'Equals',
    value: 'eq',
  },
  {
    label: 'Not Equals',
    value: 'ne',
  },
];

import { Button, Card, Typography } from 'antd';
import styles from './cards.module.scss';

const { Text } = Typography;

type PriceCardProps = {
  title: string;
  infoList?: Record<string, never>[];
  price: number;
  previousPrice?: number;
  subtitle: string;
  btnLabel?: string;
};

const PriceCard: React.FC<PriceCardProps> = ({
  title,
  infoList,
  price,
  previousPrice,
  subtitle,
  btnLabel,
}) => {
  return (
    <Card
      title={title}
      headStyle={{ border: 'none' }}
      bodyStyle={{ paddingTop: 0 }}
      className={styles.priceCard}
      hoverable
    >
      <div className={styles.cardBody} style={{ minHeight: `${infoList ? '478px' : '188px'}` }}>
        <div>
          <Text>{subtitle}</Text>
        </div>

        <div>
          <div className={styles.priceWrapper}>
            {previousPrice ? (
              <del>
                <Text>
                  <span className={styles.prevPrice}>${previousPrice}</span> /Month
                </Text>
              </del>
            ) : null}
            <Text>
              <span className={styles.price}>${price}</span> /Month
            </Text>
          </div>

          <div>
            <Button
              type="primary"
              className={styles.cardBtn}
              // onClick={() => onOpenModal(item)}
            >
              ADD {btnLabel ?? ''}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PriceCard;

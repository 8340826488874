import { SVGProps } from 'react';

const LogoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 175 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 45.3543C0 20.3058 20.3058 0 45.3543 0H92.8019C117.85 0 138.156 20.3058 138.156 45.3543V92.8019C138.156 117.85 117.85 138.156 92.8019 138.156H45.3543C20.3058 138.156 0 117.85 0 92.8019V45.3543Z"
        fill="#EA3A60"
      />
      <path
        d="M64.4736 64.4766H119.736C150.257 64.4766 174.999 89.2184 174.999 119.739V119.739C174.999 150.26 150.257 175.002 119.736 175.002V175.002C89.2155 175.002 64.4736 150.26 64.4736 119.739V64.4766Z"
        fill="#263238"
      />
    </svg>
  );
};

export default LogoIcon;

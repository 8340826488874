import { Badge, Button, Divider, Drawer, Spin, Tag, Typography } from 'antd';
import { useState } from 'react';
import RevisionDiffContainer from './RevisionDiffContainer';
import { useFormDataQueryRevisionQuery } from 'generated/graphql';

const { Text } = Typography;

const RevisionHistory = ({
  model: modelName,
  _id,
}: {
  model: string;
  _id: string;
  status: string;
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [contentData, setContentData] = useState<{
    id: string;
    clickedId: string;
    model: string;
    status: string;
    revision: boolean;
  }>();

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  const { loading, data } = useFormDataQueryRevisionQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      _id,
      model: modelName,
    },
  });

  return (
    <div>
      <Spin spinning={loading}>
        {data?.listSingleModelRevisionData?.length > 0
          ? data?.listSingleModelRevisionData?.map((r) => (
              <Badge
                color={r.status === 'published' ? 'green' : 'yellow'}
                text={[
                  <Text
                    underline={true}
                    onClick={(e) => {
                      setContentData({
                        id: _id ?? '',
                        clickedId: r.id,
                        model: modelName,
                        status: r.status,
                        revision: true,
                      });
                      setDrawerVisible(true);
                    }}
                  >
                    {r.id}
                  </Text>,
                  <Divider type="vertical" />,
                  <Tag color={r.status === 'published' ? 'green' : 'yellow'}>{r.status}</Tag>,
                ]}
              />
            ))
          : 'No Revision History Found'}
      </Spin>
      <Drawer // Default Drawer
        title={`Revision: ${contentData?.id} || Status: ${contentData?.status}`}
        //height="95%"
        width="90%"
        bodyStyle={{ padding: '5px 24px 24px' }}
        closable
        // destroyOnClose={true}
        onClose={onDrawerClose}
        visible={drawerVisible}
        //placement={'bottom'}
        extra={<Button type="link" onClick={onDrawerClose}>{`< Back`}</Button>}
      >
        <RevisionDiffContainer contentData={contentData} />
      </Drawer>
    </div>
  );
};

export default RevisionHistory;

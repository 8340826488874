import { Button, Card, Checkbox, Form, Input, InputNumber } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import pluralize from 'pluralize';
import { ANY_FIXME } from 'types/common';
import ReactDraft from 'utils/ReactDraft';
import { CustomDatePicker } from 'components/inputs/CustomDatePicker';
import { BooleanSelector } from 'components/inputs/BooleanSelector';
import { generateLabel } from 'utils/GenerateLabelAndIcon';
import { capitalize } from 'lodash';
import { ImageUploaderModal } from 'components/inputs/ImageUploaderModal';
import { InputGeoField } from './InputGeoField';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { DynamicFormInsideArrayList } from 'components/groupedInput/DynamicFormList';

dayjs.extend(relativeTime);

export function RepeatedFieldsGenerator({ field_item, ...props }) {
  const FieldsList = field_item?.sub_field_info ?? [];

  const getValidationRules = (item) => {
    const required = item?.validation?.required && !item?.validation?.is_password;
    return [
      {
        required,
        message: `${capitalize(item.identifier)} is required`,
      },
    ];
  };

  // const SingleField = (fieldItem) => {
  //   const item = fieldItem?.value ?? {};
  //   return renderFormItem(item, item?.identifier);
  // };

  const getFormField = (field_item, name?: ANY_FIXME[]) => {
    const field_Type = field_item?.field_type;

    switch (field_Type) {
      case 'text':
        if (field_item?.system_generated) {
          return <Input addonBefore="UUID" title="some" defaultValue={uuidv4()} disabled />;
        }
        return <Input />;

      case 'multiline':
        return <ReactDraft />;
      case 'date':
        return <CustomDatePicker />;
      case 'number':
        return <InputNumber style={{ width: '100%' }} />;
      case 'boolean':
        return <BooleanSelector />;
      case 'media':
        return <ImageUploaderModal field_item={field_item} />;
      case 'geo':
        return <InputGeoField field_item={field_item} namePath={name} />;
      case 'list':
        const list = field_item?.validation?.fixed_list_elements || [];
        const multiChoice = field_item?.validation?.is_multi_choice;

        if (list.length > 0 && !multiChoice) {
          return <FixedListSelector list={list} />;
        }
        if (list.length > 0 && multiChoice) {
          return <Checkbox.Group options={list} />;
        }
        if (list.length === 0 && !multiChoice) {
          return <DynamicFormInsideArrayList field_item={field_item} namePath={name} />;
        }
        break;
      default:
        break;
    }
  };

  const renderObjectFormItems = (fieldItem, FieldsArray) => {
    return FieldsArray.map((item, i) => {
      const list = item?.validation?.fixed_list_elements || [];
      const multiChoice = item?.validation?.is_multi_choice;

      if (item?.field_type === 'multiline') {
        return (
          <Form.Item
            key={i}
            name={['form', `${fieldItem.identifier}`, `${item.identifier}`, 'html']}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      if (item?.field_type === 'geo' || (list.length === 0 && !multiChoice)) {
        // geo && dynamicList
        return (
          <Form.Item
            key={i}
            name={['form', `${fieldItem.identifier}`, `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
          >
            {getFormField(item, ['form', `${fieldItem.identifier}`, `${item.identifier}`])}
          </Form.Item>
        );
      }
      return (
        <Form.Item
          key={i}
          name={['form', `${fieldItem.identifier}`, `${item.identifier}`]}
          label={generateLabel(item)}
          tooltip={item.description}
          rules={getValidationRules(item)}
        >
          {getFormField(item)}
        </Form.Item>
      );
    });
  };

  const renderArrayFormItems = (FieldsArray, index) =>
    FieldsArray.map((item, i) => {
      const list = item?.validation?.fixed_list_elements || [];
      const multiChoice = item?.validation?.is_multi_choice;

      if (item?.field_type === 'multiline') {
        return (
          <Form.Item
            key={i}
            name={[index, `${item.identifier}`, 'html']}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      if (item?.field_type === 'geo' || (list.length === 0 && !multiChoice)) {
        // geo & dynamicList
        return (
          <Form.Item
            key={i}
            name={[index, `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
          >
            {getFormField(item, [index, `${item.identifier}`])}
          </Form.Item>
        );
      }
      return (
        <Form.Item
          key={i}
          name={[index, `${item.identifier}`]}
          label={generateLabel(item)}
          tooltip={item.description}
          rules={getValidationRules(item)}
        >
          {getFormField(item)}
        </Form.Item>
      );
    });

  // console.log('Log: renderFormItems -> field', field)

  if (field_item?.field_type === 'object') {
    return (
      <div style={{ marginTop: 10 }}>
        <Card
          headStyle={{
            borderBottomColor: '#D7D9DA',
            borderBottomWidth: '1px',
            backgroundColor: '#FAFAFA',
          }}
          style={{ marginBottom: '15px' }}
        >
          {renderObjectFormItems(field_item, FieldsList)}
        </Card>
      </div>
    );
  } else {
    return (
      <Form.List name={['form', `${field_item.identifier}`]} {...props}>
        {/* formList name must be same as parent to catch initial value */}
        {(fields, { add, remove }) => (
          <div style={{ marginTop: 10 }}>
            {fields.map((field, index) => (
              <Card
                headStyle={{
                  borderBottomColor: '#D7D9DA',
                  borderBottomWidth: '1px',
                  backgroundColor: '#FAFAFA',
                }}
                title={`${capitalize(field_item.identifier)} Section #${index + 1}`}
                extra={
                  <div>
                    {fields.length >= 1 ? (
                      <Button onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                        Remove Field
                      </Button>
                    ) : null}
                  </div>
                }
                style={{ marginBottom: '15px' }}
              >
                <div key={field.key}>{renderArrayFormItems(FieldsList, index)}</div>
              </Card>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                style={{ width: '100%' }}
              >
                <PlusOutlined /> Add {pluralize(`${field_item.label}`, 1)}
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    );
  }
}

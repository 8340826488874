import { Layout, PageHeader as ContentHeader } from 'antd';
import PageHeader from 'components/headers/PageHeader';
import styles from './layouts.module.scss';
import { ReactNode } from 'react';
import useProjectStore from '../../store/projectStore';

type MainLayoutProps = {
  children: ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string;
  noPageHeader?: boolean;
  extra?: React.ReactNode;
  parentName?: string;
  breadcrumList?: string[];
  trialText?: string;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  title,
  subtitle,
  noPageHeader,
  extra,
  children,
  breadcrumList,
  trialText,
}) => {
  const { Content } = Layout;

  const { projectDetails } = useProjectStore();

  return (
    <>
      {!noPageHeader && (
        <PageHeader
          breadcrumList={breadcrumList}
          projectName={projectDetails.name}
          trialText={trialText}
        />
      )}
      <Content style={{ overflowY: 'auto' }}>
        <ContentHeader
          title={title}
          subTitle={subtitle}
          extra={extra}
          className={styles?.contentHeader}
        >
          {children}
        </ContentHeader>
      </Content>
    </>
  );
};

export default MainLayout;

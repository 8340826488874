import { useContext, useState } from 'react';

import { Button, Form, Input, Skeleton } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

import pluralize from 'pluralize';

import styles from '../fieldConfigDrawerComponent.module.scss';
import { capitalize } from 'lodash';
import { ContentContext } from 'context/ContentContext';
import CustomSelect from '../CustomSelect';
import {
  Connection_Type_Enum,
  GetAllModelsQuery,
  useGetAllModelsQuery,
  useUpdateModelRelationMutation,
} from 'generated/graphql';
import { errorMessage, successMessage } from 'utils/message';
import { GET_FORM_GENERATION_DATA } from '../../../../../../graphql/queries/contents';

const AddRelation = () => {
  const { loading, error, data } = useGetAllModelsQuery();
  if (error) {
    errorMessage(error);
    return null;
  }
  return loading ? <Skeleton active /> : <AddRelationContainer data={data} />;
};

type AddRelationContainerProps = {
  data: GetAllModelsQuery;
};

const AddRelationContainer = ({ data }: AddRelationContainerProps) => {
  const modelList = data?.projectModelsInfo ?? [];
  const { state } = useContext(ContentContext);
  const [model, setModel] = useState('');
  const model_List = modelList
    ?.filter((model) => model.name !== state.target)
    .map((item) => item.name);
  const relationArr: Connection_Type_Enum[] = [
    Connection_Type_Enum.HasMany,
    Connection_Type_Enum.HasOne,
  ];

  const [forwardRelation, setForwardRelation] = useState('');
  const [backwardRelation, setBackwardRelation] = useState('');

  const onFormValueChange = (values) => {
    const forwardRltn = values?.forward?.relation ?? '';
    const backwardRltn = values?.backward?.relation ?? '';

    if (forwardRltn) setForwardRelation(forwardRltn);
    if (backwardRltn) setBackwardRelation(backwardRltn);
  };

  const updateModelRelationCache = (cache, { data }) => {
    const existingRelationData = cache.readQuery({
      query: GET_FORM_GENERATION_DATA,
      variables: {
        model_name: `${state.target}`,
      },
    });

    const resData = data?.upsertConnectionToModel;

    const updatedConnectionData = [
      ...resData,
      ...existingRelationData?.projectModelInfoByName?.connections?.filter(
        (item) => item.model !== model
      ),
    ];

    cache.writeQuery({
      query: GET_FORM_GENERATION_DATA,
      variables: {
        model_name: `${state.target}`,
      },
      data: { projectModelInfoByName: { connections: updatedConnectionData } },
    });
  };

  const [createModelRelation, { loading }] = useUpdateModelRelationMutation({
    onCompleted: (data) => {
      successMessage('New Relation Created Sucessfully!');
    },
    update: updateModelRelationCache,
  });

  const onFinish = (values) => {
    const payload = {
      forward_connection_type: values?.forward?.relation,
      from: state.target,
      reverse_connection_type: values?.backward?.relation,
      to: values?.forward?.model,
      known_as: values?.known_as,
    };
    // await setPayload(payload);
    createModelRelation({
      variables: {
        ...payload,
      },
    });
  };

  return (
    <div className={styles.createField}>
      <Form
        name="add_relation"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onFormValueChange}
      >
        <div className={`${styles.topContent} mt-2`}>
          <div>
            <Form.Item label="Forward Relationship" rules={[{ required: true }]}>
              <Input value={capitalize(state.target)} />
            </Form.Item>
            <Form.Item
              name={['forward', 'relation']}
              rules={[{ message: 'Relation Type is Required', required: true }]}
            >
              <CustomSelect placeholder="Has One/Has Many" data={relationArr} />
            </Form.Item>
            <Form.Item
              name={['forward', 'model']}
              rules={[{ message: 'Another Model is Required', required: true }]}
            >
              <CustomSelect data={model_List} modelList={model_List} setModel={setModel} />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Backward Relationship" rules={[{ required: true }]}>
              <Input
                disabled
                placeholder="Select Forward Relationship First"
                value={capitalize(model)}
              />
            </Form.Item>
            <Form.Item
              name={['backward', 'relation']}
              rules={[{ message: 'Relation Type is Required', required: true }]}
            >
              <CustomSelect
                placeholder="Has One/Has Many"
                modelList={model_List}
                data={relationArr}
                forwardRelation={forwardRelation}
                setModel={setModel}
              />
            </Form.Item>
            <Form.Item rules={[{ required: true }]}>
              <Input
                disabled
                value={
                  backwardRelation === 'has_many'
                    ? capitalize(pluralize(state.target))
                    : capitalize(state.target)
                }
              />
            </Form.Item>
          </div>
          <Form.Item
            name="known_as"
            label="Known As (Optional)"
            help={
              <div>
                Please do check out the{' '}
                <a
                  target="_"
                  href="https://docs.apito.io/console/models-fields#creating-relations-between-models"
                >
                  guide
                </a>{' '}
                before using this field. If you are confused then dont.
              </div>
            }
          >
            <Input placeholder="Alternate identifier for this relation" value="" />
          </Form.Item>
        </div>

        <Button
          block
          type="primary"
          htmlType="submit"
          icon={<CloudUploadOutlined />}
          loading={loading}
        >
          CREATE RELATION
        </Button>
      </Form>
    </div>
  );
};

export default AddRelation;

import { Button, Checkbox, CheckboxOptionType, Divider, Form, Typography } from 'antd';
import { InputHTMLAttributes } from 'react';

const { Text } = Typography;

interface IGroupedCheckBoxWithAllSelectProps
  extends Partial<InputHTMLAttributes<HTMLInputElement>> {
  label: string;
  subTitle?: string;
  options?: CheckboxOptionType[];
  formValueUpdater: (data: { [x: string]: Record<string, any>[] }) => void;
  isDisabled?: boolean;
  rules?: { required: boolean }[];
  name: string;
  initialValue?: string[];
  onReset?: () => void;
}

const GroupedCheckBoxWithAllSelect = ({
  label,
  subTitle,
  options,
  formValueUpdater,
  isDisabled,
  initialValue,
  ...props
}: IGroupedCheckBoxWithAllSelectProps) => {
  return (
    <div>
      <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
        <Text className="title">
          {props.rules?.map((e, index) => {
            if (e.required === true) {
              return (
                <Text className="required" key={index}>
                  *{' '}
                </Text>
              );
            }
            return '';
          })}
          {label || ''}
        </Text>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginTop: '-4px' }}
        >
          <Button
            style={{ border: 'none', color: 'var(--themeColor)', padding: 4 }}
            onClick={(e) => formValueUpdater({ [`${props?.name}`]: options })}
          >
            Check All
          </Button>
          <Divider type="vertical" />
          <Button
            style={{ border: 'none', color: 'var(--themeColor)', padding: 4 }}
            className="btn"
            onClick={(e) => formValueUpdater({ [`${props?.name}`]: [] })}
          >
            Uncheck All
          </Button>
        </div>
      </div>
      {subTitle ? (
        <div style={{ paddingBottom: '15px' }}>
          <Text type="secondary">{subTitle || ''}</Text>
        </div>
      ) : null}
      <Form.Item {...props}>
        <Checkbox.Group options={options || []} disabled={isDisabled} />
      </Form.Item>
      <style>
        {`
            .ant-checkbox-group-item + .ant-checkbox-group-item{
                margin-bottom: 8px;
            }
            .required {
                color: red
            }
            .subTitle{
                margin-bottom: 15px;
            }
        `}
      </style>
    </div>
  );
};

export default GroupedCheckBoxWithAllSelect;

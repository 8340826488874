import { Form, Table } from 'antd';
import FixedListSelector from 'components/inputs/FixedListSelector';

const AddRolesTable = ({ models, initialData, isDisabled }) => {
  const initialDataKeys = initialData ? Object.keys(initialData) : [];

  const tableData =
    models?.length > 0
      ? models.map((model, i) => {
          if (initialDataKeys?.length > 0 && initialDataKeys.includes(model)) {
            return {
              model,
              read: initialData?.[`${model}`]?.read || 'none',
              create: initialData?.[`${model}`]?.create || 'none',
              update: initialData?.[`${model}`]?.update || 'none',
              delete: initialData?.[`${model}`]?.delete || 'none',
            };
          }
          return {
            model,
            read: 'none',
            create: 'none',
            update: 'none',
            delete: 'none',
          };
        })
      : [];

  const tableDropdownList = [
    {
      label: 'None',
      value: 'none',
    },
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Logged In User',
      value: 'auth',
    },
    {
      label: 'Tenant Specific',
      value: 'tenant',
    },
    {
      label: 'Personal Data',
      value: 'own',
    },
  ];

  const tableColumns: any = [
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model', // unique for eatch column (ignore if dataIndex is unique)
    },
    {
      title: 'Read',
      key: 'read',
      align: 'center',
      width: 150,
      render: (text) => (
        <div>
          <Form.Item
            name={['api_permissions', `${text.model}`, 'read']}
            initialValue={text.read || 'none'}
          >
            <FixedListSelector
              islabelValue
              list={tableDropdownList}
              allowClear={false}
              disabled={isDisabled}
            />
          </Form.Item>
        </div>
      ),
    },
    {
      title: 'Create',
      key: 'create',
      align: 'center',
      width: 150,
      render: (text) => (
        <div>
          <Form.Item
            name={['api_permissions', `${text.model}`, 'create']}
            initialValue={text.create || 'none'}
          >
            <FixedListSelector
              islabelValue
              list={tableDropdownList}
              allowClear={false}
              disabled={isDisabled}
            />
          </Form.Item>
        </div>
      ),
    },
    {
      title: 'Update',
      key: 'update',
      align: 'center',
      width: 150,
      render: (text) => (
        <div>
          <Form.Item
            name={['api_permissions', `${text.model}`, 'update']}
            initialValue={text.update || 'none'}
          >
            <FixedListSelector
              islabelValue
              list={tableDropdownList}
              allowClear={false}
              disabled={isDisabled}
            />
          </Form.Item>
        </div>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      align: 'center',
      width: 150,
      render: (text) => (
        <div>
          <Form.Item
            name={['api_permissions', `${text.model}`, 'delete']}
            initialValue={text.delete || 'none'}
          >
            <FixedListSelector
              islabelValue
              list={tableDropdownList}
              allowClear={false}
              disabled={isDisabled}
            />
          </Form.Item>
        </div>
      ),
    },
  ];

  // const [deleteToken] = useMutation(DELETE_API_CREDENTIAL, {
  //     onError: err => { errorMessage(err) },
  //     onCompleted: data => {
  //         successMessage('Token Deleted Successfully!')
  //     },
  //     update: updateCache
  // })

  return (
    <div>
      <Table
        dataSource={tableData}
        columns={tableColumns}
        pagination={false}
        bordered
        rowKey="model"
        // style={{ maxWidth: '1080px' }}
        size="small"
        //scroll={{ y: 280 }}
      />
    </div>
  );
};

export default AddRolesTable;

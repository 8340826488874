import { LoadingOutlined } from '@ant-design/icons';

const CustomLoading = () => {
  return (
    <div // TODO: Fix this styling must!
      style={{
        minHeight: '200px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingOutlined style={{ fontSize: '26px' }} />
    </div>
  );
};

export default CustomLoading;

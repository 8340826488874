export const runtimeEnvList = [
  {
    label: 'go 1.x',
    value: 'go1.x',
  },
  {
    label: 'nodejs 10.x',
    value: 'nodejs10.x',
  },
  {
    label: 'nodejs 12.x',
    value: 'nodejs12.x',
  },
  {
    label: '.NetCore 2.1',
    value: 'dotnetcore2.1',
  },
  {
    label: '.NetCore 3.1',
    value: 'dotnetcore3.1',
  },
  {
    label: 'java 8',
    value: 'java8',
  },
  {
    label: 'java 11',
    value: 'java11',
  },
  {
    label: 'ruby 2.7',
    value: 'ruby2.7',
  },
  {
    label: 'ruby 2.5',
    value: 'ruby2.5',
  },
  {
    label: 'python 3.8',
    value: 'python3.8',
  },
  {
    label: 'python 3.7',
    value: 'python3.7',
  },
  {
    label: 'python 3.6',
    value: 'python3.6',
  },
  {
    label: 'python 2.7',
    value: 'python2.7',
  },
];

import React from 'react';
import Audit from './Audit';

const AuditContainer = () => {
  return (
    <div>
      <Audit />
    </div>
  );
};

export default AuditContainer;

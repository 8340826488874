import React from 'react';
import APIExplorer from './APIExplorer';
import styles from '../../components/layouts/layouts.module.scss';
import { Layout } from 'antd';

const APIExplorerContainer = () => {
  return (
    <Layout className={styles.layoutContentInner}>
      <APIExplorer />
    </Layout>
  );
};

export default APIExplorerContainer;

export const fakeDataList = {
  text: [
    'product_name | Small Wooden Chair',
    'product_category | Electronics, Home & Baby',
    'name | John Doe',
    'quote | "Gluten-free selfies everyday photo booth chia ethical food truck synth deep v." - Harvey Moen',
    'phase | wake up and smell the ashes',
    'question | Photo booth whatever helvetica helvetica?',
    'sentence | Implement wine hill performance core hold reduction.',
    'uuid | 0d4ca573-2853-4745-8b06-b0db725b86dd',
    'url | http://www.internalparadigms.name/target/sticky',
    'credit_card_number | 3009348432096707',
    'company | SocialEffort Inc',
    'hacker_phrase | Quantifying the matrix wont do anything, we need to override the solid state PCI matrix!',
    'hipstar_sentence | Tote bag salvia mixtape pabst craft beer wolf venmo.',
    'name_prefix | Dr',
    'name_suffix | Jr.',
    'first_name | John',
    'last_name | Doe',
    'gender | Female',
    'color | pink',
    'ssn | 682442524',
    'email | example@gmail.com',
    'phone | 761-313-9262',
    'city | Lennaton',
    'country | Romania',
    'state | North Dakota',
    'street | 2339 Isle borough',
    'zip | 60650',
    'time | 3.04PM',
    'breakfast | Cinnamon maple granola',
    'dessert | Nifs peanut butter banana muffins',
    'dinner | Hoisin marinated wing pieces',
    'lunch | Kittencals beef burritos',
    'fruit | Raspberry',
    'car_model | E55 Amg (wagon)',
    'job | Technician',
    'animal | walrus',
  ],
  random: ['Random'],
  boolean: ['boolean | Random True or False'],
  multiline: [
    'paragraph | 2 Paragraph, 5 Sentence, 20 Words',
    'lorem_paragraph | 2 Paragraph, 5 Sentence, 20 Words',
    'hipstar_paragraph | 2 Paragraph, 5 Sentence, 20 Words',
  ],
  number: [
    'int | 1234',
    'double | 589.89',
    'price | 5.99 (0.0 to 100.0)',
    'rating5 | 4.9 (0.0 to 5.0)',
    'rating10 | 7.8 (0.0 to 10.0)',
  ],
  geo: ['geo | 40.741895, -73.989308'],
  date: ['date | 2021-02-05'],
  media: ['media | 450x450 image'],
};

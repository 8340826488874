import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonType } from 'antd/es/button';

export type BackToButtonProps = {
  label: string;
  path: string;
  type?: ButtonType;
};

const BackToButton: React.FC<BackToButtonProps> = ({ type, label, path }) => {
  const navigate = useNavigate();
  return (
    <Button
      size="large"
      type={type || 'default'}
      onClick={() => {
        navigate(path);
      }}
    >
      {label}
    </Button>
  );
};

export default BackToButton;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';

export function FuncEnvDynamicFormList(props) {
  return (
    <Form.List name="env_vars" {...props}>
      {/* formList name must be same as parent to catch initial value */}
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <Form.Item required={false} key={field.key} style={{ marginBottom: 0 }}>
              <Form.Item {...field} style={{ marginBottom: 5 }}>
                <Input.Group compact>
                  <Form.Item
                    name={[`${field.name}`, 'key']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please input Name or delete this field.',
                      },
                    ]}
                    noStyle
                  >
                    <Input style={{ width: '50%' }} placeholder="Key" title="Name" />
                  </Form.Item>

                  <div style={{ paddingLeft: '10px' }} />

                  <Form.Item
                    name={[`${field.name}`, 'value']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Insert a Key Value Pair or delete this field.',
                      },
                    ]}
                    noStyle
                  >
                    <Input style={{ width: '47%' }} placeholder="Value" title="Value" />
                  </Form.Item>

                  {fields.length >= 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      style={{
                        margin: '9px 0 0 8px',
                        float: 'right',
                        position: 'absolute',
                        zIndex: '1 !important',
                      }}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  ) : null}
                </Input.Group>
              </Form.Item>
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              className="mt-2"
              onClick={() => {
                add();
              }}
            >
              <PlusOutlined /> ADD MORE
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
}

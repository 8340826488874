import { Skeleton, Typography } from 'antd';
import styles from './apiUsages.module.scss';
import Upgrades from './Upgrades';
import ProgressBars from './ProgressBars';
import { GetLimitAndUsageQuery } from 'generated/graphql';

const { Title, Text } = Typography;

type APIUsagesProps = {
  limitAndUsageLoading: boolean;
  projectUsages: GetLimitAndUsageQuery;
};

const APIUsages: React.FC<APIUsagesProps> = ({ projectUsages, limitAndUsageLoading }) => {
  return (
    <div className={styles.projectUsagesContainer}>
      {limitAndUsageLoading ? <Skeleton active /> : <ProgressBars projectUsages={projectUsages} />}
      <div className="mt-6">
        <Title level={4} className="globalTitle">
          <Text>Project Upgrades</Text>
        </Title>
        <Upgrades />
      </div>
    </div>
  );
};

export default APIUsages;

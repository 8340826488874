import { AuthProvider } from 'hooks/useAuth';

import ApitoRoutes from 'ApitoRoutes';

import './App.scss';
require('antd.less');

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <ApitoRoutes />
      </AuthProvider>
    </div>
  );
};

export default App;

import { Skeleton, Tabs } from 'antd';
import MainLayout from 'components/layouts/MainLayout';
import { Plugin_System_Type_Enum, useGetPluginsLazyQuery } from 'generated/graphql';
import Plugins from './Plugin';
import { useEffect, useState } from 'react';

const PluginsContainer = () => {
  const [fetchMore, { data, loading }] = useGetPluginsLazyQuery({
    variables: {
      system_type: Plugin_System_Type_Enum.Local,
    },
  });

  const _items = [
    {
      label: 'Local',
      key: Plugin_System_Type_Enum.Local,
      children: loading ? (
        <Skeleton active />
      ) : (
        <Plugins data={data || {}} pluginType={Plugin_System_Type_Enum.Local} />
      ),
    }, // remember to pass the key prop
    {
      label: 'Third Party',
      key: Plugin_System_Type_Enum.ThirdParty,
      children: loading ? (
        <Skeleton active />
      ) : (
        <Plugins data={data || {}} pluginType={Plugin_System_Type_Enum.ThirdParty} />
      ),
    },
  ];

  const [items, setItems] = useState(_items);
  const [activeTab, setActiveTab] = useState(Plugin_System_Type_Enum.Local);

  useEffect(() => {
    switch (activeTab) {
      case Plugin_System_Type_Enum.Local:
        fetchMore({
          variables: {
            system_type: Plugin_System_Type_Enum.Local,
          },
        });
        break;
      case Plugin_System_Type_Enum.ThirdParty:
        fetchMore({
          variables: {
            system_type: Plugin_System_Type_Enum.ThirdParty,
          },
        });
        break;
    }

    let newItems = items.filter(function (item, index) {
      if (item.key === activeTab) {
        item.children = loading ? (
          <Skeleton active />
        ) : (
          <Plugins data={data || {}} pluginType={activeTab} />
        );
      }
      return item;
    }) as [];
    setItems(newItems);
  }, [data, activeTab]);

  const onChange = async (key: string) => {
    if (key === Plugin_System_Type_Enum.Local) {
      await setActiveTab(Plugin_System_Type_Enum.Local);
    } else if (key === Plugin_System_Type_Enum.ThirdParty) {
      await setActiveTab(Plugin_System_Type_Enum.ThirdParty);
    }
  };

  return (
    <MainLayout
      title="Plugins"
      subtitle="Easily Extend The System by Plugin"
      breadcrumList={['Setting', 'Plugins']}
    >
      <Tabs type={'card'} activeKey={activeTab} items={items} onChange={onChange} />
    </MainLayout>
  );
};

export default PluginsContainer;

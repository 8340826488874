import { Checkbox, Form, Space } from 'antd';
import { capitalize } from 'lodash';
import CheckboxTooltip from '../../../../../../components/wrapper/CheckboxTooltip';
import styles from '../fieldConfigDrawerComponent.module.scss';
import { Field_Type_Enum } from 'generated/graphql';
import { FieldArrayProps } from 'types/model';

const CheckboxList = ({ field_type, field_sub_type }: FieldArrayProps): any => {
  const CheckboxList = ['required', 'hide', 'is_multi_choice', 'is_gallery', 'is_url'];
  const _replace = (str) => str.replace(/_/g, ' ');

  return CheckboxList.map((item, i) => {
    if (item === 'as_title' && field_type === Field_Type_Enum.Text) {
      return (
        <Form.Item
          className={styles.checkbox}
          name={['validation', `${item}`]}
          valuePropName="checked"
        >
          <Space>
            <Checkbox>Show In Preview</Checkbox>
            <CheckboxTooltip field={item} key={i} />
          </Space>
        </Form.Item>
      );
    }
    if (item === 'is_multi_choice' && field_sub_type === 'multiSelect') {
      return (
        <Form.Item className={styles.checkbox} name={['validation', `${item}`]} initialValue>
          <Space>
            <Checkbox>{capitalize(_replace(item))}</Checkbox>
            <CheckboxTooltip field={item} style={{ display: 'none' }} key={i} />
          </Space>
        </Form.Item>
      );
    }
    if (item === 'required') {
      return (
        <Form.Item
          className={styles.checkbox}
          name={['validation', `${item}`]}
          valuePropName="checked"
        >
          <Space>
            <Checkbox>{capitalize(_replace(item))}</Checkbox>
            <CheckboxTooltip field={item} key={i} />
          </Space>
        </Form.Item>
      );
    }
    if (item === 'hide' && field_type !== Field_Type_Enum.Media) {
      return (
        <Form.Item
          className={styles.checkbox}
          name={['validation', `${item}`]}
          valuePropName="checked"
        >
          <Space>
            <Checkbox>{capitalize(_replace(item))}</Checkbox>
            <CheckboxTooltip field={item} key={i} />
          </Space>
        </Form.Item>
      );
    }
    if (
      item === 'unique' &&
      field_type !== Field_Type_Enum.Media &&
      field_type !== Field_Type_Enum.List &&
      field_type !== Field_Type_Enum.Boolean &&
      field_type !== Field_Type_Enum.Geo &&
      field_type !== Field_Type_Enum.Repeated
    ) {
      return (
        <Form.Item
          className={styles.checkbox}
          name={['validation', `${item}`]}
          valuePropName="checked"
        >
          <Space>
            <Checkbox>{capitalize(_replace(item))}</Checkbox>
            <CheckboxTooltip field={item} key={i} />
          </Space>
        </Form.Item>
      );
    }
    if (item === 'is_gallery' && field_type === Field_Type_Enum.Media) {
      return (
        <Form.Item
          className={styles.checkbox}
          name={['validation', `${item}`]}
          valuePropName="checked"
        >
          <Space>
            <Checkbox>Allow Multiple Media Upload</Checkbox>
            <CheckboxTooltip field={item} key={i} />
          </Space>
        </Form.Item>
      );
    }
    if (item === 'is_url' && field_type === Field_Type_Enum.Media) {
      return (
        <Form.Item
          className={styles.checkbox}
          name={['validation', `${item}`]}
          valuePropName="checked"
        >
          <Space>
            <Checkbox>Allow Media URL</Checkbox>
            <CheckboxTooltip key={i} field={item} />
          </Space>
        </Form.Item>
      );
    }
    return <div></div>;
  });
};

export default CheckboxList;

import { Card } from 'antd';
import styles from './cards.module.scss';
import { ReactNode } from 'react';

const { Meta } = Card;

type AddonCardProps = {
  children: ReactNode;
  hasOption?: boolean;
  icon: JSX.Element;
  title: string;
  description: string;
  className?: string;
};

const AddonCard: React.FC<AddonCardProps> = ({
  hasOption,
  icon,
  title,
  description,
  className,
  children,
}) => {
  return (
    <Card
      hoverable
      className={`${styles.addonCard} ${className}`}
      //   extra={hasOption ? getDropDownMenu(item) : null}
      headStyle={{ border: 'none', position: 'absolute', width: '100%' }}
      cover={<div className={styles.cardAvater}> {icon}</div>}
    >
      <div className={styles.metaWrapper}>
        <Meta title={<span>{title}</span>} description={<span>{description}</span>} />
        {children}
      </div>
    </Card>
  );
};

export default AddonCard;

import { Badge, Button, Card, Carousel, Divider, PageHeader, Steps, Typography } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import styles from './startProject.module.scss';
import { useAuthContext } from 'hooks/useAuth';
import { errorMessage } from 'utils/message';
import { httpService } from 'utils/httpService';
import { PROJECT_CREATE, USER_SUB_CHECK } from 'configs';
import ProjectCreateForm, { ProjectCreateRequest } from 'components/forms/ProjectCreateForm';
import { merge } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ProjectComplete from './ProjectComplete';
import { useSwitchProject } from 'hooks/useProjectList';
import { client } from 'index';
import { DEMO_PROJECTS } from '../../../data/demo_projects';

const StartProject = () => {
  const { decodeTokenData } = useAuthContext();
  const tokenData = decodeTokenData();
  //const navigate = useNavigate();
  //const switchProject = useSwitchProject();

  const carousel = useRef(null);
  const [projectStep, setProjectStep] = useState(0);

  const [projectCreateData, setProjectCreateData] = useState<ProjectCreateRequest>(null);
  const [currentProcess, setCurrentProcess] = useState(0);
  const [createdProjectId, setCreatedProjectId] = useState('');

  //const [projectType, setProjectType] = useState(null);

  const httpConfig = {
    withCredentials: true,
  };

  const checkLimit = (templateId) => {
    httpService
      .post(
        USER_SUB_CHECK,
        {
          template: templateId,
          subscription_type: tokenData?.ust,
        },
        httpConfig
      )
      .then((res) => {
        //const _res = res?.data;
        //const ust = _res?.subscription_type;
        carousel.current.next();
        setProjectStep(1);
      })
      .catch((err) => {
        console.log('error', err);
        errorMessage(err);
      });
  };

  useEffect(() => {
    if (currentProcess > 0) {
      const timer = setTimeout(() => {
        if (currentProcess < 6) {
          setCurrentProcess((old) => old + 1);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [currentProcess]);

  // useEffect(() => {
  //   if (projectStep === 2 && projectCreateData?.id !== '') {
  //     createProject();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [projectStep]);

  // useEffect(() => {
  //   if (projectType !== null && projectStep === 0) {
  //     checkLimit(projectType);
  //   }
  // }, [projectStep, projectType]);

  return (
    <div className={styles.root}>
      <PageHeader title="New Project" subTitle="New journey starts here ...">
        <div
          style={{
            marginTop: '20px',
            marginBottom: '100px',
            minWidth: '550px',
          }}
        >
          <ProjectCreateForm />
        </div>
      </PageHeader>
    </div>
  );
};

export default StartProject;

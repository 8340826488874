import Addons from './Addons';
import { useGetSettingsAddonsQuery } from '../../../../generated/graphql';
import { Skeleton } from 'antd';
import { errorMessage } from '../../../../utils/message';
import React from 'react';
import MainLayout from '../../../../components/layouts/MainLayout';

const AddonsContainer = () => {
  const { loading, error, data } = useGetSettingsAddonsQuery();

  return (
    <MainLayout
      title="Addons"
      subtitle="Some You Enable, Some You Disable!"
      breadcrumList={['Setting', 'Addons']}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {error && errorMessage(error)}
          <Addons roles={data.currentProject.roles} data={data.currentProject.addons} />
        </>
      )}
    </MainLayout>
  );
};
export default AddonsContainer;

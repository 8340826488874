/* eslint-disable jsx-a11y/anchor-is-valid */
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Divider, Popover, Space, Tooltip } from 'antd';
import { useDeleteApiCredentialMutation } from 'generated/graphql';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { successMessage } from 'utils/message';
import { GET_CURRENT_PROJECT } from '../../../../graphql/queries/projects';

type ApiSecretsTablesDeleteProps = {
  text: { token: string; expire: boolean };
  deletedToken: string;
  setDeletedToken: Function;
};

const ApiSecretsTablesDelete: React.FC<ApiSecretsTablesDeleteProps> = ({
  text,
  deletedToken,
  setDeletedToken,
}) => {
  const [isPopOver, setIsPopOver] = React.useState(false);

  const updateCache = (cache) => {
    const existingData = cache.readQuery({
      query: GET_CURRENT_PROJECT,
    })?.currentProject?.tokens;

    if (existingData?.length > 0) {
      cache.writeQuery({
        query: GET_CURRENT_PROJECT,
        data: {
          currentProject: {
            tokens: [...existingData.filter((item) => item.token !== deletedToken)],
          },
        },
      });
      setDeletedToken('');
    }
  };

  const [deleteToken] = useDeleteApiCredentialMutation({
    onCompleted: (data) => {
      successMessage('Token Deleted Successfully!');
    },
    update: updateCache,
  });

  const onDeleteToken = async (token, duration) => {
    await setDeletedToken(token);
    deleteToken({
      variables: {
        token,
        duration,
      },
    });
  };

  const hidePopOver = (value) => {
    setIsPopOver(value);
  };
  return (
    <div
      style={{
        width: '36px',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 auto',
      }}
    >
      <CopyToClipboard text={text.token} onCopy={() => successMessage('Token Copied!')}>
        <Tooltip title="Copy Token">
          <CopyOutlined />
        </Tooltip>
      </CopyToClipboard>
      <Tooltip title="Delete Token">
        <Popover
          title="Are You Sure?"
          trigger="click"
          placement="left"
          open={isPopOver}
          onOpenChange={hidePopOver}
          content={
            <Space>
              <a
                onClick={() => {
                  setIsPopOver(false);
                }}
              >
                Close
              </a>
              <Divider />,
              <a onClick={() => onDeleteToken(text.token, text.expire)} className="ml-1">
                Yes
              </a>
            </Space>
          }
        >
          <DeleteOutlined
            onClick={() => {
              setIsPopOver(true);
            }}
          />
        </Popover>
      </Tooltip>
    </div>
  );
};

export default ApiSecretsTablesDelete;

import React, { useState } from 'react';
import { Button, Col, Drawer, Row } from 'antd';

import styles from './extensions.module.scss';
import PluginCard from 'components/cards/PluginCard';
import { GetPluginsQuery, Plugin_System_Type_Enum, PluginDetailsFields } from 'generated/graphql';
import PluginSettingsDrawer from './PluginSettingsDrawer';

const Plugins = ({
  data,
  pluginType,
}: {
  data: GetPluginsQuery;
  pluginType: Plugin_System_Type_Enum;
}) => {
  const [visible, setVisible] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [plugin, setPlugin] = useState(null);

  const showDrawer = (plugin: PluginDetailsFields) => {
    setDrawerTitle(plugin.title);
    setVisible(true);
    setPlugin(plugin);
  };

  return (
    <>
      <div className={styles.extensionContainer}>
        <Row gutter={[16, 16]}>
          {data?.getPlugins?.map((plugin, index) => (
            <Col sm={8} key={index}>
              <PluginCard plugin={plugin} pluginType={pluginType} showDrawer={showDrawer} />
            </Col>
          ))}
        </Row>
      </div>

      <Drawer
        title={drawerTitle}
        placement="right"
        width={320}
        closable
        onClose={() => {
          setVisible(false);
          setPlugin(null);
        }}
        extra={<Button type="link" onClick={() => setVisible(false)}>{`< Back`}</Button>}
        visible={visible}
      >
        <PluginSettingsDrawer plugin={plugin} pluginType={pluginType} />
      </Drawer>
    </>
  );
};

export default Plugins;

import { Layout } from 'antd';
import ModelSidebar from 'components/sidebars/ModelSidebar';

import styles from './layouts.module.scss';
import { ReactNode } from 'react';

const { Sider, Content } = Layout;

type ModelsLayoutProps = {
  children: ReactNode;
  setIsCreate: (val: boolean) => void;
};

const ModelsLayout: React.FC<ModelsLayoutProps> = ({ setIsCreate, children }) => {
  return (
    <Layout hasSider className={styles.layoutWrapper}>
      <Sider className={styles.sidebar}>
        <ModelSidebar setIsCreate={setIsCreate} />
      </Sider>
      <div className={styles.layoutContent}>
        <Layout className={styles.layoutContentInner}>
          <Content>{children}</Content>
        </Layout>
      </div>
    </Layout>
  );
};

export default ModelsLayout;

import AuthLayout from 'components/layouts/AuthLayout';
import Logo from 'components/Logo';
import styles from './styles.module.scss';
import { GitlabOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GITHUB_CALLBACK_URL } from '../../configs';
import { httpService } from '../../utils/httpService';

type RegistrationProps = {
  //
};

const GithubCallback: React.FC<RegistrationProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialRender = useRef(true);

  const fetchUserInfo = async () => {
    try {
      // Extract code and state from URL parameters
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');

      // Make a request to your backend with the code and state
      await httpService.get(`${GITHUB_CALLBACK_URL}?code=${code}&state=${state}`); // Handle the response as needed, e.g., store user info, redirect, etc.
      //console.log('User Info:', response.data);
      message.info('You have been successfully logged in.', 5);

      // Redirect to the home page or dashboard
      navigate('/projects');
      window.location.reload();
    } catch (error) {
      console.error('Error during OAuth callback processing:', error);
      // Handle error accordingly, maybe redirect to an error page or show a message
      navigate('/login');
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      fetchUserInfo();
    }
  }, [initialRender]);

  return (
    <AuthLayout>
      <div className={styles.rightColContentWrapper}>
        <div>
          <div className={styles.logo}>
            <Logo />
          </div>
          <h2>
            Create <span>API</span> instantly,
            <br /> Deliver <span>Projects</span> in time.
          </h2>
          <Button
            icon={<GitlabOutlined />}
            type={'default'}
            size="large"
            onClick={() => {
              window.location.href = 'http://localhost:5050/auth/v2/google/login';
            }}
            loading={true}
          >
            Login with Github ...
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default GithubCallback;

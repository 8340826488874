import { useEffect, useRef, useState } from 'react';
import { Alert, Divider, PageHeader } from 'antd';
import SubscriptionTable from './SubscriptionTable';
import { httpService } from 'utils/httpService';
import { BASE_URL, USER_SUB_CHECK, USER_SUB_FETCH } from 'configs';
import { errorMessage } from 'utils/message';
import PlanCard from '../../../components/cards/PlanCard';
import { useAuthContext } from '../../../hooks/useAuth';
import { PLANS_LIST } from '../../../constants/plansData';

const Subscription = (props) => {
  const { decodeTokenData } = useAuthContext();
  const tokenData = decodeTokenData();

  // @ts-ignore
  const { Paddle } = window;

  const [listSubscription, setListSubscription] = useState([]);
  const [isProjectFetching, setIsProjectFetching] = useState(false);

  const config = {
    withCredentials: true,
  };

  const fetchProjects = () => {
    setIsProjectFetching(true);
    httpService
      .post(USER_SUB_FETCH, {}, config)
      .then((res) => {
        const projects = res?.data?.body || [];
        setListSubscription(projects);
        setIsProjectFetching(false);
      })
      .catch((err) => {
        setIsProjectFetching(false);
        errorMessage(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const httpConfig = {
    withCredentials: true,
  };

  const onOpenModal = (plan) => {
    Paddle.Checkout.open({
      product: plan?.code,
      email: tokenData?.email,
      // passthrough: "{\"user_id\": "98765", \"project_id\": \"id\"}"
      passthrough: `{"user_id": "${tokenData.account}"}`,
    });
  };

  const checkPlan = (plan) => {
    httpService
      .post(
        USER_SUB_CHECK,
        {
          subscription_type: plan?.key,
          name: plan?.title,
          subscription_code: plan?.code.toString(),
          feature_promise: plan?.infoList,
        },
        httpConfig
      )
      .then((res) => {
        onOpenModal(plan);
      })
      .catch((err) => {
        console.log('error', err);
        errorMessage(err);
      });
  };

  return (
    <div>
      <PageHeader title="Subscription" subTitle="Payment History">
        {/*<div>
          <Alert message="Info Text" type="info" />
        </div>
        <Divider></Divider>*/}
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {PLANS_LIST.slice(0, 4).map((plan, i) => (
            <PlanCard
              item={plan}
              key={i}
              onPlanClicked={(plan) => {
                checkPlan(plan);
              }}
            />
          ))}
        </div>
        <Divider></Divider>
        <SubscriptionTable tableData={listSubscription} loading={isProjectFetching} />
      </PageHeader>
    </div>
  );
};

export default Subscription;

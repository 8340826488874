import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import MediaSidebar from 'components/sidebars/MediaSidebar';
import styles from './layouts.module.scss';
import { ReactNode } from 'react';
import useProjectStore from '../../store/projectStore';
import { ProjectModel } from '../../generated/graphql';

type MediaLayoutProps = {
  children: ReactNode;
  setFilterByModelOpt: (val: string) => void;
};

const MediaLayout: React.FC<MediaLayoutProps> = ({ children, setFilterByModelOpt }) => {
  return (
    <Layout hasSider className={styles.layoutWrapper}>
      <Sider className={styles.mediaSidebar}>
        <MediaSidebar setFilterByModelOpt={setFilterByModelOpt} />
      </Sider>
      <div className={styles.layoutContent}>
        <Layout className={styles.layoutContentInner} id="scrollableDiv">
          <Content>{children}</Content>
        </Layout>
      </div>
    </Layout>
  );
};

export default MediaLayout;

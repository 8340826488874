import React from 'react';
import { Table, Tag } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { GetSettingsTeamsRolesQuery } from 'generated/graphql';
import TablesDeleteButton from './TablesDeleteButton';
import { capitalize } from 'lodash';

type RolesTableProps = {
  rolesObj?: Record<string, never>;
  onEditRole?: any;
  rolesData: GetSettingsTeamsRolesQuery;
};

const RolesTable: React.FC<RolesTableProps> = ({ onEditRole, rolesData }) => {
  const rolesObj = rolesData?.currentProject?.roles || [];
  const rolesKey = Object.keys(rolesObj);
  const rolesArr =
    rolesKey?.length > 0
      ? rolesKey.map((item, i) => ({
          name: item,
          identifier: item,
          no_of_users: rolesObj?.[`${item}`]?.no_of_users || 0,
          system_generated: rolesObj?.[`${item}`]?.system_generated ? 'Yes' : 'No',
        }))
      : [];

  const tableColumns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name', // unique for each column (ignore if dataIndex is unique)
      render: (text) => <span>{capitalize(text || '')}</span>,
    },
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      key: 'identifier', // unique for each column (ignore if dataIndex is unique)
      render: (text) => (
        <span>
          <Tag>{text}</Tag>
        </span>
      ),
    },
    {
      title: 'System Generated',
      dataIndex: 'system_generated',
      key: 'sg',
      align: 'center',
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: (text) => <TablesDeleteButton text={text} onEditRole={onEditRole} />,
    },
  ];

  return <Table dataSource={rolesArr} columns={tableColumns} bordered rowKey="name" size="small" />;
};

export default RolesTable;

import { Descriptions } from 'antd';
import { ContentContext } from 'context/ContentContext';
import { capitalize } from 'lodash';
import Contents from 'pages/Console/Contents/Contents';
import { useContext } from 'react';
import EditAndCreateContentForm from './EditAndCreateContentForm';
import EditRelationForm from './EditRelationForm';
import MainLayout from '../../../components/layouts/MainLayout';
import { useLoaderData } from 'react-router-dom';

const ContentsContainer = () => {
  const { state } = useContext(ContentContext);

  /*  let team = useLoaderData();

  console.log('team', team);*/

  return (
    <>
      {state.single_page ? (
        <MainLayout
          title={<span style={{ textTransform: 'capitalize' }}>{state?.target}</span>}
          subtitle={'single data model'}
          breadcrumList={['Content']}
        >
          <div style={{ paddingTop: 15, overflow: 'auto' }}>
            {state.has_connections ? (
              <Descriptions size="small" column={1} layout="vertical" bordered>
                <Descriptions.Item label={`${capitalize(state.target)} Relations`}>
                  <EditRelationForm
                    passedContentData={{
                      model: state.target,
                      id: state.single_page_uuid,
                      single_page_data: true,
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
            ) : null}
            <EditAndCreateContentForm
              contentData={{
                model: state.target,
                id: state.single_page_uuid,
                single_page_data: true,
              }}
              formType="edit"
            />
          </div>
        </MainLayout>
      ) : (
        <Contents />
      )}
    </>
  );
};

export default ContentsContainer;

import { Skeleton } from 'antd';

export function TableSkeleton({ loading, rows, children, ...props }) {
  return (
    <Skeleton
      loading={loading}
      active
      title={false}
      paragraph={{ rows: rows || 5, width: '100%' }}
      {...props}
    >
      {children}
    </Skeleton>
  );
}

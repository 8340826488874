import { ProjectsProvider } from 'hooks/useProjectList';
import ProjectList from 'pages/projects/ProjectList/ProjectList';

const ProjectListContainer = () => {
  return (
    <ProjectsProvider>
      <ProjectList />
    </ProjectsProvider>
  );
};

export default ProjectListContainer;

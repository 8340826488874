import React from 'react';
import SingleProgressBar from './SingleProgressBar';
import styles from './apiUsages.module.scss';
import { GetLimitAndUsageQuery } from 'generated/graphql';

type ProgressBarsProps = {
  projectUsages: GetLimitAndUsageQuery;
};

const ProgressBars: React.FC<ProgressBarsProps> = ({ projectUsages }) => {
  const progressArr = [
    {
      item: 'api calls',
      key: 'api_calls',
    },
    {
      item: 'api traffic',
      key: 'api_bandwidth',
      is_space: true,
    },
    {
      item: 'media storage',
      key: 'media_storage',
      is_space: true,
    },
    {
      item: 'media bandwidth',
      key: 'media_bandwidth',
      is_space: true,
    },
    {
      item: 'number of records',
      key: 'number_of_records',
    },
  ];

  return (
    <div className={styles.progressContainer}>
      {progressArr.map((item) => (
        <SingleProgressBar key={item.key} progressData={item} projectUsages={projectUsages} />
      ))}
    </div>
  );
};

export default ProgressBars;

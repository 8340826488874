import { Button, Drawer, Skeleton } from 'antd';
import MainLayout from 'components/layouts/MainLayout';
import { useGetSettingsWebhooksQuery } from 'generated/graphql';
import React from 'react';
import AddWebhooksDrawerComponent from './AddWebhookDrawerComponent';
import WebhooksTable from './WebHooksTable';

const WebhooksContainer = () => {
  const [isAddHookWrapper, setIsAddHookWrawer] = React.useState(false);
  const { data, loading } = useGetSettingsWebhooksQuery();

  return (
    <MainLayout
      title="Webhooks"
      subtitle="Trigger Things When Something Happens To Your Data..."
      breadcrumList={['Setting', 'Webhooks']}
      extra={[
        <Button
          type="primary"
          key={1}
          onClick={() => {
            setIsAddHookWrawer(true);
          }}
        >
          ADD WEBHOOK
        </Button>,
      ]}
    >
      {loading ? <Skeleton active /> : <WebhooksTable hookList={data?.listWebHooks || []} />}
      <Drawer // Field Configuration  - Drawer
        title="Add a Webhook Event"
        width={440}
        closable
        extra={<Button type="link" onClick={() => setIsAddHookWrawer(false)}>{`< Back`}</Button>}
        onClose={() => {
          setIsAddHookWrawer(false);
        }}
        visible={isAddHookWrapper}
        destroyOnClose
      >
        <AddWebhooksDrawerComponent />
      </Drawer>
    </MainLayout>
  );
};

export default WebhooksContainer;

import { Empty } from 'antd';

export function SelectContent() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Empty
        image="/assets/content/svg/select.svg"
        imageStyle={{
          height: 60,
        }}
        description={<span className="description">Please select a content from the list.</span>}
      />

      <style>
        {`
          .description {
            font-family: Raleway;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
          }
        `}
      </style>
    </div>
  );
}

import {
  ApiOutlined,
  DatabaseOutlined,
  KeyOutlined,
  LockOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Alert, Form, Input, Select } from 'antd';
import styles from './form.module.scss';
import { ProjectModel } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { DBLIST } from 'data/dbList';
import { DEMO_PROJECTS } from 'data/demo_projects';

const { Option } = Select;

type ProjectTemplateFormProps = {
  projectData?: ProjectModel;
  setProjectTemplate: (template: string) => void;
};

const ProjectTemplateForm = ({ projectData, setProjectTemplate }: ProjectTemplateFormProps) => {
  return (
    <>
      <Form.Item
        name={['project_template']}
        label="Project Template"
        // rules={[
        //   {
        //     required: true,
        //     type: 'string',
        //   },
        // ]}
      >
        <Select
          className={styles.select}
          placeholder="Select A Template"
          onChange={(value) => {
            setProjectTemplate(value);
          }}
          defaultValue={projectData?.project_template || ''}
        >
          {DEMO_PROJECTS.map((db) => (
            <Option value={db.id} key={db.key}>
              {db.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
export default ProjectTemplateForm;

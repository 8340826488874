import { useEffect, useState } from 'react';
import { Button, Input, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography;

type DeleteConfirmModalProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  handleModelConfirm?: Function;
  loader: boolean;
  confirmText?: string;
};

const DeleteConfirmModal = ({
  isVisible,
  setIsVisible,
  handleModelConfirm,
  loader,
  confirmText = 'DELETE',
}: DeleteConfirmModalProps) => {
  const [inpStr, setInpStr] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (inpStr) {
      errorCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inpStr]);

  const errorCheck = () => {
    if (inpStr !== confirmText) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const hideModal = () => {
    setInpStr('');
    setError(false);
    setIsVisible(false);
  };

  const OnDelete = () => {
    if (inpStr === confirmText) {
      handleModelConfirm('delete');
    } else {
      errorCheck();
    }
  };

  function ModalFooter() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0 8px',
        }}
      >
        <Button
          icon={loader ? <LoadingOutlined spin /> : ''}
          onClick={() => {
            return !loader ? OnDelete() : null;
          }}
          type="primary"
        >
          YES DO IT
        </Button>
        <Button onClick={hideModal}>CANCEL</Button>
      </div>
    );
  }

  return (
    <Modal
      title={
        <span style={{ textAlign: 'center' }}>
          <ExclamationCircleOutlined /> Are You Sure?
        </span>
      }
      open={isVisible}
      onCancel={hideModal}
      footer={<ModalFooter />}
    >
      <div>
        <Text>
          Write <b>{confirmText}</b> in the box to confirm!
        </Text>
        <Input
          style={{ margin: '5px 0' }}
          value={inpStr}
          allowClear={true}
          onChange={(e) => setInpStr(e.target.value)}
        />
        {error && inpStr !== '' ? (
          <Text type="danger">You need to type '{confirmText}' in the box to delete the model</Text>
        ) : null}
      </div>
    </Modal>
  );
};

export default DeleteConfirmModal;

import { Checkbox } from 'antd';
import { useGetAllModelsQuery } from 'generated/graphql';
import { capitalize } from 'lodash';
import styles from './sidebars.module.scss';

type MediaSidebarProps = {
  setFilterByModelOpt: (val: string) => void;
};

const MediaSidebar = ({ setFilterByModelOpt }: MediaSidebarProps) => {
  const { data } = useGetAllModelsQuery();
  const models = data?.projectModelsInfo?.filter((item) => !item.system_generated) || [];

  const filterByType = [
    {
      value: 'image',
      name: 'image',
    },
    {
      value: 'document',
      name: 'document',
    },
    {
      value: 'private_image',
      name: 'show private image',
    },
  ];

  const onModelFilterChange = (checkedValues) => {
    // console.log('Log: onModelFilterChange -> checkedValues', checkedValues)
    setFilterByModelOpt(checkedValues);
  };

  const renderFilterByModel = () => {
    const checkboxOptions =
      models?.length > 0
        ? models.map((item) => ({
            label: capitalize(item?.name ?? ''),
            value: item?.name ?? '',
          }))
        : [];

    return (
      <Checkbox.Group
        className={styles.checkboxGrp}
        options={checkboxOptions}
        onChange={onModelFilterChange}
      />
    );
  };

  const renderFilterByType = () =>
    filterByType.map((item, i) => (
      <Checkbox key={i} defaultChecked={!!item.value} className={styles.checkbox}>
        {capitalize(item.name)}
      </Checkbox>
    ));

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.menuGroup}>
        <p className={styles.groupTitle}>Media - Filter</p>
        <div className={styles.checkboxWrapper}>{renderFilterByType()}</div>
        <p className={styles.groupTitle}>By Model</p>
        <div className={styles.checkboxWrapper}>{renderFilterByModel()}</div>
      </div>
    </div>
  );
};

export default MediaSidebar;

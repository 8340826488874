import MainLayout from 'components/layouts/MainLayout';
import { useGetSettingsTeamsRolesQuery } from 'generated/graphql';
import ApiSecrets from './ApiSecrets';
import { Skeleton } from 'antd';

const ApiSecretsContainer = () => {
  const { loading, data } = useGetSettingsTeamsRolesQuery();

  return (
    <MainLayout
      title="API Secrets"
      subtitle="Ability to Export Your API to the Outside World!"
      breadcrumList={['Setting', 'Webhooks']}
    >
      {' '}
      {loading ? <Skeleton active /> : <ApiSecrets roles={data} />}
    </MainLayout>
  );
};

export default ApiSecretsContainer;

import { Button, Form, Input, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import pluralize from 'pluralize';
import { useState } from 'react';

const { Text } = Typography;

type DynamicMediaURLFormListProps = {
  field_item: { validation: { is_gallery: boolean }; identifier: string; label: string };
};

export function DynamicMediaURLFormList({ field_item, ...props }: DynamicMediaURLFormListProps) {
  const { validation } = field_item;
  const is_gallery = validation?.is_gallery || false;

  // formList
  const [photos, setPhotos] = useState([]);

  function _delete(array, index) {
    // (1)
    return array.filter((_item) => _item.index !== index);
  }

  function upsert(array, item) {
    // (1)
    const i = array.findIndex((_item) => _item.index === item.index);
    if (i > -1) {
      array[i] = item;
    } else {
      array.push(item);
    }
  }

  const handleImagePreview = (index, val) => {
    const url = val.target.defaultValue;
    if (url === '' || url.endsWith('.png') || url.endsWith('.jpg') || url.endsWith('.jpeg')) {
      const name = url.split('/');
      const fileName = name[name.length - 1];
      const newPhotos = photos.slice();
      upsert(newPhotos, {
        index,
        url,
        file_name: fileName,
      });
      setPhotos(newPhotos);
    }
  };

  console.log('photos', photos);

  return (
    <div>
      <Form.List name={[`${field_item.identifier}`]} {...props}>
        {/* formList name must be same as parent to catch initial value */}
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => (
              <Form.Item
                required={false}
                key={field.name}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                }}
                {...field}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: `Please input ${field_item.label} or delete this field.`,
                  },
                ]}
                noStyle
              >
                <Input
                  allowClear
                  placeholder="Media URL"
                  onPaste={(val) => handleImagePreview(index, val)}
                  onChange={(val) => handleImagePreview(index, val)}
                  suffix={
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                        const _photos = photos.slice();
                        _delete(_photos, index);
                        setPhotos(_photos);
                      }}
                    />
                  }
                />
              </Form.Item>
            ))}
            {!is_gallery && photos.length === 1 ? null : (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{ width: '100%' }}
                >
                  <PlusOutlined />
                  {`Add ${pluralize(`${field_item.label}`, 1)} URL`}
                </Button>
              </Form.Item>
            )}
          </div>
        )}
      </Form.List>
      <div>
        {photos.length}
        {photos.map((e) => (
          <Text>{e?.url}</Text>
        ))}
      </div>
    </div>
  );
}

import { useAuthContext } from 'hooks/useAuth';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Varification from './Varification';

const VarificationContainer = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const email = window.localStorage.getItem('verifyEmail') || false;
    if (!email) {
      navigate('/verify');
    }
  }, [navigate]);

  if (auth.isAuthenticated()) return <Navigate to={auth.navigateFrom} />;

  return <Varification />;
};

export default VarificationContainer;

import { Button, Card, Form, Input, InputNumber } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import pluralize from 'pluralize';
import { fakeDataList } from './fakeDataList';
import { capitalize } from 'lodash';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { generateLabel } from 'utils/GenerateLabelAndIcon';

dayjs.extend(relativeTime);

export function RepeatedFakerFieldsGenerator({ field_item, ...props }) {
  const FieldsArray = field_item?.sub_field_info ?? [];

  const getValidationRules = (item) => {
    const required = item?.validation?.required && !item?.validation?.is_password;
    return [
      {
        required,
      },
    ];
  };

  // const SingleField = (fieldItem) => {
  //   const item = fieldItem?.value ?? {};
  //   return renderFormItem(item, item?.identifier);
  // };

  const renderFormItems = (FieldsArray, ListIndex, field) =>
    // console.log('Log: renderFormItems -> field', field)
    FieldsArray.map((item, i) => {
      if (item?.field_type === 'multiline') {
        return (
          <Form.Item
            key={i}
            name={[ListIndex, `${item.identifier}`, 'html']}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      if (item?.field_type === 'geo') {
        return (
          <Form.Item
            key={i}
            name={[ListIndex, `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      return (
        <Form.Item
          key={i}
          name={[ListIndex, `${item.identifier}`]}
          label={generateLabel(item)}
          tooltip={item.description}
          rules={getValidationRules(item)}
        >
          {getFormField(item)}
        </Form.Item>
      );
    });
  const getFormField = (field_item) => {
    const field_Type = field_item?.field_type;

    switch (field_Type) {
      case 'text':
        if (field_item?.system_generated) {
          return <Input addonBefore="UUID" title="some" value={uuidv4()} disabled />;
        }
        return <FixedListSelector list={fakeDataList.text} />;

      case 'multiline':
        return <FixedListSelector list={fakeDataList.multiline} />;
      case 'date':
        return <FixedListSelector list={fakeDataList.date} />;
      case 'number':
        return <FixedListSelector list={fakeDataList.number} />;
      case 'boolean':
        return <FixedListSelector list={fakeDataList.boolean} />;
      case 'media':
        return <FixedListSelector list={fakeDataList.media} />;
      case 'geo':
        return <FixedListSelector list={fakeDataList.geo} />;
      case 'list':
        const list = field_item?.validation?.fixed_list_elements || [];
        const multiChoice = field_item?.validation?.is_multi_choice;

        if (list.length > 0 && !multiChoice) {
          return <FixedListSelector list={fakeDataList.random} />;
        }
        if (list.length > 0 && multiChoice) {
          return <FixedListSelector list={fakeDataList.random} />;
        }
        if (list.length === 0 && !multiChoice) {
          return <FixedListSelector list={fakeDataList.text} />;
        }
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <Form.List name={['form', `${field_item.identifier}`]} {...props}>
        {/* formList name must be same as parent to catch initial value */}
        {(fields, { add, remove }) => (
          <div style={{ marginTop: 10 }}>
            {fields.map((field, index) => (
              <Card
                title={`${capitalize(field_item.identifier)} Section #${index + 1}`}
                extra={
                  <div>
                    {fields.length >= 1 ? (
                      <Button
                        type="primary"
                        danger
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                      >
                        Remove Field
                      </Button>
                    ) : null}
                  </div>
                }
                style={{ marginBottom: '15px' }}
              >
                <div key={field.key}>
                  <Form.Item
                    key={100}
                    name={[index, `number_of_records`]}
                    label="Number Of Repeats"
                    rules={[
                      {
                        required: true,
                        message: 'Number of Records Field is required',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                  {renderFormItems(FieldsArray, index, field)}
                </div>
              </Card>
            ))}
            {fields.length < 1 ? (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{ width: '100%' }}
                >
                  <PlusOutlined /> Add {pluralize(`${field_item.label}`, 1)}
                </Button>
              </Form.Item>
            ) : null}
          </div>
        )}
      </Form.List>
    </div>
  );
}

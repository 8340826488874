export const PLANS_LIST = [
  {
    key: 2,
    isPlan: true,
    title: 'Free',
    subtitle:
      'Freelancers, hobbyists, small businesses, and startups with limited budgets or small-scale projects.',
    infoList: [
      '1 Single Project',
      '50K API Calls / Month',
      '200 MB Media CDN',
      '2,000 Records',
      '1 Seat',
      'GraphQL Server/API',
      'RESTful API',
      'Content Management API',
    ],
    previousPrice: 0,
    price: 0,
    btnLabel: 'Upgrade to Basic',
    code: 651642,
  },
  {
    key: 3,
    isPlan: true,
    title: 'Content First',
    subtitle:
      'Content creators, bloggers, and small businesses looking for a content-first solution.',
    infoList: [
      'Managed Database',
      '3M API Calls/Month',
      '10GB Media CDN',
      '20,000 Records',
      '5 Team Members',
      'Content Revision History',
      'Save Content as Draft',
      'GraphQL Server/API',
      'RESTful API',
      'Content Management API',
      'Support via Email',
    ],
    previousPrice: 89.99,
    price: 69.99,
    btnLabel: 'Upgrade',
    code: 651642,
  },
  {
    key: 4,
    isPlan: true,
    title: 'API First',
    subtitle: 'Developers, Agencies, Startups and Businesses looking for an API-first solution.',
    infoList: [
      'Ability to Self Host on Demand',
      'Self Hosted Database Connection Support',
      'Kubernetes Deployment Support',
      'CI & CD Repository Setup Support',
      'Unlimited API Calls',
      'Unlimited Media CDN',
      'Unlimited Records',
      'GraphQL API',
      'REST API',
      'Content Revision History',
      'Save Content as Draft',
      'Database Backup & Restore Setup',
      'Updates on Bug Fixes',
      'Support via Email & Discord',
    ],
    previousPrice: 249.99,
    price: 219.99,
    btnLabel: 'Upgrade',
    code: 882649,
  },
  {
    key: 5,
    isPlan: true,
    title: 'Enterprise Solution',
    subtitle: 'Large enterprises or organizations with complex needs.',
    infoList: [
      'Highly Performer API with millisecond response guarantee',
      'Each Project has its own Database',
      'Over 14 Database Driver to Choose from',
      'Option to Scale Individual Project API & Database',
      'Highly Available',
      'Managed Database on Demand',
      'Option to Choose Datacenter',
      'Dedicated Instance Setup on Demand',
      'Unlimited API Calls /Month',
      'Unlimited Media CDN',
      'Unlimited Records',
      'GraphQL API',
      'RESTful API',
      'Content Revision History',
      'Save Content as Draft',
      'Database Backup & Restore',
      'Project Import & Export',
      'Support via Email & Call & Discord',
    ],
    previousPrice: 899.9,
    price: 899.98,
    btnLabel: `Let's Talk`,
    code: 882649,
  },
];

export const OLD_PLANS_LIST = [
  {
    key: 2,
    isPlan: true,
    title: 'Free',
    subtitle:
      'Freelancers, hobbyists, small businesses, and startups with limited budgets or small-scale projects.',
    infoList: [
      '1 Single Project',
      '50K API Calls / Month',
      '200 MB Media CDN',
      '2,000 Records',
      '1 Seat',
      'GraphQL Server/API',
      'RESTful API',
      'Content Management API',
    ],
    previousPrice: 0,
    price: 0,
    btnLabel: 'Upgrade to Basic',
    code: 651642,
  },
  {
    key: 3,
    isPlan: true,
    title: 'Basic',
    subtitle: 'Small businesses or startups looking for an entry-level solution.',
    infoList: [
      'Managed Database',
      '3M API Calls/Month',
      '10GB Media CDN',
      '20,000 Records',
      '5 Team Members',
      'Content Revision History',
      'Save Content as Draft',
      'GraphQL Server/API',
      'RESTful API',
      'Content Management API',
      'Support via Email',
    ],
    previousPrice: 89.99,
    price: 69.99,
    btnLabel: 'Upgrade',
    code: 651642,
  },
  {
    key: 4,
    isPlan: true,
    title: 'Professional',
    subtitle: 'Medium-sized businesses requiring advanced features.',
    infoList: [
      'Ability to Self Host on Demand',
      'Self Hosted Database Connection Support',
      'Kubernetes Deployment Support',
      'CI & CD Repository Setup Support',
      'Unlimited API Calls',
      'Unlimited Media CDN',
      'Unlimited Records',
      'GraphQL API',
      'REST API',
      'Content Revision History',
      'Save Content as Draft',
      'Database Backup & Restore Setup',
      'Updates on Bug Fixes',
      'Support via Email & Discord',
    ],
    previousPrice: 249.99,
    price: 219.99,
    btnLabel: 'Upgrade',
    code: 882649,
  },
  {
    key: 5,
    isPlan: true,
    title: 'Enterprise Solution',
    subtitle: 'Large enterprises or organizations with complex needs.',
    infoList: [
      'Highly Performer API with millisecond response guarantee',
      'Each Project has its own Database',
      'Over 14 Database Driver to Choose from',
      'Option to Scale Individual Project API & Database',
      'Highly Available',
      'Managed Database on Demand',
      'Option to Choose Datacenter',
      'Dedicated Instance Setup on Demand',
      'Unlimited API Calls /Month',
      'Unlimited Media CDN',
      'Unlimited Records',
      'GraphQL API',
      'RESTful API',
      'Content Revision History',
      'Save Content as Draft',
      'Database Backup & Restore',
      'Project Import & Export',
      'Support via Email & Call & Discord',
    ],
    previousPrice: 899.9,
    price: 899.98,
    btnLabel: `Let's Talk`,
    code: 882649,
  },
];

import { useState } from 'react';
import { Table, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { useDeleteSettingsWeekhookMutation } from 'generated/graphql';
import { successMessage } from 'utils/message';

import Loader from 'components/loader/Loader';
import { GET_SETTINGS_WEBHOOKS } from '../../../../graphql/queries/settings';

const WebhooksTable = ({ hookList }) => {
  const [deletedHookID, setDeletedHookID] = useState('');

  const cacheUpdate = (cache, { data }) => {
    const existingHooks = cache.readQuery({
      query: GET_SETTINGS_WEBHOOKS,
    })?.listWebHooks;

    cache.writeQuery({
      query: GET_SETTINGS_WEBHOOKS,
      data: {
        listWebHooks: [...existingHooks.filter((hook) => hook.id !== deletedHookID)],
      },
    });
  };

  const [deleteHook, { loading }] = useDeleteSettingsWeekhookMutation({
    onCompleted: (data) => {
      successMessage('Webhook Deleted successfully!');
    },
    update: cacheUpdate,
  });

  const onHookDelete = async (id) => {
    await setDeletedHookID(id);
    await deleteHook({
      variables: {
        id,
      },
    });
  };

  const tableColumns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name', // unique for eatch column (ignore if dataIndex is unique)
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Operations',
      dataIndex: 'events',
      key: 'events',
      align: 'center',
      render: (events) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          {events?.length > 0
            ? events.map((event, i) => {
                switch (event) {
                  case 'create':
                    return (
                      <div key={i} className="event" style={{ backgroundColor: '#086EA7' }}>
                        {event}
                      </div>
                    );
                  case 'update':
                    return (
                      <div key={i} className="event" style={{ backgroundColor: '#E73A55' }}>
                        {event}
                      </div>
                    );
                  case 'delete':
                    return (
                      <div key={i} className="event" style={{ backgroundColor: '#F68536' }}>
                        {event}
                      </div>
                    );
                  default:
                    break;
                }
                return '';
              })
            : ''}

          <style>
            {`
              .event {
                color: #fff;
                padding: 4px 12px;
                margin: 0 4px;
                border-radius: 5px;
                text-align: center;
              }
            `}
          </style>
        </div>
      ),
    },
    {
      title: 'Webhook URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: (text) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Tooltip title="Delete Hook">
              <DeleteOutlined onClick={() => onHookDelete(text.id)} />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={hookList}
        columns={tableColumns}
        // pagination={true}
        bordered
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default WebhooksTable;

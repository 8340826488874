import { useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import {
  Alert,
  Checkbox,
  Descriptions,
  Empty,
  Form,
  Input,
  InputNumber,
  Layout,
  Space,
  Tag,
  Typography,
} from 'antd';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import styles from './editContentForm.module.scss';
import { capitalize } from 'lodash';
import { GetFormDataQuery, GetFormGenerationDataQuery, ModelType } from 'generated/graphql';
import { CustomDatePicker } from 'components/inputs/CustomDatePicker';
import { BooleanSelector } from 'components/inputs/BooleanSelector';
import { ImageUploaderModal } from 'components/inputs/ImageUploaderModal';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { RepeatedFieldsGenerator } from './RepeatedFieldsGenerator';
import { InputGeoField } from './InputGeoField';
import ReactDraft from 'utils/ReactDraft';
import { generateLabel } from 'utils/GenerateLabelAndIcon';
import { DynamicFormList } from 'components/groupedInput/DynamicFormList';

const localsData = require('data/setting/locals.json');

dayjs.extend(relativeTime);

const { Content } = Layout;

const { Text } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

type EditContentFormContainerProps = {
  localsArr: string[];
  local: string;
  formData: GetFormDataQuery['getSingleData'];
  modelInfo: ModelType;
  disableInput: false;
};

const RevisionContentFormContainer2 = ({
  localsArr,
  local,
  formData,
  modelInfo,
  disableInput,
}: EditContentFormContainerProps) => {
  const [form] = Form.useForm();

  const [formErrors, setFormErrors] = useState([]);

  const formDataKeys = Object.keys(formData?.data ?? {}) || [];

  const createdAt =
    formData?.meta !== null ? dayjs().from(dayjs(formData?.meta?.created_at), true) : null;
  const editedAt =
    formData?.meta !== null ? dayjs().from(dayjs(formData?.meta?.updated_at), true) : null;
  const createdBy =
    formData?.meta !== null
      ? formData?.meta?.created_by?.first_name
        ? formData?.meta?.created_by?.first_name
        : formData?.meta?.created_by?.email
      : null;
  const editedBy =
    formData?.meta !== null
      ? formData?.meta?.last_modified_by?.first_name
        ? formData?.meta?.last_modified_by?.first_name
        : formData?.meta?.last_modified_by?.email
      : null;
  const status = formData?.meta !== null ? formData?.meta?.status : 'New Document';

  const getValidationRules = (item) => {
    const required = item?.validation?.required && !item?.validation?.is_password;
    return [
      {
        required,
        message: `${capitalize(item.identifier)} is required`,
      },
    ];
  };

  const getFormField = (field_item) => {
    const field_Type = field_item?.field_type;
    switch (field_Type) {
      case 'text':
        return [
          <Input disabled={disableInput} />,
          <ReactDiffViewer
            oldValue={formData?.data[`${field_item.identifier}`]}
            newValue={'Barton Kunze'}
            splitView={true}
            compareMethod={DiffMethod.WORDS}
            hideLineNumbers={true}
          />,
        ];
      case 'multiline':
        return <ReactDraft disabled={disableInput} />;
      case 'date':
        return <CustomDatePicker disabled={disableInput} />;
      case 'number':
        return <InputNumber style={{ width: '100%' }} disabled={disableInput} />;
      case 'boolean':
        return <BooleanSelector disabled={disableInput} />;
      case 'media':
        return <ImageUploaderModal field_item={field_item} disabled={disableInput} />;
      case 'geo':
        return <InputGeoField field_item={field_item} disabled={disableInput} />;
      case 'repeated':
        return <RepeatedFieldsGenerator field_item={field_item} disabled={disableInput} />;
      case 'list': {
        const list = field_item?.validation?.fixed_list_elements || [];
        const multiChoice = field_item?.validation?.is_multi_choice;

        if (list.length > 0 && !multiChoice) {
          return <FixedListSelector list={list} disabled={disableInput} />;
        }
        if (list.length > 0 && multiChoice) {
          return <Checkbox.Group options={list} disabled={disableInput} />;
        }
        if (list.length === 0 && !multiChoice) {
          return <DynamicFormList field_item={field_item} />;
        }
        break;
      }
      default:
        break;
    }
  };

  const renderForm = (modelInfo) => {
    return modelInfo.fields.map((item, i) => {
      if (formDataKeys.includes(item.identifier)) {
        if (item?.field_type === 'multiline') {
          // has initial data
          return (
            <Form.Item
              key={i}
              initialValue={formData?.data[`${item.identifier}`]?.html ?? undefined}
              name={['form', `${item.identifier}`, 'html']}
              label={generateLabel(item)}
              tooltip={item.description}
              rules={getValidationRules(item)}
            >
              {getFormField(item)}
            </Form.Item>
          );
        }
        if (item?.field_type === 'text' && item?.validation?.is_password) {
          return (
            <Form.Item
              key={i}
              name={['form', `${item.identifier}`]}
              label={generateLabel(item)}
              tooltip={item.description}
              rules={getValidationRules(item)}
            >
              {getFormField(item)}
            </Form.Item>
          );
        }
        if (item?.field_type === 'geo') {
          const { lat, lon } = formData?.data[`${item.identifier}`] ?? {};
          const initData =
            lat || lon
              ? {
                  lat,
                  lon,
                }
              : undefined;
          return (
            <Form.Item
              key={i}
              initialValue={initData || undefined}
              name={['form', `${item.identifier}`]}
              label={generateLabel(item)}
              tooltip={item.description}
            >
              {getFormField(item)}
            </Form.Item>
          );
        }
        if (item?.field_type === 'repeated') {
          return (
            <Form.Item
              key={i}
              initialValue={formData?.data[`${item.identifier}`] ?? undefined}
              name={['form', `${item.identifier}`]}
              label={generateLabel(item)}
              tooltip={item.description}
            >
              {getFormField(item)}
            </Form.Item>
          );
        }

        return (
          <Form.Item
            key={i}
            initialValue={formData?.data[`${item.identifier}`] ?? undefined}
            name={['form', `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item)}
          </Form.Item>
        );
      } // has no initial data
      if (item?.field_type === 'multiline') {
        return (
          <Form.Item
            key={i}
            name={['form', `${item.identifier}`, 'html']}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      if (item?.field_type === 'geo') {
        return (
          <Form.Item
            key={i}
            name={['form', `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }

      // <div>{field_item?.validation?.locals.map(l => <Tag icon={l == local ? <CheckCircleOutlined/> : null} color={l == local ? '#e73a55' : 'default'}>{l}</Tag>)}</div>
      // <Radio.Group value={local} style={{display: 'flex'}}>{ field_item?.validation?.locals.map( l => <div><Radio value={l}>{l}</Radio></div>)}</Radio.Group>

      return (
        <Form.Item
          key={i}
          name={['form', `${item.identifier}`]}
          label={generateLabel(item)}
          rules={getValidationRules(item)}
          tooltip={item.description}
        >
          {getFormField(item)}
        </Form.Item>
      );
    });
  };

  if (modelInfo?.fields?.length === 0 || !modelInfo) return <Empty />;

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Descriptions size="small" bordered>
        <Descriptions.Item
          label={
            <div>
              <img
                src="/static/pages/console/content/blue_circle.svg"
                alt="p"
                className={styles.metaIcon}
              />
              <Text> Created By</Text>
            </div>
          }
          labelStyle={{ fontWeight: '700' }}
        >
          {createdBy !== null ? (
            <Space size={1} direction="vertical">
              <Text className={styles.metaTitle}>{createdBy || ''}</Text>
              <Text type="secondary" className={styles.metaSubtitle}>
                {createdAt} ago
              </Text>
            </Space>
          ) : (
            '-'
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <div>
              <img
                src="/static/pages/console/content/yellow_circle.svg"
                alt="p"
                className={styles.metaIcon}
              />
              <Text> Edited By</Text>
            </div>
          }
          labelStyle={{ fontWeight: '700' }}
        >
          <Space size={1} direction="vertical">
            {editedBy !== null ? (
              <>
                <Text className={styles.metaTitle}>{editedBy || ''}</Text>
                <Text type="secondary" className={styles.metaSubtitle}>
                  {editedAt} ago
                </Text>{' '}
              </>
            ) : (
              '-'
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Status" labelStyle={{ fontWeight: '700' }}>
          <Tag color={status === 'published' ? 'green' : 'yellow'}>{status}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Localization" labelStyle={{ fontWeight: '700' }}>
          {localsArr.map((local) => (
            <Tag>{capitalize(localsData?.[`${local}`] || local || '')}</Tag>
          ))}
        </Descriptions.Item>
        {/* <Descriptions.Item label='Last Published' labelStyle={{ fontWeight: '700' }}>
                        22 days ago
                    </Descriptions.Item> */}
        <Descriptions.Item label="Content ID" labelStyle={{ fontWeight: '700' }}>
          {formData?._key}
        </Descriptions.Item>
      </Descriptions>
      <Content key={`${formData?._key}${local}`} className={styles.contentFormContainer}>
        <Form
          {...layout}
          name="content-form"
          className="apitoCreateFormHideValidation"
          layout="vertical"
          form={form}
          // onPublish={onPublish}
          validateMessages={validateMessages}
          initialValues={{ remember: false }}
        >
          <div style={{ margin: '15px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'right',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Tag color={'blue'}>Last Version</Tag>
            </div>

            {formErrors.length > 0
              ? formErrors.map((e) => (
                  <div style={{ paddingBottom: 5 }}>
                    <Alert message={e} type="error" showIcon />
                  </div>
                ))
              : null}
          </div>
          <div style={{ marginTop: '5px' }}>{renderForm(modelInfo)}</div>
          {/* {formType === 'create' && (
            <div style={{ marginTop: '5px' }}>{renderForm(projectModelInfo)}</div>
          )} */}
        </Form>
      </Content>
    </Layout>
  );
};

export default RevisionContentFormContainer2;

import { FileTextOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Menu, Space } from 'antd';
import { ContentContext } from 'context/ContentContext';
import { useGetAllModelsQuery } from 'generated/graphql';
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './sidebars.module.scss';

const ModelSidebar = ({ setIsCreate }: { setIsCreate: (value: boolean) => void }) => {
  const location = useLocation();

  const { data: modelList } = useGetAllModelsQuery();
  const firstModel = modelList?.projectModelsInfo?.[0]?.name;
  const navigate = useNavigate();
  const params = useParams();

  const { dispatch } = useContext(ContentContext);

  useEffect(() => {
    if (modelList?.projectModelsInfo?.length) {
      if (!params?.model) {
        navigate(`${firstModel}`);
      }
    }
    const obj = modelList?.projectModelsInfo?.find((item) => item.name === params?.model);
    dispatch({
      type: 'SET_TARGET',
      payload: {
        target: obj?.name,
        single_page: obj?.single_page,
        single_page_uuid: obj?.single_page_uuid,
        has_connections: obj?.has_connections,
      },
    });
  }, [dispatch, firstModel, modelList?.projectModelsInfo, navigate, params?.model]);

  return (
    <div>
      <Button
        icon={<PlusOutlined />}
        className={styles.createBtn}
        onClick={(e) => {
          setIsCreate(true);
        }}
      >
        ADD A MODEL
      </Button>
      <div className={styles.menuWrapper}>
        <div className={styles.menuGroup}>
          <p className={styles.groupTitle}>Model</p>
          <Menu mode="inline" className={styles.menu} selectedKeys={[location?.pathname]}>
            {modelList?.projectModelsInfo?.map((item, i) => (
              <Menu.Item
                key={`/console/models/${item.name}`}
                onClick={() => {
                  dispatch({
                    type: 'SET_TARGET',
                    payload: {
                      target: item.name,
                      single_page: item.single_page,
                      single_page_uuid: item.single_page_uuid,
                      has_connections: item.has_connections,
                    },
                  });
                  navigate(`/console/models/${item.name}`);
                  setIsCreate(false);
                }}
                className={styles.menuItem}
              >
                <div className={styles.menuDetails}>
                  <Space>
                    {
                      // model && item.name === state.target &&
                      item.name === 'user' && item.system_generated ? (
                        // model ? (
                        //   <LockOutlined />
                        // ) : (
                        <LockOutlined />
                      ) : // )
                      item.single_page ? (
                        <FileTextOutlined />
                      ) : null
                    }
                    {item.name}
                  </Space>
                </div>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default ModelSidebar;

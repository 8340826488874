import {
  CalendarOutlined,
  EnvironmentFilled,
  FileImageOutlined,
  FontSizeOutlined,
  PartitionOutlined,
} from '@ant-design/icons';
import { Divider, Tag } from 'antd';
import {
  Field_Sub_Type_Enum,
  Field_Type_Enum,
  FieldInfo,
  Input_Type_Enum,
} from 'generated/graphql';
import { capitalize } from 'lodash';
import { Icon } from '@iconify/react';

export const generateIcon = (field: FieldInfo) => {
  switch (field.field_type) {
    case Field_Type_Enum.Text:
      return <FontSizeOutlined />;
    case Field_Type_Enum.Date:
      return <CalendarOutlined />;
    case Field_Type_Enum.Number:
      if (field.input_type === Input_Type_Enum.Double) {
        return <Icon icon="octicon:number-16" />;
      } else {
        return <Icon icon="carbon:character-whole-number" />;
      }
    case Field_Type_Enum.Object:
      return <Icon icon="ph:brackets-curly-bold" />;
    case Field_Type_Enum.List:
      const list = field?.validation?.fixed_list_elements || [];
      const multiChoice = field?.validation?.is_multi_choice;
      if (
        field?.field_sub_type === Field_Sub_Type_Enum.Dropdown ||
        (list.length > 0 && !multiChoice)
      ) {
        //dropdown
        return <Icon icon="carbon:list-dropdown" />;
      }
      if (
        field?.field_sub_type === Field_Sub_Type_Enum.MultiSelect ||
        (list.length > 0 && multiChoice)
      ) {
        // multiple choice
        return <Icon icon="ci:select-multiple" />;
      }
      if (
        field?.field_sub_type === Field_Sub_Type_Enum.DynamicList ||
        (list.length === 0 && !multiChoice)
      ) {
        // dynamic list
        return <Icon icon="oui:list" />;
      }
      return <Icon icon="oui:list" />;
    case Field_Type_Enum.Multiline:
      return <Icon icon="gala:editor" />;
    case Field_Type_Enum.Boolean:
      return <Icon icon="carbon:boolean" />;
    case Field_Type_Enum.Media:
      return <FileImageOutlined />;
    case Field_Type_Enum.Geo:
      return <EnvironmentFilled />;
    case 'relation':
      return <PartitionOutlined />;
    case Field_Type_Enum.Repeated:
      return <Icon icon="mingcute:brackets-fill" />;
    default:
      return null;
  }
};

export const generateLabel = (item) => (
  <div>
    {generateIcon(item?.field_type)} <Divider type="vertical" /> {capitalize(`${item?.label}`)}{' '}
    {<Divider type="vertical" />}{' '}
    {item?.validation?.locals.map((l) => (
      <Tag color="blue">{l}</Tag>
    ))}
  </div>
);

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { env } from './env';

import App from './App';
//import reportWebVitals from './reportWebVitals';
import 'graphiql/graphiql.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-lightbox/style.css';
import { gqlErrorMessage } from './utils/message';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = createHttpLink({
  uri: env.REACT_APP_GRAPH_API,
  credentials: 'include',
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: env.REACT_APP_GRAPH_SUBS_API,
    //url: 'ws://localhost:5050/test/graphql/subscription',
    /*connectionParams: async () => {
      return { token: token };
    },*/
  })
);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Use-Cookies': true,
    },
  }));

  return forward(operation);
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const apolloErrorHandlr = onError(({ graphQLErrors, networkError }) => {
  gqlErrorMessage(graphQLErrors, networkError);
});

export const client = new ApolloClient({
  link: from([apolloErrorHandlr, authMiddleware, splitLink]),
  cache: new InMemoryCache(),
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

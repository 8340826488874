import { Button, Form, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import styles from './modelContainer.module.scss';

const DrawerDynamicFormList = ({ btnLabel, ...props }) => {
  // console.log('Log: DrawerDynamicFormList -> props', props)

  return (
    <Form.List name={['validation', 'fixed_list_elements']} {...props}>
      {/* formList name must be same as parent to catch initial value */}
      {(fields, { add, remove }) => (
        <div className={styles.drawerDynamicFormList}>
          {fields.map((field, index) => (
            <Form.Item required={false} key={field.key} className={styles.dynamicListItem}>
              <Form.Item
                {...field}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please input or delete this field.',
                  },
                ]}
                noStyle
              >
                <Input placeholder="Input List Item" style={{ width: '100%' }} />
              </Form.Item>
              {fields.length >= 1 ? (
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  style={{
                    margin: '9px 0 0 -24px',
                    float: 'right',
                    position: 'absolute',
                    zIndex: '1 !important',
                  }}
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item className={styles.addBtn}>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              style={{ width: '100%' }}
            >
              <PlusOutlined /> {`Add ${btnLabel || ''}`}
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
};

export default DrawerDynamicFormList;

import React from 'react';
import Admin from './Admin';

const AdminContainer = () => {
  return (
    <div>
      <Admin />
    </div>
  );
};

export default AdminContainer;

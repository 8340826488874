import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export default function ReactDraft(props) {
  const [editorState, setEditorState] = useState(null);

  let html = '';
  if (typeof props.value === 'string') {
    html = props.value;
  }

  const blocksFromHTML = convertFromHTML(html);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const handleEditorStateChange = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText();

    if (plainText !== '') {
      const rawContentState = convertToRaw(currentContent);
      const markup = draftToHtml(rawContentState);
      setEditorState(editorState);
      console.log('getting inside ', markup);
      props.onChange(markup);
    } else {
      setEditorState(editorState);
      console.log('getting nothing ', plainText);
      props.onChange(plainText);
    }
  };

  // const handleEditorChange = (EditorData) => {
  //     // props.onChange(EditorData)
  //     setEditorState()
  // }

  return (
    <Editor
      editorState={editorState || EditorState.createWithContent(state)}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      // value={props.value || ''}
      onEditorStateChange={handleEditorStateChange}
    />
  );
}

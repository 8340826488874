import { Divider, Tooltip, Typography } from 'antd';
import { Field_Type_Enum } from 'generated/graphql';
import React from 'react';
import { FieldArrayProps } from 'types/model';
import { generateIcon } from 'utils/GenerateLabelAndIcon';
import styles from '../models.module.scss';
import ReactJson from 'react-json-view';

const { Text } = Typography;

type SingleFieldProps = {
  index: number;
  field: FieldArrayProps;
  onOpenDrawer: Function;
};

const SingleField = ({ field, onOpenDrawer, index }: SingleFieldProps) => {
  return (
    <div>
      {field.field_sub_type === 'relation' ? (
        <Divider orientation="right">Special Fields</Divider>
      ) : null}
      {field.field_sub_type === Field_Type_Enum.Text ? (
        <Divider orientation="right">Regular Fields</Divider>
      ) : null}
      <div
        // type="dashed"
        // size="large"
        className={styles.singleFieldContainer}
        onClick={() => {
          onOpenDrawer(index);
        }}
      >
        <Tooltip
          //overlayInnerStyle={{ width: '300px' }}
          title={
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              quotesOnKeys={false}
              enableClipboard={false}
              //displayArrayKey={false}
              theme={'bright:inverted'}
              src={field?.example_json}
              name={field?.root_json_name}
            />
          }
          key={field.label}
          color={'white'}
          placement={'left'}
        >
          <div className={styles.contentContainer}>
            <div className={styles.iconWrapper}>{generateIcon(field)}</div>
            <div style={{ marginLeft: '8px' }}>
              <Text className={styles.title}>{field.label}</Text>
              <Text className={styles.desc}>{field.description}</Text>
            </div>
          </div>
        </Tooltip>
        {/* <Dropdown overlay={menu}>
            <EllipsisOutlined rotate={90} className={styles.dropDownMenuIcon} />
        </Dropdown> */}
      </div>
    </div>
  );
};

export default SingleField;

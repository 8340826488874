import { useAuthContext } from 'hooks/useAuth';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Login from './Registration';

const RegistrationContainer = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const email = window.localStorage.getItem('verifyEmail') || false;
    if (email) {
      navigate('/varify');
    }
  }, [navigate]);

  if (auth.isAuthenticated()) return <Navigate to={auth.navigateFrom} />;

  return <Login />;
};

export default RegistrationContainer;

import React from 'react';
import GraphqlExplorerWrapper from '../../apiExplorer/GraphqlExplorerWrapper';
import { SYSTEM_GRAPHQL_URL } from '../../../configs';

const ExplorerContainer = () => {
  return (
    <div>
      <GraphqlExplorerWrapper url={SYSTEM_GRAPHQL_URL} />
    </div>
  );
};

export default ExplorerContainer;

import { useEffect, useState } from 'react';
import { Button, Input, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography;

type RenameConfirmModalProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  handleModelConfirm?: Function;
  loader: boolean;
  isSinglePage: boolean;
  confirmText?: string;
};

const RenameConfirmModal = ({
  isVisible,
  setIsVisible,
  handleModelConfirm,
  loader,
  isSinglePage,
  confirmText = 'RENAME',
}: RenameConfirmModalProps) => {
  const [inpStr, setInpStr] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (inpStr) {
      errorCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inpStr]);

  const errorCheck = () => {
    if (inpStr === '') {
      setError(true);
    } else {
      setError(false);
    }
  };

  const hideModal = () => {
    setInpStr('');
    setError(false);
    setIsVisible(false);
  };

  const OnDelete = () => {
    if (inpStr !== '') {
      handleModelConfirm('rename', inpStr);
    } else {
      errorCheck();
    }
  };

  function ModalFooter() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0 8px',
        }}
      >
        <Button
          icon={loader ? <LoadingOutlined spin /> : ''}
          onClick={() => {
            return !loader ? OnDelete() : null;
          }}
          type="primary"
        >
          RENAME
        </Button>
        <Button onClick={hideModal}>CANCEL</Button>
      </div>
    );
  }

  return (
    <Modal
      title={
        <span style={{ textAlign: 'center' }}>
          <ExclamationCircleOutlined /> Rename Model?
        </span>
      }
      open={isVisible}
      onCancel={hideModal}
      footer={<ModalFooter />}
    >
      <>
        <Text>Type New model name in the box and click {confirmText}</Text>
        <Input
          style={{ margin: '5px 0' }}
          value={inpStr}
          placeholder={'New Model Name'}
          onChange={(e) => setInpStr(e.target.value)}
        />
        {error ? <Text type="danger">Value is required</Text> : null}
      </>
    </Modal>
  );
};

export default RenameConfirmModal;

import { useContext, useState } from 'react';

import { Form, Skeleton, Tag, Typography } from 'antd';
import styles from '../modelContainer/modelContainer.module.scss';
import { ContentContext } from 'context/ContentContext';
import { errorMessage, successMessage } from 'utils/message';
import {
  GetAllModelsQuery,
  useGetAllModelsQuery,
  useUpdateModelRelationMutation,
} from 'generated/graphql';
import { GET_FORM_GENERATION_DATA } from '../../../../graphql/queries/contents';

const { Title } = Typography;

export const ConnectionSettingDrawerComponent = (props) => {
  const { loading, error, data } = useGetAllModelsQuery();

  if (error) {
    errorMessage(error);
    return null;
  }
  return loading ? (
    <Skeleton active />
  ) : (
    <ConnectionSettingDrawerComponentContainer modelData={data} data={props.data} />
  );
};

type ConnectionSettingDrawerComponentContainerProps = {
  modelData: GetAllModelsQuery;
  data: { model: string; relation: string }[];
};

const ConnectionSettingDrawerComponentContainer = ({
  data,
}: ConnectionSettingDrawerComponentContainerProps) => {
  const { state } = useContext(ContentContext);

  const [payload, setPayload] = useState({});

  const updateModelRelationCache = (cache, { data }) => {
    const existingRelationData = cache.readQuery({
      query: GET_FORM_GENERATION_DATA,
      variables: {
        model_name: `${state.target}`,
      },
    });

    const resData = data?.upsertConnectionToModel;

    const updatedConnectionData = [
      ...resData,
      ...existingRelationData?.projectModelInfoByName?.connections?.filter(
        (item) => item.model !== data[0].model
      ),
    ];

    cache.writeQuery({
      query: GET_FORM_GENERATION_DATA,
      variables: {
        model_name: `${state.target}`,
      },
      data: { projectModelInfoByName: { connections: updatedConnectionData } },
    });
  };

  const [updateModelRelation] = useUpdateModelRelationMutation({
    onCompleted: (data) => {
      successMessage(data);
    },
    update: updateModelRelationCache,
  });

  const onFinish = (values) => {
    setPayload(payload);
    updateModelRelation({
      variables: {
        forward_connection_type: values?.forward?.relation,
        from: state.target,
        reverse_connection_type: values?.backward?.relation,
        to: data[0].model,
      },
    });
  };

  return (
    <div className={styles.connectionSettingDrawerContainer}>
      <Form
        name="relation_setting"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <div className={styles.topContent}>
          <div style={{ textAlign: 'center' }}>
            <Title level={5}>Relations Can Not be Reconfigured After Creation!</Title>
            <Title type="warning" level={5}>
              To Reconfigure a Relation, Delete the Old One and Create a New One!
            </Title>
            <div style={{ height: '25px' }} />
            <Tag style={{ textAlign: 'left' }}>
              Note: If you delete a Relation Field, Connections <br />
              between those particular models will be <br />
              disconnected/deleted
            </Tag>
          </div>
        </div>
      </Form>
    </div>
  );
};

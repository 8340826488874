import { Avatar, Button, Col, Form, Row, Select, Skeleton } from 'antd';
import FixedListSelector from 'components/inputs/FixedListSelector';
import GroupedCheckBoxWithAllSelect from 'components/inputs/GroupedCheckBoxWithAllSelect';
import Loader from 'components/loader/Loader';
import { useSearchUsersLazyQuery, useUpdateSettingTeamsMutation } from 'generated/graphql';
import { debounce } from 'lodash';
import React from 'react';
import { ANY_FIXME } from 'types/common';
import { gqlErrorMessage, successMessage } from 'utils/message';
import styles from './teams.module.scss';
import { GET_SETTINGS_TEAMS_MEMBERS } from '../../../../graphql/queries/settings';

type TeamsFormProps = {
  roles: Record<string, never>;
  rolesLoading?: boolean;
  administrations: ANY_FIXME;
  roleScopeLoading: boolean;
  fetchMore: Function;
};

const TeamsForm: React.FC<TeamsFormProps> = ({
  roles,
  rolesLoading,
  administrations,
  roleScopeLoading,
  fetchMore,
}) => {
  const roleList = roles ? Object.keys(roles) : [];
  const [fetching, setFetching] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [form] = Form.useForm();

  const formValueUpdater = form.setFieldsValue;

  const { Option } = Select;

  const updateCache = (cache, { data }) => {
    const existingData = cache.readQuery({ query: GET_SETTINGS_TEAMS_MEMBERS })?.teamMembers ?? [];

    cache.writeQuery({
      query: GET_SETTINGS_TEAMS_MEMBERS,
      data: {
        teamMembers: [...existingData],
      },
    });
  };

  const [updateCurrentProject, { loading }] = useUpdateSettingTeamsMutation({
    onCompleted: (data) => {
      successMessage('Team Member Added Successfully!');
      form.resetFields();
      setData([]);
    },
    update: updateCache,
  });

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "'${label}' is required!",
    // ...
  };

  const [searchUser] = useSearchUsersLazyQuery({
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
      setFetching(false);
    },
    onCompleted: (data) => {
      setData(data?.searchUsers);
      setFetching(false);
    },
  });

  let onSearch = debounce((search) => {
    if (search.trim()) {
      setFetching(true);
      searchUser({
        variables: {
          first_name: search,
        },
      });
    }
  }, 1000);

  const onFinish = (values) => {
    updateCurrentProject({
      variables: {
        add_team_member: {
          ...values,
        },
      },
      refetchQueries: [GET_SETTINGS_TEAMS_MEMBERS],
    });
  };

  return (
    <Form
      style={{ marginTop: '25px' }}
      layout="vertical"
      name="general-setting"
      form={form}
      onFinish={onFinish}
      initialValues={{ remember: false }}
      validateMessages={validateMessages}
    >
      <Row>
        <Col span={24} lg={{ span: 8 }}>
          <Form.Item
            name="member_id"
            label="Team Member"
            rules={[{ required: true }]}
            style={{ marginTop: '-12px' }}
          >
            <Select
              showSearch
              placeholder="Apito User's Email and Press Enter to Search"
              notFoundContent={fetching ? <Loader size="small" /> : null}
              filterOption={false}
              onSearch={onSearch}
              style={{ width: '100%' }}
            >
              {data.length > 0
                ? data.map((d) => (
                    <Option key={d.id} value={d.id}>
                      <Avatar size="small" src={d.avatar} style={{ marginRight: '8px' }} />
                      <span>{d.name}</span>
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>

          <Form.Item name="team_member_role" label="Role (Optional)">
            {rolesLoading ? (
              <Skeleton.Input active className="w-100" />
            ) : (
              <FixedListSelector
                placeholder="Give Your Team Member a certain Role that has Particular Permission"
                list={roleList || []}
              />
            )}
          </Form.Item>
        </Col>
        <Col
          span={24}
          lg={{
            span: 15,
            offset: 1,
          }}
        >
          {roleScopeLoading ? (
            <Skeleton active />
          ) : administrations?.length > 0 ? (
            <GroupedCheckBoxWithAllSelect
              options={administrations || []}
              formValueUpdater={formValueUpdater}
              name="administrative_permissions"
              label="Administrative Permissions"
              subTitle="Apito Console Access Level Permissions"
              rules={[{ required: true }]}
              className={styles.checkboxs}
            />
          ) : null}
          {/* <Table dataSource={tableData} columns={tableColumns} pagination={false} bordered size="small" /> */}
        </Col>
      </Row>
      <Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            ADD TEAM MEMBER
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default TeamsForm;

import { Button, message, Upload } from 'antd';
import React, { useState } from 'react';
import { Plugin_Load_Type_Enum, PluginDetailsFields } from '../../generated/graphql';
import { UploadOutlined } from '@ant-design/icons';
import { PLUGIN_UPLOAD } from '../../configs';
import { RcFile } from 'antd/es/upload';

type PluginCardButtonProps = {
  pluginDetails: PluginDetailsFields;
};

const PluginStatusButton: React.FC<PluginCardButtonProps> = ({ pluginDetails, ...props }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('Plugin must smaller than 20MB!');
    }
    return isLt2M;
  };

  /*
  function customRequest(options) {
    let params = new FormData();
    params.append("avatar", options.file);
    props.ajax.post(`api/system/user/updateAvatar`, params, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
      if (res.statusCode == 200) {
        message.success(res.message)
        handleSearch()
      } else {
        message.error(res.message)
      }
    })
  }
   */

  const uploadProps = {
    action: PLUGIN_UPLOAD,
    maxCount: 1,
    headers: {
      'X-Use-Cookies': 'true',
    },
    withCredentials: true,
    data: pluginDetails,
    beforeUpload: beforeUpload,
    showUploadList: false,
    onChange: (info) => {
      const { status } = info.file;
      if (status === 'done') {
        message.success('plugin uploaded');
        setLoading(false);
      } else if (status === 'error') {
        message.error('plugin uploaded failed');
        setLoading(false);
      }
    },
  };

  const getLabel = (data: Plugin_Load_Type_Enum) => {
    switch (pluginDetails.load_status) {
      case Plugin_Load_Type_Enum.NotInstalled:
      case Plugin_Load_Type_Enum.ReInstall:
        return 'Upload';
      case Plugin_Load_Type_Enum.Installed:
        return 'Re Upload';
      case Plugin_Load_Type_Enum.Loading:
        return 'Loading';
      case Plugin_Load_Type_Enum.Loaded:
        return 'Re Install';
      case Plugin_Load_Type_Enum.LoadFailed:
        return 'Re Load';
      default:
        return 'Unknown';
    }
  };

  return (
    <Upload {...uploadProps}>
      <Button icon={<UploadOutlined />} disabled={false} loading={loading}>
        {getLabel(pluginDetails.load_status)}
      </Button>
    </Upload>
  );
};

export default PluginStatusButton;

import { useAuthContext } from 'hooks/useAuth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ANY_FIXME } from 'types/common';

type AuthCheckProps = ANY_FIXME & {
  //
};

const AuthCheck: React.FC<AuthCheckProps> = () => {
  const auth = useAuthContext();

  return auth.isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: auth.navigateFrom }} replace />
  );
};

export default AuthCheck;

import { ProjectsProvider } from 'hooks/useProjectList';
import StartProject from 'pages/projects/StartProject/StartProject';
import React from 'react';

const StartProjectContainer = () => {
  return (
    <ProjectsProvider>
      <StartProject />
    </ProjectsProvider>
  );
};

export default StartProjectContainer;

export const DBLIST = [
  { title: 'Apito Cloud', value: 'apito', inputList: ['cloud'] },
  {
    title: 'MySQL',
    value: 'mysql',
    //inputList: ['host', 'port', 'user', 'password', 'db']
    inputList: ['paid'],
  },
  {
    title: 'MariaDB',
    value: 'mariadb',
    //inputList: ['info', 'host', 'port', 'user', 'password', 'db'],
    inputList: ['paid'],
  },
  {
    title: 'SQLite',
    value: 'sqlite',
    inputList: ['paid'],
  },
  {
    title: 'PostgresSQL',
    value: 'postgresql',
    inputList: ['paid'],
    //inputList: ['info', 'host', 'port', 'user', 'password', 'db'],
  },
  {
    title: 'SQLServer',
    value: 'sqlServer',
    inputList: ['paid'],
  },
  {
    title: 'Oracle',
    value: 'oracle',
    inputList: ['paid'],
  },
  {
    title: 'ArangoDB',
    value: 'arangodb',
    //inputList: ['info', 'host', 'port', 'user', 'password', 'db'],
    inputList: ['paid'],
  },
  {
    title: 'MongoDB',
    value: 'mongodb',
    inputList: ['paid'],
  },
  {
    title: 'Couchbase',
    value: 'couchbase',
    inputList: ['paid'],
  },
  { title: 'DynamoDB', value: 'dynamoDB', inputList: ['paid'] },
  { title: 'FireStore', value: 'firestore', inputList: ['paid'] },
];

import { DatePicker } from 'antd';
import moment from 'moment';

export function CustomDatePicker(props) {
  return (
    <DatePicker
      //locale={locale}
      style={{ width: '100%' }}
      format="YYYY-MM-DD HH:mm"
      {...props}
      showTime={{ format: 'HH:mm' }}
      showNow
      value={props.value ? moment(props.value, 'YYYY-MM-DD HH:mm') : ''}
    />
  );
}
